import React, { MouseEventHandler, ButtonHTMLAttributes } from "react";

import { generateButtonClassName } from "../../utils/get-button-data";
import { IButtonBase } from "../../models/button-base.model";

export interface ButtonProps extends IButtonBase {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        type,
        color,
        className,
        onClick,
        children,
        smallPaddingHeight,
        textUppercase = true,
        disabled = false,
        htmlType = "button",
        active = false,
        activeClass = "active",
    } = props;

    const generatedClassName = generateButtonClassName({
        type,
        color,
        className,
        smallPaddingHeight,
        textUppercase,
        disabled,
        active,
        activeClass,
    });

    return (
        <button
            ref={ref}
            className={generatedClassName}
            disabled={disabled}
            type={htmlType}
            onClick={onClick}
        >
            {children}
        </button>
    );
});

Button.displayName = "Button";

export default Button;
