import { actions } from "../api/api.actions";
import { statusFail, statusLoading, statusSuccess } from "./ui.actions";

const uiMiddleware = ({ dispatch }) => (next) => (action) => {
    const { type, meta = {} } = action;
    const { entity, actionName } = meta;
    next(action);

    if (entity && actionName) {
        switch (true) {
            case type.includes(actions.API_REQUEST):
                dispatch(statusLoading(meta));
                break;
            case type.includes(actions.API_SUCCESS):
                dispatch(statusSuccess(meta));
                break;
            case type.includes(actions.API_FAIL):
                dispatch(statusFail(meta));
                break;
            // no default
        }
    }
};

export default uiMiddleware;
