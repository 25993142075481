import moment from 'moment';
import { Thing } from "schema-dts";

import { GraphWithoutContext } from "./structured-data/create-graph";
import { IPostStructuredData } from "../models/structured-data.model";

export const processStructuredDataObject = (structuredData: Thing | IPostStructuredData ) => {
    const searchFields = ['datePublished', 'dateModified'];

    if (typeof  structuredData === 'object') {
        //take properties with "date" name and process date to properly format
        const processedDataProperties = Object.entries(structuredData)
            .filter(([key, value]) => searchFields.includes(key) && typeof value !== 'string')
            .reduce<Record<string, any>>((acc, [key, entry]) => {
                acc[key] = moment.unix(entry).locale('us').format('YYYY-MM-DD');
                return acc;
            }, {});

        return { ...structuredData, ...processedDataProperties };
    } else {
        return structuredData;
    }
}


export default function processStructuredData(structuredData: Thing | GraphWithoutContext) {
    if (typeof structuredData === 'object' && '@graph' in structuredData) {
        const newGraph = structuredData['@graph'].map(data => processStructuredDataObject(data));
        return {
            ...structuredData,
            '@graph': newGraph
        }
    } else if (typeof structuredData === 'object' && '@type' in structuredData) {
        return processStructuredDataObject(structuredData);
    } else {
        return structuredData;
    }
}
