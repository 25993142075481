module.exports = {
    author: {
        nodeType: "Author",
        schema: {
            profileId: "Int",
            slug: "String",
            media: "[Media]",
            posts: "[Post]",
            translations: "AuthorTranslations",
        },
    },
    sliderProduct: {
        nodeType: "SliderProduct",
        schema: {
            productId: "Int",
            categoryGroup: "String",
            category: "String",
            brandName: "String",
            url: "String",
            affiliateUrl: "String",
            imgUrl: "String",
        }
    },
    authorTranslations: {
        nodeType: "AuthorTranslations",
        schema: {
            en: "AuthorTranslation",
            pl: "AuthorTranslation",
        },
    },
    authorTranslation: {
        nodeType: "AuthorTranslation",
        schema: {
            title: "String",
            firstName: "String",
            lastName: "String",
            slug: "String",
            bio: "String",
        },
    },
    banner: {
        nodeType: "Banner",
        schema: {
            bannerId: "Int",
            buttonUrl: "String",
            publishedAt: "Int",
            contentWidth: "Int",
            horizontalStart: "String",
            verticalStart: "String",
            verticalStartMobile: "String",
            translations: "BannerTranslations",
            media: "[Media]",
        },
    },
    bannerTranslations: {
        nodeType: "BannerTranslations",
        schema: {
            pl: "BannerTranslation",
        },
    },
    bannerTranslation: {
        nodeType: "BannerTranslation",
        schema: {
            title: "String",
            description: "String",
            buttonUrl: "String",
            buttonText: "String",
            media: "[Media]",
        },
    },
    bannersCollection: {
        nodeType: "BannersCollection",
        schema: {
            placeId: "String",
            banners: "[Banner]",
        },
    },
    faq: {
        nodeType: "Faq",
        schema: {
            translations: "FaqTranslations",
        },
    },
    faqTranslations: {
        nodeType: "FaqTranslations",
        schema: {
            pl: "FaqTranslation",
        },
    },
    faqTranslation: {
        nodeType: "FaqTranslation",
        schema: {
            question: "String",
            answer: "String",
        },
    },
    media: {
        nodeType: "Media",
        schema: {
            fileId: "Int",
            type: "String",
            url: "String",
            alt: "String",
            relations: "[Int]",
        },
    },
    post: {
        nodeType: "Post",
        schema: {
            articleId: "Int",
            slug: "String",
            isPromoted: "Boolean",
            publishedAt: "Int",
            translations: "PostTranslations",
            media: "[Media]",
            author: "Author",
            relatedPosts: "[Post]",
            tags: "[Tag]",
            categories: "[PostCategory]",
        },
    },
    postCategory: {
        nodeType: "PostCategory",
        schema: {
            categoryId: "Int",
            sectionId: "Int",
            businessMeaning: "Int",
            name: "String",
            description: "String",
            createdAt: "Int",
            updatedAt: "Int",
            slug: "String",
            postsIds: "[Int]",
        },
    },
    postTranslations: {
        nodeType: "PostTranslations",
        schema: {
            pl: "PostTranslation",
            en: "PostTranslation",
        },
    },
    postTranslation: {
        nodeType: "PostTranslation",
        schema: {
            slug: "String",
            title: "String",
            lead: "String",
            content: "String",
        },
    },
    tag: {
        nodeType: "Tag",
        schema: {
            tagId: "Int",
            type: "Int",
            slug: "String",
            posts: "[Post]",
            translations: "TagTranslations",
        },
    },
    tagTranslations: {
        nodeType: "TagTranslations",
        schema: {
            en: "TagTranslation",
            pl: "TagTranslation",
        },
    },
    tagTranslation: {
        nodeType: "TagTranslation",
        schema: {
            name: "String",
            slug: "String",
        },
    },
    site: {
        nodeType: "Site",
        siteMetadata: {
            description: "String",
            url: "String",
            lang: "String",
            langs: "[String]",
            image: "String",
            title: "String",
            shareApiUrl: "String"
        },
    },
};
