import React from "react";
import { ISurveyOptionProps } from "../../../models/survey/survey-question-option-props.model";

import {
    imageTextOption,
    imageTextInput,
    imageText,
    imageTextIcon,
    labelText,
} from "./survey-option-image-text.module.scss";

import Checked from "../../../assets/images/svg/checked.svg";
import { input } from "../../atoms/form/input.module.scss";
import { FieldHookConfig, useField } from "formik";

const SurveyOptionImageText = (props: ISurveyOptionProps) => {
    const { option, inputType, questionId } = props;
    const { id, label } = option;

    const optionConfig: FieldHookConfig<any> = {
        name: questionId.toString(),
        type: inputType,
        value: id.toString(),
    };

    const [field] = useField(optionConfig);

    return (
        <label className={imageTextOption}>
            <input className={imageTextInput} {...field} type={inputType} />
            <div className={imageText}>{Checked && <Checked className={imageTextIcon} />}</div>
            {label && <p className={labelText}>{label}</p>}
        </label>
    );
};

export default SurveyOptionImageText;
