import React from "react";

import { surveySessionStateIndicator, text } from "./survey-session-state-indicator.module.scss";
import SurveySessionStateEnum, {
    SurveySessionStateLabels,
} from "../../../enums/survey-session-state.enum";

export interface ISurveySessionStateIndicator {
    state: SurveySessionStateEnum;
    onClick?: () => void;
    className?: string;
}

export default function SurveySessionStateIndicator({
    state,
    onClick,
    className = "",
}: ISurveySessionStateIndicator) {
    const styles = {
        "--state-color": getStateColor(state),
    };

    return (
        <div
            //@ts-ignore
            style={styles}
            className={`${surveySessionStateIndicator} ${className}`}
            onClick={onClick}
        >
            <p className={text}>{SurveySessionStateLabels[state]}</p>
        </div>
    );
}

const getStateColor = (state: SurveySessionStateEnum) => {
    switch (state) {
        case SurveySessionStateEnum.Completed:
            return "var(--color-blue-3)";
        case SurveySessionStateEnum.New:
            return "var(--color-pink-light-3)";
        case SurveySessionStateEnum.InProgress:
        case SurveySessionStateEnum.Processing:
            return "var(--color-peach)";
        case SurveySessionStateEnum.Undefined:
            return "var(--color-grey-dark)";
        case SurveySessionStateEnum.Refused:
        case SurveySessionStateEnum.Dropped:
            return "var(--color-red)";
        case SurveySessionStateEnum.Finished:
            return "var(--color-green-lighter)";
    }
};
