import React, { useState } from "react";
import Moment from "react-moment";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { useDispatch } from "react-redux";
import { Link } from "gatsby";

import {
    actions,
    buttonDelete,
    buttonEdit,
    container,
    link,
    content,
    date,
    iconDelete,
    image,
    imageBox,
    imageRatio,
    images,
    nameBox,
    nameText,
    occasionText,
} from "./stylization-card.module.scss";
import TrashCanIcon from "../../../assets/images/svg/trash-can.svg";
import { IStylization } from "../../../models/stylization.model";
import { EModal } from "../../../models/modal.model";
import { useModalContext } from "../../../contexts/modal.context";
import { getAbsolutePath } from "../../utility/routes.utility";
import {
    deleteSingleStylizationAction,
    updateSingleStylizationAction,
} from "../../../redux/single-stylization/single-stylization.actions";

import ButtonEdit from "../../../components/atoms/button-edit";
import StylizationEditForm, { IStylizationEditFormProps } from "./stylization-edit-form";

interface IStylizationCardProps {
    className?: string;
    stylization: IStylization;
    NameTag?: React.ElementType;
}

const StylizationCard: React.FC<IStylizationCardProps> = ({
    className = "",
    stylization,
    NameTag = "h2",
}) => {
    const { t } = useI18next();
    const dispatch = useDispatch();
    const { stylizationId, name, occasion, createdAt, products } = stylization;
    const [editMode, setEditMode] = useState(false);
    const { addModal } = useModalContext();

    const LinkTag = editMode ? "div" : Link;

    const handleEdit = () => {
        setEditMode((prevEditMode) => !prevEditMode);
    };

    const handleSubmit: IStylizationEditFormProps["onSubmit"] = (values) => {
        dispatch(updateSingleStylizationAction({ data: values, stylizationId }));
    };

    const handleDelete = () => {
        addModal({
            key: EModal.CONFIRMATION,
            props: {
                onConfirm() {
                    dispatch(deleteSingleStylizationAction(stylizationId));
                },
                title: t("stylization.delete.confirm.title"),
                content: t("stylization.delete.confirm.text"),
            },
        });
    };

    return (
        <div className={`${container} ${className}`}>
            <LinkTag
                className={link}
                to={`${getAbsolutePath("GENERATOR_STYLIZATION")}/${stylizationId}`}
            >
                <div className={images}>
                    {products.map((product) => {
                        return (
                            <div className={imageBox} key={product.productId}>
                                <div className={imageRatio}>
                                    <img className={image} src={product.imgUrl} alt="" />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={content}>
                    <Moment unix={true} format="DD-MM-YYYY" className={date}>
                        {createdAt}
                    </Moment>
                    <NameTag className={nameBox}>
                        <span className={occasionText}>{occasion.label}</span>
                        {!editMode && <span className={nameText}>{name}</span>}
                    </NameTag>
                    {editMode && (
                        <StylizationEditForm
                            onSubmit={handleSubmit}
                            onCancel={handleEdit}
                            initialValues={{ name }}
                        />
                    )}
                </div>
            </LinkTag>
            <div className={actions}>
                <button className={buttonDelete} onClick={handleDelete}>
                    <TrashCanIcon className={iconDelete} />
                </button>
                <ButtonEdit className={buttonEdit} onClick={handleEdit} />
            </div>
        </div>
    );
};

export default StylizationCard;
