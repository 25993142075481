import React from "react";
import { GatsbyLinkProps } from "gatsby";

import GatsbyLink from "../../../plugins/gatsby-plugin-ap-i18next/src/link";

import { IButtonBase } from "../../models/button-base.model";
import { generateButtonClassName } from "../../utils/get-button-data";

export interface LinkProps extends IButtonBase {
    to: string;
    external?: boolean;
    additionalProps?: Omit<GatsbyLinkProps<any>, "to"> | HTMLAnchorElement;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const {
        to,
        external = false,
        type,
        color,
        textUppercase,
        smallPaddingHeight,
        className,
        disabled,
        additionalProps,
        active,
        children,
        style,
    } = props;

    const additionalAnchorProps = external
        ? (additionalProps as Omit<HTMLAnchorElement, "href" | "className">)
        : undefined;
    const additionalLinkProps = !external ? (additionalProps as GatsbyLinkProps<any>) : undefined;

    const classNames = generateButtonClassName({
        type,
        color,
        textUppercase,
        smallPaddingHeight,
        className,
        disabled,
        active,
    });

    const commonProps = {
        className: classNames,
        children,
        style,
    };

    const externalLink = (
        //@ts-ignore
        <a
            ref={ref}
            href={to}
            target="_blank"
            rel="noreferrer noopener"
            {...commonProps}
            {...additionalAnchorProps}
        />
    );
    const gatsbyLink = <GatsbyLink ref={ref} to={to} {...commonProps} {...additionalLinkProps} />;

    return external ? externalLink : gatsbyLink;
});

Link.displayName = "Link";

export default Link;
