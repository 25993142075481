import { useState, useEffect, useCallback } from 'react';

export default function useWindowScroll() {
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = useCallback(() => {
        setScrollX(window.scrollX);
        setScrollY(window.scrollY);
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => document.removeEventListener('scroll', handleScroll);
    },[]);

    return {
        x: scrollX,
        y: scrollY
    }
}