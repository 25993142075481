import { FormikValues } from "formik";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { selectEntityIsLoading } from "../../redux/ui/ui.selectors";
import { SURVEY_SESSION, LIST } from "../../redux/survey-session/survey-session.reducer";
import {
    getSingleSurveySessionQuestions,
    getSurveySessions,
    updateAnswer,
    updateStatus,
} from "../../redux/survey-session/survey-session.actions";
import { clearList } from "../../redux/generic-list/generic-list.actions";
import {
    selectFirstSurveySessionId,
    selectSurveySessionCategoriesBySessionId,
    selectSurveySessionListItems,
    selectSurveySessionQuestionByIdAndSessionId,
    selectSurveySessionQuestionsBySessionId,
    selectSurveySessionStatusBySessionId,
    selectSurveySessionUserBySessionId,
} from "../../redux/survey-session/survey-session.selectors";

import SurveySessionState from "../../enums/survey-session-state.enum";

export interface IUseSurveySessionProps {
    page?: number;
    sessionId?: number | string;
    questionId?: number | string;
}

export default function useSurveySession(props: IUseSurveySessionProps = {}) {
    const { page = 1, sessionId, questionId } = props;
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => selectEntityIsLoading(state, SURVEY_SESSION));

    const {
        surveyStatus,
        surveyUser,
        categories,
        questions,
        singleQuestion,
        listItems,
        firstSessionId,
    } = useSelector((state) => {
        return {
            surveyStatus: selectSurveySessionStatusBySessionId(state, sessionId),
            surveyUser: selectSurveySessionUserBySessionId(state, sessionId),
            listItems: selectSurveySessionListItems(state),
            firstSessionId: selectFirstSurveySessionId(state),
            categories: selectSurveySessionCategoriesBySessionId(state, sessionId),
            questions: selectSurveySessionQuestionsBySessionId(state, sessionId, true),
            singleQuestion: selectSurveySessionQuestionByIdAndSessionId(
                state,
                sessionId,
                questionId
            ),
        };
    });

    const submitAnswer = (values: FormikValues) => {
        dispatch(updateAnswer(values, { sessionId, questionId }));
    };

    const changeStatus = (sessionState: SurveySessionState) => {
        dispatch(updateStatus(sessionState, { sessionId }));
    };

    const getSessionsList = () => {
        if (page && !isLoading) {
            const payload = {
                params: {
                    page,
                },
            };
            dispatch(clearList(SURVEY_SESSION, LIST));
            dispatch(getSurveySessions(payload));
        }
    };

    const getSingleSession = () => {
        if (!isLoading && listItems.length === 0) {
            getSessionsList();
        }
        if (firstSessionId && sessionId === ":surveySessionId" && !isLoading) {
            navigate(`/konto/ankieta/${firstSessionId}`);
        }
        if (sessionId && sessionId !== ":surveySessionId" && !isLoading) {
            dispatch(getSingleSurveySessionQuestions(sessionId));
        }
    };

    return {
        isLoading,
        listItems,
        firstSessionId,
        categories,
        surveyUser,
        surveyStatus,
        questions,
        singleQuestion,
        submitAnswer,
        getSessionsList,
        getSingleSession,
        changeStatus,
    };
}
