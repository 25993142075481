import { actions, deleteAlert } from "./alerts.actions";
import { config } from "../../config";
import { ALERTS } from "./alerts.reducer";

const { alerts } = config;

const alertsMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta = {} } = action;
    const { id } = meta;
    next(action);

    if (type.includes(`${ALERTS} ${actions.SET_ALERT}`)) {
        const alertType = payload.type;
        if (alertType !== alerts.types.error) {
            setTimeout(() => dispatch(deleteAlert(id)), 3000);
        }
    }
};

export default alertsMiddleware;
