import { useCallback, useRef, useState } from "react";

export default function useMenuClosingButton<RefType extends HTMLElement>(onClose?: () => void) {
    const [closing, setClosing] = useState(false);
    const animatedElementRef = useRef<RefType | null>(null);

    const onMenuClosingButtonClick = useCallback(() => {
        setClosing(true);
    }, []);

    const onAnimationEnd = useCallback(
        (target: EventTarget) => {
            if (target === animatedElementRef.current && closing) {
                setClosing(false);

                if (onClose) {
                    onClose();
                }
            }
        },
        [closing, onClose]
    );

    return {
        animatedElementRef,
        closing,
        onMenuClosingButtonClick,
        onAnimationEnd,
    };
}
