// extracted by mini-css-extract-plugin
export var alerts = "header-module--alerts--TpPOv";
export var buttonsWrapper = "header-module--buttons-wrapper--LB0ZA";
export var fadeIn = "header-module--fade-in--Ui-gP";
export var fadeOut = "header-module--fade-out--6bYd2";
export var header = "header-module--header--2SLJm";
export var hiding = "header-module--hiding--LsTaX";
export var logo = "header-module--logo--mULiY";
export var menuButton = "header-module--menu-button--tYNCu";
export var scrolled = "header-module--scrolled--fzW+f";
export var slideFromBottom = "header-module--slide-from-bottom--GzOvd";
export var slideFromLeft = "header-module--slide-from-left--rHLhY";
export var slideFromRight = "header-module--slide-from-right--LPbQA";
export var slideFromTop = "header-module--slide-from-top--t-8WF";
export var slideToBottom = "header-module--slide-to-bottom--MONX7";
export var slideToLeft = "header-module--slide-to-left--RpXlW";
export var slideToRight = "header-module--slide-to-right--0Xwwc";
export var slideToTop = "header-module--slide-to-top--kZHKO";
export var surveyLogo = "header-module--survey-logo--DLXXw";
export var zoomIn = "header-module--zoom-in--akPeB";
export var zoomOut = "header-module--zoom-out--wYM7p";