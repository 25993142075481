import React, { ReactNode, RefObject } from "react";
import * as CSS from "csstype";

import {
    titleContainer,
    title,
    small,
    medium,
    large,
    subtitleText,
    left,
    center,
    right,
    normal,
    italic,
    visible as visibleClass,
    hidden,
} from "./title.module.scss";

export interface ITitleProps {
    Tag?: React.ElementType;
    subtitle?: string | null;
    size?: "small" | "medium" | "large";
    align?: "left" | "center" | "right";
    fontStyle?: "normal" | "italic";
    className?: string;
    subtitleClassName?: string;
    containerClassName?: string;
    children?: ReactNode;
    titleStyle?: CSS.Properties;
    containerRef?: RefObject<HTMLDivElement>;
    visible?: boolean;
}

const Title = React.forwardRef<HTMLHeadElement, ITitleProps>(
    (
        {
            Tag = "h1",
            subtitle = "",
            size,
            align = "left",
            fontStyle = "normal",
            className = "",
            subtitleClassName = "",
            containerClassName = "",
            children,
            titleStyle,
            containerRef,
            visible = true,
        },
        ref
    ) => {
        return (
            <div
                className={`${titleContainer} ${containerClassName} ${getAlignClass(align)} 
                `}
                ref={containerRef}
            >
                {subtitle && (
                    <div
                        className={`${subtitleText} ${subtitleClassName} ${
                            visible ? visibleClass : hidden
                        }`}
                    >
                        {subtitle}
                    </div>
                )}
                <Tag
                    style={titleStyle}
                    ref={ref}
                    className={`
                ${title}
                ${className} 
                ${getSizeClass(size)}
                ${getStyleClass(fontStyle)}
                ${visible ? visibleClass : hidden}
            `}
                >
                    {children}
                </Tag>
            </div>
        );
    }
);

function getSizeClass(size: ITitleProps["size"]): string {
    switch (size) {
        case "small":
            return small;
        case "medium":
            return medium;
        case "large":
        default:
            return large;
    }
}

function getAlignClass(align: ITitleProps["align"]): string {
    switch (align) {
        case "center":
            return center;
        case "right":
            return right;
        case "left":
        default:
            return left;
    }
}

function getStyleClass(fontStyle: ITitleProps["fontStyle"]): string {
    switch (fontStyle) {
        case "italic":
            return italic;
        case "normal":
        default:
            return normal;
    }
}

export default Title;
