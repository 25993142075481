import { config } from "../../config";

const UI = "[UI]";

const statusMap = config.apiStatusMap;

const initialState = {};

function uiReducer(state = initialState, action) {
    const { type, meta } = action;

    switch (true) {
        case type.includes(statusMap.loading):
            return {
                ...state,
                [meta.entity]: statusMap.loading,
            };
        case type.includes(statusMap.success):
            return {
                ...state,
                [meta.entity]: statusMap.success,
            };
        case type.includes(statusMap.fail):
            return {
                ...state,
                [meta.entity]: statusMap.fail,
            };
        case type.includes(statusMap.idle):
            return {
                ...state,
                [meta.entity]: statusMap.idle,
            };
        default:
            return state;
    }
}

export { UI, uiReducer as default, initialState };
