import React from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import AuthLayout from "../../../layouts/auth-layout";
import PassResetForm from "../forms/pass-reset-form";

export interface IAvatarProps {
    token: string;
}

const PassReset: React.FC<IAvatarProps> = ({ token }) => {
    const { t } = useI18next();
    const title = t(token ? "auth.changePassword.form.title" : "auth.changePassword.noToken.title");
    return (
        <AuthLayout title={title} titleAlign="center">
            {token ? (
                <PassResetForm token={token} />
            ) : (
                <span>{t("auth.changePassword.noToken.text")}</span>
            )}
        </AuthLayout>
    );
};

export default PassReset;
