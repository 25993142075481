import React from "react";

import {
    surveyCounter,
    surveyCounter__iconWrapper,
    surveyCounter__content,
    surveyCounter__content__counter,
    surveyCounter__content__title,
} from "./survey-counter.module.scss";

export interface ISurveyCounter {
    title: string;
    Icon: React.ElementType;
    count: number;
}

export default function SurveyCounter({ title, Icon, count }: ISurveyCounter) {
    return (
        <div className={surveyCounter}>
            <div className={surveyCounter__iconWrapper}>
                <Icon />
            </div>
            <div className={surveyCounter__content}>
                <p className={surveyCounter__content__counter}>{count}</p>
                <p className={surveyCounter__content__title}>{title}</p>
            </div>
        </div>
    );
}
