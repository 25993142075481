import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
    avatarWrapper,
    fileInput,
    icon,
    editable,
    text,
    hidden,
    black,
} from "./user-avatar-widget.module.scss";
import LetterX from "../../assets/images/svg/letter-x.svg";
import useUser from "../../apps/auth/hooks/use-user";
import { setAlert } from "../../redux/alerts/alerts.actions";
import { USER } from "../../redux/user/user.reducer";
import { config } from "../../config";

import BaseFileInput, { IBaseFileInputProps } from "../atoms/form/base-file-input";
import UserAvatar from "../atoms/user-avatar";

const { apiStatusMap } = config;

export default function UserAvatarWidget() {
    const [showFileInput, setShowFileInput] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const { updateProfile, profile, status } = useUser();
    const dispatch = useDispatch();

    const isLoading = status === apiStatusMap.loading || imageLoading;
    const avatarUri = profile?.avatarUri;

    const dispatchError = useCallback(
        (error) => dispatch(setAlert({ type: "error", content: error }, { entity: USER })),
        [dispatch]
    );

    const handleChange: IBaseFileInputProps["onChange"] = useCallback(
        ({ uploadedFiles, uploadErrors }) => {
            if (
                uploadErrors?.length === 0 &&
                uploadedFiles &&
                uploadedFiles.length > 0 &&
                typeof uploadedFiles?.[0].content === "string"
            ) {
                updateProfile({ avatarBase64: uploadedFiles[0].content });
            } else {
                if (uploadErrors && uploadErrors.length > 0) {
                    uploadErrors.forEach(dispatchError);
                } else {
                    dispatchError("Wystąpił nieznany błąd podczas ładowania pliku.");
                }
            }
        },
        [dispatchError, updateProfile]
    );

    const handleMouseEnter = useCallback(() => setShowFileInput(true), []);
    const handleMouseLeave = useCallback(() => setShowFileInput(false), []);

    useEffect(() => {
        if (!avatarUri) {
            setShowFileInput(true);
        }
    }, [avatarUri]);

    return (
        <div
            className={avatarWrapper}
            {...(avatarUri
                ? {
                      onMouseEnter: handleMouseEnter,
                      onMouseLeave: handleMouseLeave,
                  }
                : {})}
        >
            {avatarUri && <UserAvatar src={avatarUri} onLoadChange={setImageLoading} />}
            <BaseFileInput
                name="avatar"
                className={`${fileInput} ${avatarUri ? editable : ""} ${
                    !showFileInput && !isLoading ? hidden : ""
                }`}
                onChange={handleChange}
                maxFileCount={1}
                compressOptions={compressOptions}
            >
                {!isLoading && !avatarUri && <LetterX className={icon} />}
                {(avatarUri || isLoading) && (
                    <p className={`${text} ${!avatarUri ? black : ""}`}>
                        {isLoading ? "Czekaj..." : "Edytuj"}
                    </p>
                )}
            </BaseFileInput>
        </div>
    );
}

const compressOptions = {
    maxWidthOrHeight: 500,
};
