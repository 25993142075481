const _ = require("lodash");
const checkTargetIndex = (targetPageIndex, pageCount, unansweredPages) => {
    let targetIndex = Number(targetPageIndex);

    const possibleIndexes = genPossibleIndexes(pageCount, unansweredPages);

    switch (true) {
        case isSuccessAccessible(targetPageIndex, pageCount, possibleIndexes):
        case isSuccessInaccessible(targetPageIndex, pageCount, possibleIndexes):
        case isAboveRange(targetIndex, possibleIndexes):
            return possibleIndexes[possibleIndexes.length - 1]; // last possible index
        case isNotNumber(targetIndex):
        case isBelowRange(targetIndex, possibleIndexes):
            return possibleIndexes[0]; // first possible index
        default:
            return targetIndex;
    }
};
const isNotNumber = (target) => !Number.isSafeInteger(target);

const isSuccessAccessible = (target, pageCount, possibleIndexes) => {
    return (
        typeof target === "string" && target === "sukces" && possibleIndexes.includes(pageCount + 1)
    );
};
const isSuccessInaccessible = (target, pageCount, possibleIndexes) => {
    return (
        typeof target === "string" &&
        target === "sukces" &&
        !possibleIndexes.includes(pageCount + 1)
    );
};

const isBelowRange = (target, possibleIndexes) => {
    return (
        Number.isSafeInteger(target) &&
        !possibleIndexes.includes(target) &&
        target < possibleIndexes[0]
    );
};

const isAboveRange = (target, possibleIndexes) => {
    return (
        Number.isSafeInteger(target) &&
        !possibleIndexes.includes(target) &&
        target > possibleIndexes[possibleIndexes.length - 1]
    );
};

const genPossibleIndexes = (pageCount, unansweredPages) => {
    let possibleIndexes = [];
    let index = 0;

    while (index < pageCount && !unansweredPages.includes(index)) {
        index++;
        possibleIndexes.push(index);
    }

    if (unansweredPages.length === 0) {
        possibleIndexes.push(pageCount + 1);
    }

    return possibleIndexes.sort((a, b) => a - b);
};

const checkUnfilledSessions = (sessionsCollection) => {
    let unfilledSession = null;

    if (Array.isArray(sessionsCollection)) {
        const findUnfilled = (item) => item.unansweredPages && item.unansweredPages.length > 0;

        unfilledSession = sessionsCollection.find(findUnfilled) || null;
    }
    return unfilledSession;
};

module.exports = { genPossibleIndexes, checkTargetIndex, checkUnfilledSessions };
