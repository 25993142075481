module.exports = {
    home: "home",
    about: "aboutUs",
    contact: "contact",
    stylize: "stylize",
    faq: "faq",
    blog: "blog",
    login: "login",
    lostPass: "lost-pass",
    resetPassword: "reset-password",
    createAccount: "create-account",
    account: "account",
    auth: "auth",
    measure: "measure",
    whatFits: "what-fits-you",
    beautyType: "beauty-type",
    generator: "generator",
};
