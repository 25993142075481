// extracted by mini-css-extract-plugin
export var center = "title-module--center--Dr8Eu";
export var hidden = "title-module--hidden--U02vA";
export var italic = "title-module--italic--8lf5L";
export var large = "title-module--large--DbkQ7";
export var left = "title-module--left--gMjMC";
export var medium = "title-module--medium--6zRRh";
export var normal = "title-module--normal--yJOMM";
export var right = "title-module--right--cJDzl";
export var small = "title-module--small--D0blM";
export var subtitleText = "title-module--subtitleText--jd2+s";
export var title = "title-module--title--ktbK2";
export var titleContainer = "title-module--title-container--B23e1";
export var visible = "title-module--visible--soyQY";