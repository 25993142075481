import React from "react";

import { accountWrapper, title } from "./account-data.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";

import Section from "../../../components/molecules/section";
import AccountDataForm from "../../auth/forms/account-data-form";
import AccountTitle from "../atoms/account-title";

export default function AccountData() {
    return (
        <div className={mainGrid}>
            <Section column="narrow">
                <AccountTitle className={title}>Dane Konta</AccountTitle>
            </Section>
            <Section column="narrow" className={accountWrapper}>
                <AccountDataForm />
            </Section>
        </div>
    );
}
