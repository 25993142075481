import React from "react";

import { passChangeWrapper, titleText } from "./pass-change.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";

import Section from "../../../components/molecules/section";
import PassChangeForm from "../../auth/forms/pass-change-form";
import AccountTitle from "../atoms/account-title";

export default function PassChange() {
    return (
        <div className={mainGrid}>
            <Section column="narrow">
                <AccountTitle className={titleText}>Zmiana hasła</AccountTitle>
            </Section>
            <Section column={"narrow"} className={passChangeWrapper}>
                <PassChangeForm />
            </Section>
        </div>
    );
}
