import { useSelector } from "react-redux";

import { selectRbac } from "../../redux/rbac/rbac.selectors";

export function recursiveIncludesRights(
    permissions: string[],
    rightsToCheck: Array<string | string[]>
): boolean {
    return rightsToCheck.every((right) => {
        if (Array.isArray(right)) {
            return recursiveIncludesRights(permissions, right);
        }
        return permissions.includes(right);
    });
}

export default function useCheckPermissions(...rightsToCheck: Array<string | string[]>) {
    const permissions = useSelector(selectRbac);

    if (rightsToCheck.length > 0) {
        return recursiveIncludesRights(permissions, rightsToCheck);
    }
    return true;
}
