/*
 * IF TYPESCRIPT THROWS ERROR THAT IT CAN'T FIND MEMBER NAMED AS ONE OF FUNCTION BELOW (TS2724) YOU SHOULD ADD
 * DECLARATION OF SUCH SELECTOR IN survey-session.selectors.d.ts FILE. ADDING DECLARATIONS RESOLVES TYPE ERROR IN
 * survey-session-question-list.tsx FILE IN SELECTORS (AND PROBABLY WILL RESOLVE ERRORS IN OTHER FILES)
 */

import { selectList } from "../generic-list/generic-list.selectors";
import { LIST, QUESTIONS, SURVEY_SESSION } from "./survey-session.reducer";
import { getUniqueListBy } from "../../utils/data-utils";
export const selectSurveySessionList = (state) => selectList(state, SURVEY_SESSION, LIST);

export const selectSurveySessionListItems = (state) =>
    selectList(state, SURVEY_SESSION, LIST).items;

export const selectFirstSurveySession = (state) => {
    const surveySessionList = selectSurveySessionList(state);

    if (Array.isArray(surveySessionList?.items) && surveySessionList.items.length > 0) {
        return surveySessionList.items[0];
    }

    return null;
};

export const selectFirstSurveySessionId = (state) => {
    return selectFirstSurveySession(state)?.sessionId || null;
};

export const selectSurveySessionById = (state, sessionId) => {
    const list = selectSurveySessionListItems(state);
    return list.find((item) => item.sessionId === parseInt(sessionId));
};

export const selectSurveySessionStatusBySessionId = (state, sessionId) => {
    const session = selectSurveySessionById(state, sessionId);
    return session?.status || null;
};

export const selectSurveySessionUserBySessionId = (state, sessionId) => {
    const session = selectSurveySessionById(state, sessionId);
    return session?.user || null;
};

export const selectSurveySessionQuestionsBySessionId = (state, sessionId, asArray = false) => {
    const questions = state[SURVEY_SESSION][QUESTIONS];

    const selectedQuestions = questions[sessionId] ? questions[sessionId] : {};

    return asArray ? Object.values(selectedQuestions) : selectedQuestions;
};

export const selectSurveySessionCategoriesBySessionId = (state, sessionId) => {
    const surveySessionQuestions = selectSurveySessionQuestionsBySessionId(state, sessionId, true);

    if (surveySessionQuestions.length > 0) {
        return getUniqueListBy(
            surveySessionQuestions
                .filter((question) => question.category !== null)
                .map((question) => {
                    return question.category;
                }),
            "id"
        );
    }

    return [];
};

export const selectSurveySessionQuestionByIdAndSessionId = (state, sessionId, questionId) => {
    const questions = selectSurveySessionQuestionsBySessionId(state, sessionId);

    return questions[questionId] ? questions[questionId] : null;
};
