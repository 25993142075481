import actionName from "../action-creator";
import { types } from "./stylizations.actions";

const STYLIZATIONS = `[STYLIZATIONS]`;

const initialState = {
    items: [],
    pagination: {
        totalCount: 0,
        pageCount: 0,
        currentPage: 0,
        perPage: 5,
    },
};

function stylizationsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actionName(STYLIZATIONS, types.SET):
            return payload;
        case actionName(STYLIZATIONS, types.RESTORE_INITIAL):
            return initialState;
        default:
            return state;
    }
}

export { initialState, STYLIZATIONS };
export default stylizationsReducer;
