import React, { useCallback } from "react";
import moment from "moment";

import {
    surveyAnswerQuestionCard,
    question as questionClass,
    answer as answerClass,
    leftWrapper,
    affirmative as affirmativeClass,
    negative as negativeClass,
    style as styleClass,
    budget as budgetClass,
    rightWrapper,
} from "./survey-answer-question-card.module.scss";
import NeutralIcon from "../../../assets/images/svg/neutral.svg";
import AffirmativeIcon from "../../../assets/images/svg/thumb-up.svg";
import NegativeIcon from "../../../assets/images/svg/thumb-down.svg";
import BudgetIcon from "../../../assets/images/svg/coin.svg";
import StyleIcon from "../../../assets/images/svg/love.svg";
import { ISurveyQuestion } from "../../../models/survey/survey-question.model";

import ButtonEdit, { IButtonEditProps } from "../../../components/atoms/button-edit";

export interface ISurveyAnswerQuestionCardProps {
    question: ISurveyQuestion;
    canEdit?: boolean;
    onEdit?(questionId: ISurveyQuestion["questionId"]): void;
    style?: React.CSSProperties;
}

export default function SurveyAnswerQuestionCard({
    question,
    onEdit,
    canEdit = true,
    style,
}: ISurveyAnswerQuestionCardProps) {
    const { context, options, title, answers, unit, questionId } = question;
    const [contextClass, ContextIcon] = getContextData(context);

    const handleEdit = useCallback(() => {
        if (typeof onEdit !== "function") return;
        onEdit(questionId);
    }, [onEdit]);

    const answersToDisplay = translateAnswers(answers, options, unit);
    const buttonHoverColor = getColorByContext(context);

    return (
        <div className={`${surveyAnswerQuestionCard} ${contextClass}`} style={style}>
            <div className={leftWrapper}>
                <p className={questionClass}>{title}</p>
                <p className={answerClass}>{answersToDisplay}</p>
            </div>
            <div className={rightWrapper}>
                <ContextIcon />
                {canEdit && <ButtonEdit onClick={handleEdit} hoverColor={buttonHoverColor} />}
            </div>
        </div>
    );
}

function getColorByContext(context: ISurveyQuestion["context"]): IButtonEditProps["hoverColor"] {
    if (context === "negative") return "red";
    if (context === "affirmative") return "green";
    if (context === "budget") return "gold";
    return "grey";
}

const getContextData = (context: ISurveyQuestion["context"]) => {
    switch (context) {
        case "affirmative":
            return [affirmativeClass, AffirmativeIcon];
        case "negative":
            return [negativeClass, NegativeIcon];
        case "style":
            return [styleClass, StyleIcon];
        case "budget":
            return [budgetClass, BudgetIcon];
        default:
            return ["", NeutralIcon];
    }
};

const translateAnswers = (
    answers: ISurveyQuestion["answers"],
    options: ISurveyQuestion["options"],
    unit: ISurveyQuestion["unit"]
) => {
    const processedAnswers = Array.isArray(answers) ? answers : [answers];

    if (!processedAnswers) {
        return "";
    }

    if (Array.isArray(options) && options.length > 0) {
        return processedAnswers
            .map((answer) => {
                const option = options.find((option) => {
                    const answerId = typeof answer === "string" ? parseInt(answer) : answer;
                    return option.id === answerId;
                });

                return option ? option.label : "";
            }, "")
            .join(", ");
    }

    return processedAnswers
        .map((answer) => {
            if (typeof answer === "string" && moment(answer, "YYYY-MM-DD", true).isValid()) {
                return moment(answer).format("DD.MM.YYYY");
            }
            return `${answer}${unit ? unit : ""}`;
        })
        .join(", ");
};
