import React, { useEffect, useRef } from "react";
import { Formik, Form, FormikHelpers } from "formik";

import {
    form,
    input,
    buttons,
    button,
    cancelIcon,
    checkIcon,
} from "./stylization-edit-form.module.scss";
import CheckmarkIcon from "../../../assets/images/svg/checkmark-02.svg";
import CancelIcon from "../../../assets/images/svg/cancel.svg";

import Input from "../../../components/atoms/form/input";
import Button from "../../../components/atoms/button";

interface IStylizationEditValues {
    name: string;
}

export interface IStylizationEditFormProps {
    className?: string;
    onSubmit(values: IStylizationEditValues, helpers: FormikHelpers<IStylizationEditValues>): void;
    onCancel(): void;
    initialValues: IStylizationEditValues;
}

const StylizationEditForm: React.FC<IStylizationEditFormProps> = ({
    className = "",
    onSubmit,
    onCancel,
    initialValues,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!inputRef.current) return;
        inputRef.current.focus();
    }, []);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
                <Form className={`${form} ${className}`}>
                    <Input
                        ref={inputRef}
                        id="stylization-edit-form-name"
                        name="name"
                        className={input}
                    />
                    <div className={buttons}>
                        <Button
                            htmlType="submit"
                            type="outlined"
                            className={button}
                            color="primary"
                        >
                            <CheckmarkIcon className={checkIcon} />
                        </Button>
                        <Button
                            htmlType="button"
                            onClick={onCancel}
                            type="outlined"
                            className={button}
                            color="secondary"
                        >
                            <CancelIcon className={cancelIcon} />
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default StylizationEditForm;
