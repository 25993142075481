import React from "react";
import { ISurveyQuestion } from "../../../models/survey/survey-question.model";

import {
    circleOption,
    labelText,
    circleInput,
    circleWrapper,
    imgCircle,
    circleIcon,
} from "./survey-option-circle.module.scss";
import Negative from "../../../assets/images/svg/negative.svg";
import Affirmative from "../../../assets/images/svg/affirmative.svg";
import { input } from "../../atoms/form/input.module.scss";
import { FieldHookConfig, useField } from "formik";

import { ISurveyOptionProps } from "../../../models/survey/survey-question-option-props.model";

const SurveyOptionCircle = (props: ISurveyOptionProps) => {
    const { option, inputType, context, questionId } = props;
    const { id, label, imgUrl } = option;
    const Icon = getIcon(context);

    const optionConfig: FieldHookConfig<any> = {
        name: questionId.toString(),
        type: inputType,
        value: id.toString(),
    };

    const [field] = useField(optionConfig);

    return (
        <label className={`${circleOption} ${context}`}>
            <input className={`${circleInput} circle-input`} {...field} type={inputType} />

            <div className={`${circleWrapper} circle-wrapper`}>
                {Icon && <Icon className={circleIcon} />}
                {imgUrl && <img className={`${imgCircle} img-circle`} src={imgUrl} alt="" />}
            </div>

            {label && <p className={labelText}>{label}</p>}
        </label>
    );
};

function getIcon(context: ISurveyQuestion["context"]): React.SVGFactory | null {
    switch (context) {
        case "negative":
            return Negative;
        case "affirmative":
        default:
            return Affirmative;
    }
}

export default SurveyOptionCircle;
