import React from "react";
import { Field, ErrorMessage } from "formik";

import {
    container,
    label,
    input,
    checkbox,
    checkmark,
    labelContent,
} from "./checked-newsletter.module.scss";
import Checked from "../../../assets/images/svg/square-checkbox.svg";

import Error from "./error";

export interface ICheckboxProps {
    className?: string;
    name: string;
    identifier?: string | number;
}

const CheckedNewsletter: React.FC<ICheckboxProps & Record<string, any>> = ({
    className = "",
    name,
    identifier = "",
    children,
    ...rest
}) => {
    return (
        <div className={`${container} ${className}`}>
            <label htmlFor={`${name}${identifier}`} className={label}>
                <Field
                    type="checkbox"
                    id={`${name}${identifier}`}
                    name={name}
                    className={input}
                    {...rest}
                />
                <span className={checkbox}>
                    <Checked className={checkmark} />
                </span>
                <span className={labelContent}>{children}</span>
            </label>
            <ErrorMessage name={name} component={Error} />
        </div>
    );
};

export default CheckedNewsletter;
