import React from "react";

import { container, absolute, fixed, icon } from "./loader.module.scss";
import WandImg from "../../assets/images/svg/wand-loader.svg";

export interface ILoaderProps {
    className?: string;
    position?: "rel" | "absolute" | "fixed";
}

const Loader: React.FC<ILoaderProps> = ({ className = "", position }) => {
    return (
        <div
            className={`
                ${container}
                ${className}
                ${genPositionClass(position)}
            `}
        >
            <WandImg className={icon} />
        </div>
    );
};

function genPositionClass(pos: ILoaderProps["position"]) {
    switch (pos) {
        case "fixed":
            return fixed;
        case "absolute":
            return absolute;
        case "rel":
        default:
            return "";
    }
}

export default Loader;
