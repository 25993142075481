import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { navigate } from "@reach/router";

import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { input, button, linkPass, authForm } from "./auth-forms.module.scss";
import { config } from "../../../config";
import useUser from "../hooks/use-user";
import { IUserLogin } from "../models/payloads.model";
import { getAbsolutePath } from "../../utility/routes.utility";

import Input from "../../../../src/components/atoms/form/input";
import FormikForm from "../../../../src/components/hoc/formik-form";
import Button from "../../../../src/components/atoms/button";

export interface IAvatarProps {
    className?: string;
}

const { required, email } = config.messages.form;

const initialValues: IUserLogin = {
    email: "",
    password: "",
};

const signupSchema = Yup.object({
    email: Yup.string().email(email).required(required),
    password: Yup.string().required(required),
});

const LoginForm: React.FC<IAvatarProps> = ({ className = "" }) => {
    const { t } = useI18next();

    const { logIn } = useUser();

    return (
        <Formik initialValues={initialValues} validationSchema={signupSchema} onSubmit={logIn}>
            {(formik) => (
                <FormikForm
                    formik={formik}
                    successMessage={""}
                    className={`${authForm} ${className}`}
                >
                    <Input
                        name="email"
                        type="email"
                        placeholder={t("form.label.email")}
                        className={input}
                        autoComplete="username"
                    />

                    <Input
                        name="password"
                        type="password"
                        placeholder={t("form.label.password")}
                        className={input}
                        autoComplete="current-password"
                    />

                    <Button color="primary" type="filled" htmlType="submit" className={button}>
                        {t("button.login")}
                    </Button>

                    <Button
                        onClick={() => navigate(getAbsolutePath("AUTH_PASS_RESET_REQUEST"))}
                        className={linkPass}
                    >
                        {t("button.lost-pass")}
                    </Button>
                </FormikForm>
            )}
        </Formik>
    );
};

export default LoginForm;
