import { setSurvey, clearSurvey } from "./survey.actions";

const SURVEY = "[SURVEY]";

const initialState = {
    list: [],
};

function surveyReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case setSurvey().type:
            return {
                ...state,
                list: [...payload],
            };
        case clearSurvey().type:
            return initialState;
        default:
            return state;
    }
}

export default surveyReducer;
export { SURVEY, initialState };
