import React, { useCallback } from "react";
import { navigate } from "gatsby";

import {
    listItem,
    button,
    occasion,
    status,
    stateIndicator,
} from "./survey-session-list-item.module.scss";
import { ISurveySession } from "../../../models/survey/survey-session.model";
import Arrow from "../../../assets/images/svg/arrow.svg";

import SurveySessionColumnItem from "../atoms/survey-session-column-item";
import SurveySessionStateIndicator from "../../account/atoms/survey-session-state-indicator";
import formatDateFromUnix from "../../../utils/format-date-from-unix";

export interface ISurveySessionColumnItemProps {
    surveySessionData: ISurveySession;
}

export default function SurveySessionListItem({
    surveySessionData,
}: ISurveySessionColumnItemProps) {
    const handleClick = useCallback(() => {
        const path = `/adm/ankieta/${surveySessionData.sessionId}`;
        //todo: handle params in getAbsolutePath
        // const path = getAbsolutePath("ADM_SURVEY_SESSION_VIEW_SINGLE", undefined, surveySessionData.sessionId)
        navigate(path);
    }, [surveySessionData.sessionId]);

    return (
        <>
            <SurveySessionColumnItem columnName="email" className={listItem}>
                {surveySessionData.user?.email || "Brak"}
            </SurveySessionColumnItem>

            <SurveySessionColumnItem columnName="occasion" className={`${listItem} ${occasion}`}>
                {surveySessionData.occasion || "Brak"}
            </SurveySessionColumnItem>

            <SurveySessionColumnItem columnName="created-date" className={listItem}>
                {formatDateFromUnix(surveySessionData.user?.createdAt, "DD-MM-YYYY")}
            </SurveySessionColumnItem>

            <SurveySessionColumnItem
                columnName="status"
                Component="div"
                className={`${listItem} ${status}`}
            >
                <SurveySessionStateIndicator
                    state={surveySessionData.status}
                    className={stateIndicator}
                />
                <button className={button} onClick={handleClick}>
                    <Arrow />
                </button>
            </SurveySessionColumnItem>
        </>
    );
}
