import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    accountLayoutContainer,
    accountLayout,
    sidebar,
    sidebarOpened,
    content,
    accountHeader,
    menuShown,
} from "./account-layout.module.scss";

import MainLayout, { IMainLayoutProps } from "./main-layout";
import SidePanel from "../components/molecules/side-panel";
import Header, { IHeaderProps } from "../components/organisms/headers/header";
import UserButton from "../components/atoms/user-button";

export interface IAccountLayoutProps extends IMainLayoutProps {
    contentClassName?: string;
}

export default function AccountLayout({
    children,
    contentClassName = "",
    SEOProps,
}: IAccountLayoutProps) {
    const [userMenuShow, setUserMenuShow] = useState(false);

    const handleUserMenuClose = () => {
        setUserMenuShow(!userMenuShow);
    };

    const renderCustomButtons: IHeaderProps["renderCustomButtons"] = (buttons, headerProps) => (
        <>
            <UserButton
                color={headerProps.color}
                isOpen={userMenuShow}
                onClick={handleUserMenuClose}
            />
            {buttons}
        </>
    );

    return (
        <MainLayout
            containerClassName={accountLayoutContainer}
            className={accountLayout}
            headerClassName={accountHeader}
            HeaderComponent={<Header color="black" renderCustomButtons={renderCustomButtons} />}
            overflow={userMenuShow}
            SEOProps={SEOProps}
        >
            {userMenuShow && (
                <Helmet>
                    <body className={menuShown} />
                </Helmet>
            )}
            <SidePanel
                className={`${sidebar} ${userMenuShow ? sidebarOpened : ""}`}
                onClose={handleUserMenuClose}
            />
            <div className={`${content} ${contentClassName}`}>{children}</div>
        </MainLayout>
    );
}
