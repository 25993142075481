import React, { useEffect, useState } from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import {
    container,
    section,
    content,
    text,
    titleSvg,
    header,
    list,
    item,
    card,
    error,
    sharebuttons,
    shareallbutton
} from "./stylization-view.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import Congratulation from "../../../assets/images/svg/success.svg";
import ShareIcon from "../../../assets/images/svg/share.svg";
import { IStylization } from "../../../models/stylization.model";
import { TStatus } from "../../../models/status.model";
import { getAbsolutePath } from "../../utility/routes.utility";
import {
    selectSingleStylization,
    selectSingleStylizationStatus,
} from "../../../redux/single-stylization/single-stylization.selectors";
import {
    clearSingleStylizationAction,
    getSingleStylizationAction,
} from "../../../redux/single-stylization/single-stylization.actions";

import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from "react-share";
import Section from "../../../components/molecules/section";
import Title from "../../../components/atoms/title";
import ProductCard from "../molecules/product-card";
import Button from "../../../components/atoms/button";
import Loader from "../../../components/atoms/loader";
import { Helmet } from "react-helmet";

type TStylizationViewProps = {
    stylizationId?: number;
    guid: string;
    isCongratulationSite?: boolean;
} & RouteComponentProps;

const customIconProps = {
    size: 40,
    bgStyle: {
        fill: "none",
    },
    iconFillColor: "black",
};

const StylizationView: React.FC<TStylizationViewProps> = ({
    stylizationId,
    guid,
    isCongratulationSite,
}) => {
    const { t } = useI18next();
    const dispatch = useDispatch();

    const [shareUrl, setShareUrl] = useState('');


    const { site } = useStaticQuery(query);
    const { pathname } = useLocation();


    const {
        url: siteUrl,
        shareApiUrl: shareApiUrl
    } = site.siteMetadata;

    const seo = {
        url: `${siteUrl}${pathname}`,
    };

    const stylization: IStylization | null = useSelector(selectSingleStylization);
    const status: TStatus = useSelector(selectSingleStylizationStatus);

    const { name, products = [], occasion } = stylization || {};

    const handleReturn = () => {
        navigate(getAbsolutePath("ACCOUNT_STYLIZATIONS"));
    };

    const shareAll = async () => {
        const shareData = {
            title: "Fashionfairy stylizacja",
            text: "Zobacz jaką stylizację mi wyczarowali!",
            url: shareUrl,
        };
        await navigator.share(shareData);
    }

    useEffect(() => {
        console.log("stylizationId: ",stylizationId);
        console.log("stylization guid: ",guid);
        if (!stylizationId) return;
        dispatch(getSingleStylizationAction(stylizationId, guid));
        return () => {
            dispatch(clearSingleStylizationAction());
        };
    }, [dispatch, stylizationId, guid]);

    useEffect(() => {
        let share = shareApiUrl + "/stylization/view/" + stylizationId + "/" + stylization?.guid;
        console.log('share: ',share);
        setShareUrl(share);
    }, [JSON.stringify(stylization)]);

    return (
        <div className={`${container} ${mainGrid}`}>
            <Section className={section}>
                <div className={content}>
                    {(!status || status === "loading" || status === "idle") && (
                        <Loader position={"absolute"} />
                    )}
                    {status === "fail" && (
                        <p className={error}>{t("generator.stylization.fail")}</p>
                    )}
                    {status === "success" && stylization && (
                        <>
                            <div className={header}>
                                {isCongratulationSite ? (
                                    <>
                                        <Congratulation className={titleSvg} />
                                        <h1 className={text}>{t("generator.stylization.ready")}</h1>
                                    </>
                                ) : (
                                    <Title
                                        Tag="h1"
                                        subtitle={occasion?.label}
                                        align="center"
                                        fontStyle="italic"
                                    >
                                        {name}
                                    </Title>
                                )}
                            </div>
                            <Helmet>
                                <meta name="image" content={stylization.shareUrl} />
                                <meta property="og:image" content={stylization.shareUrl} />
                                <meta property="twitter:image" content={stylization.shareUrl} />
                                <meta property="og:url" content={shareUrl} />
                            </Helmet>
                            <ul className={list}>
                                {products.map((product) => {
                                    return (
                                        <li key={product.productId} className={item}>
                                            <ProductCard product={product} className={card} />
                                        </li>
                                    );
                                })}
                            </ul>
                            <div>
                                <p>Podziel się ze znajomymi:</p>
                                <div className={sharebuttons}>
                                    <FacebookShareButton url={shareUrl}>
                                        <FacebookIcon {...customIconProps}></FacebookIcon>
                                    </FacebookShareButton>
                                    <TwitterShareButton url={shareUrl}>
                                        <TwitterIcon {...customIconProps} />
                                    </TwitterShareButton>
                                    <LinkedinShareButton url={shareUrl}>
                                        <LinkedinIcon {...customIconProps} />
                                    </LinkedinShareButton>
                                    <div className={shareallbutton}>
                                        <ShareIcon onClick={shareAll}></ShareIcon>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <Button onClick={handleReturn} type="filled">
                    {isCongratulationSite
                        ? t(`generator.stylization.ready.button`)
                        : t(`generator.stylization.view.button`)}
                </Button>
            </Section>
        </div>
    );
};

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                image
                url
                shareApiUrl
            }
        }
    }
`;
export default StylizationView;
