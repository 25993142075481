import React from "react";

import {
    adminLayout,
    avatar,
    email,
    innerWrapper,
    name,
    registerDate,
    surveySessionHeader,
    statusSelect,
} from "./survey-session-header.module.scss";
import UserAvatarIcon from "../../../assets/images/svg/user-avatar.svg";
import { config } from "../../../config";
import { ISurveySession } from "../../../models/survey/survey-session.model";
import SurveySessionState, {
    SurveySessionStateLabels,
} from "../../../enums/survey-session-state.enum";
import useCheckPermissions from "../../hooks/use-check-permissions";
import formatDateFromUnix from "../../../utils/format-date-from-unix";

import SurveySessionHeaderElement from "../atoms/survey-session-header-element";
import SurveySessionStateIndicator from "../atoms/survey-session-state-indicator";
import Select, { ISelectRenderItemProps } from "../../../components/molecules/select";
import UserAvatar from "../../../components/atoms/user-avatar";

const { Admin } = config.rbac.permissionMap;

type TSurveySessionHeaderProps = {
    changeStatus: (value: SurveySessionState) => void;
    sessionId?: number | string;
} & Pick<ISurveySession, "user" | "status">;

export default function SurveySessionHeader({
    user,
    status,
    changeStatus,
}: TSurveySessionHeaderProps) {
    const isAdmin = useCheckPermissions(Admin.role);

    const avatarUri = user?.profiles?.[0]?.avatarUri;

    return (
        <div className={surveySessionHeader}>
            <div className={`${innerWrapper} ${isAdmin ? adminLayout : ""}`}>
                {isAdmin && avatarUri && <UserAvatar src={avatarUri} className={avatar} />}
                {isAdmin && !avatarUri && <UserAvatarIcon className={avatar} />}

                <SurveySessionHeaderElement title="Data rejestracji" className={registerDate}>
                    {user?.createdAt ? formatDateFromUnix(user.createdAt, "DD-MM-YYYY") : "Brak"}
                </SurveySessionHeaderElement>

                <SurveySessionHeaderElement title="E-mail" className={email}>
                    {user ? user.email : "Brak adresu"}
                </SurveySessionHeaderElement>

                {isAdmin && (
                    <SurveySessionHeaderElement title="Imię" className={name}>
                        {user?.profiles ? user.profiles[0]?.firstName : "Brak imienia"}
                    </SurveySessionHeaderElement>
                )}
            </div>

            <div>
                <SurveySessionHeaderElement title="Status">
                    {isAdmin ? (
                        <Select
                            items={SELECT_ITEMS}
                            renderItem={selectRenderItem}
                            value={status}
                            onChange={changeStatus}
                            className={statusSelect}
                        />
                    ) : (
                        <SurveySessionStateIndicator state={status} />
                    )}
                </SurveySessionHeaderElement>
            </div>
        </div>
    );
}

const selectRenderItem = ({ item }: ISelectRenderItemProps<SurveySessionState>) => {
    return <SurveySessionStateIndicator state={item.value} />;
};

const SELECT_ITEMS = [
    {
        label: SurveySessionStateLabels[SurveySessionState.Undefined],
        value: SurveySessionState.Undefined,
    },
    {
        label: SurveySessionStateLabels[SurveySessionState.New],
        value: SurveySessionState.New,
    },
    {
        label: SurveySessionStateLabels[SurveySessionState.Completed],
        value: SurveySessionState.Completed,
    },
    {
        label: SurveySessionStateLabels[SurveySessionState.Processing],
        value: SurveySessionState.Processing,
    },
    {
        label: SurveySessionStateLabels[SurveySessionState.Refused],
        value: SurveySessionState.Refused,
    },
    {
        label: SurveySessionStateLabels[SurveySessionState.Finished],
        value: SurveySessionState.Finished,
    },
];
