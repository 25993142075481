import React, { AnimationEventHandler, useCallback } from "react";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { navItem, navItemOutlined, bottomLogo } from "./menu-vertical.module.scss";
import { IMenuLinksElement } from "../../models/menu-links-element.model";

import MenuTemplate from "../molecules/menu-template";
import Button from "../atoms/button";
import Link from "../atoms/link";
import Logo from "../atoms/logo";
import MenuCloseButton from "../atoms/menu-close-button";
import useMenuClosingButton from "../../hooks/use-menu-closing-button";

export interface MenuVerticalProps {
    navItems: IMenuLinksElement[];
    onClose?: () => void;
}

export default function MenuVertical({ navItems, onClose }: MenuVerticalProps) {
    const { t } = useI18next();
    const {
        animatedElementRef,
        closing,
        onMenuClosingButtonClick,
        onAnimationEnd,
    } = useMenuClosingButton<HTMLDivElement>(onClose);

    const handleAnimationEnd: AnimationEventHandler = useCallback(
        (event) => {
            onAnimationEnd(event.target);
        },
        [onAnimationEnd]
    );

    return (
        <MenuTemplate
            ref={animatedElementRef}
            onAnimationEnd={handleAnimationEnd}
            closing={closing}
            color="violet"
            header={<MenuCloseButton closing={closing} onClick={onMenuClosingButtonClick} />}
            content={navItems
                .filter((item) => !item.logo)
                .map(({ to, labelKey, type, as }) => {
                    const Tag = as === "button" ? Button : Link;

                    return (
                        <Tag
                            key={`menu-vertical-${labelKey}`}
                            active={false}
                            to={to}
                            color="white"
                            type={type !== "nav-item" ? type : undefined}
                            textUppercase={type === "outlined"}
                            className={type !== "outlined" ? navItem : navItemOutlined}
                            additionalProps={{ onClick: onMenuClosingButtonClick }}
                        >
                            {t(labelKey)}
                        </Tag>
                    );
                })}
            footer={
                <Link
                    to="/"
                    className={bottomLogo}
                    additionalProps={{ onClick: onMenuClosingButtonClick }}
                >
                    <Logo color="white" type="logo" />
                </Link>
            }
        />
    );
}
