// extracted by mini-css-extract-plugin
export var button = "footer-module--button--ggdrM";
export var footer = "footer-module--footer--ef16N";
export var footerLink = "footer-module--footer-link--xRRKb";
export var hidden = "footer-module--hidden--kRDmw";
export var links = "footer-module--links--AXweY";
export var logo = "footer-module--logo--VkX-G";
export var logoAp = "footer-module--logo-ap--TQx2p";
export var lower = "footer-module--lower--6sRY9";
export var social = "footer-module--social--2797v";
export var upper = "footer-module--upper--fV8I6";