import actionName from "../action-creator";
import { STYLIZATIONS } from "./stylizations.reducer";
import {
    restoreStylizationsInitialStateAction,
    setStylizationsAction,
    types,
} from "./stylizations.actions";
import endpoints from "../../config/endpoints";
import { selectStylizationsPagination } from "./stylizations.selectors";
import { actions, sendApiRequest } from "../api/api.actions";
import { statusIdle } from "../ui/ui.actions";

const stylizationsMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;

    next(action);

    let fetchConfig;

    switch (type) {
        case actionName(STYLIZATIONS, types.GET):
            const pagination = selectStylizationsPagination(getState());
            const perPage = payload?.perPage || pagination.perPage;
            const page = payload?.page;
            fetchConfig = {
                url: endpoints.stylizations.page,
                method: "GET",
                withCredentials: true,
                params: {
                    expand: "products",
                    "per-page": perPage,
                    sort: "-createdAt",
                    ...(page ? { page } : {}),
                },
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;
        case actionName(STYLIZATIONS, `${types.GET} ${actions.API_SUCCESS}`):
            dispatch(setStylizationsAction(payload));
            break;
        case actionName(STYLIZATIONS, types.CLEAR):
            dispatch(statusIdle({ actionName: types.CLEAR, entity: STYLIZATIONS }));
            dispatch(restoreStylizationsInitialStateAction());
            break;
        default:
            break;
    }
};

export default stylizationsMiddleware;
