import React, { CSSProperties } from "react";
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from "gatsby-plugin-image";

import { ratioContainer, imgBox, img } from './ratio-image.module.scss';

export interface IRatioImageProps {
    className?: string,
    ratioClass?: string,
    image: IGatsbyImageData | undefined,
    alt?: string,
    objectFit?: CSSProperties['objectFit'],
    loading?: GatsbyImageProps['loading'],
}

const RatioImage: React.FC<IRatioImageProps> = ({
    className = '',
    ratioClass = '',
    image,
    alt = '',
    objectFit = 'cover',
    loading,
}) => {
    return (
        <div className={className}>
            <div className={`${ratioContainer} ${ratioClass}`}>
                <div className={imgBox}>
                    {image && (
                        <GatsbyImage
                            className={img}
                            image={image}
                            alt={alt || ''}
                            objectFit={objectFit}
                            loading={loading}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RatioImage;