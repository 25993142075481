import { Namespace, useTranslation, UseTranslationOptions } from "react-i18next";
import { useContext } from 'react';

import { I18nextContext } from './i18nextContext';

export const useI18next = (ns?: Namespace, options?: UseTranslationOptions) => {
    const { i18n, t, ready } = useTranslation(ns, options);
    const context = useContext(I18nextContext);

    return {
        ...context,
        i18n,
        t,
        ready,
    };
}