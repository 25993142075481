export function isSettledFulfilled<Value extends any>(
    promise: PromiseSettledResult<Value>
): promise is PromiseFulfilledResult<Value> {
    return promise.status === "fulfilled";
}

export function isSettledRejected<Value extends any>(
    promise: PromiseSettledResult<Value>
): promise is PromiseRejectedResult {
    return promise.status === "rejected";
}
