import { applyMiddleware } from "redux";

import uiMiddleware from "./ui/ui.middleware";
import alertsMiddleware from "./alerts/alerts.middleware";
import apiMiddleware from "./api/api.middleware";
import userMiddleware from "./user/user.middleware";
import formikMiddleware from "./formik/formik.middleware";
import surveyMiddleware from "./survey/survey.middleware";
import surveyFillMiddleware from "./survey-fill/survey-fill.middleware";
import surveySessionMiddlewares from "./survey-session/survey-session.middleware";
import stylizationsMiddleware from "./stylizations/stylizations.middleware";
import singleStylizationMiddleware from "./single-stylization/single-stylization.middleware";
import generatorFiltersMiddleware from "./generator-filters/generator-filters.middleware";
import generatorProductsMiddleware from "./generator-products/generator-products.middleware";

const middlewareList = applyMiddleware(
    userMiddleware,
    formikMiddleware,
    apiMiddleware,
    uiMiddleware,
    alertsMiddleware,
    surveyMiddleware,
    surveyFillMiddleware,
    ...surveySessionMiddlewares,
    stylizationsMiddleware,
    singleStylizationMiddleware,
    generatorFiltersMiddleware,
    generatorProductsMiddleware
);

export default middlewareList;
