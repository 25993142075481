import React from "react";

import { EModal } from "../models/modal.model";

const ConfirmationModal = React.lazy(() => import("../components/modals/confirmation-modal"));
const SurveyRegisterModal = React.lazy(() => import("../apps/survey/modals/survey-register-modal"));

export const modalMap: Record<EModal, React.ElementType> = {
    [EModal.CONFIRMATION]: ConfirmationModal,
    [EModal.SURVEY_REGISTER]: SurveyRegisterModal,
};
