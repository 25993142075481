import React, { useState } from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { ErrorMessage, FieldMetaProps, useField } from "formik";

import {
    container,
    select,
    closeLayer,
    labelText,
    selectButton,
    selectButtonContent,
    selectButtonText,
    counter,
    arrowSvg,
    light,
    compact,
    check,
    iconMulti,
    chosenOption,
    containerMulti,
    exit,
    showCheck,
    showExit,
    imgMulti,
    open,
    selectArrow,
    selected,
    selectOption,
    selectOptionList,
} from "./select.module.scss";
import Arrow from "../../../assets/images/svg/expand-button.svg";
import Exit from "../../../assets/images/svg/exit.svg";
import Check from "../../../assets/images/svg/select-check.svg";
import { TOption } from "../../../models/option.model";

import Error from "./error";

export interface ISelectProps {
    options: TOption[];
    name: string;
    placeholder?: string | undefined;
    colorTheme?: "dark" | "light";
    styleTheme?: "normal" | "compact";
    showLabel?: boolean;
    label?: string;
    className?: string;
    isMultiselect?: boolean;
    showCounter?: boolean;
    selectText?: "label" | "placeholder" | "option";
    listClassName?: string;
}

const Select: React.FC<ISelectProps> = ({
    options,
    name,
    placeholder,
    colorTheme = "dark",
    styleTheme = "normal",
    showLabel = false,
    label,
    className = "",
    isMultiselect = false,
    showCounter = false,
    selectText = "option",
    listClassName = "",
}) => {
    const { t } = useI18next();
    const [isOpen, setIsOpen] = useState(false);
    const [field, meta, helpers] = useField(name);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleOptionClick = (option: TOption) => () => {
        const optionValue = option.value || option.id;

        if (isMultiselect) {
            const fieldValues = field.value;
            if (fieldValues.includes(optionValue)) {
                helpers.setValue(fieldValues.filter((value: string) => value !== optionValue));
            } else {
                helpers.setValue([...fieldValues, optionValue]);
            }
            return;
        } else {
            helpers.setValue(optionValue);
            setIsOpen(false);
        }
    };

    return (
        <div
            className={`
                ${container} 
                ${getColorThemeClass(colorTheme)} 
                ${getStyleThemeClass(styleTheme)}
                ${isOpen ? open : ""} 
                ${className}
            `}
        >
            {showLabel && label && <p className={labelText}>{label}</p>}
            <label className={select}>
                <button className={closeLayer} onClick={handleClose} type="button" tabIndex={-1} />
                <button
                    type="button"
                    onClick={handleOpen}
                    className={getButtonClass(meta.value)}
                    {...(isOpen ? { tabIndex: 1 } : {})}
                >
                    <div className={selectButtonContent}>
                        <span className={selectButtonText}>
                            {getSelectText({
                                value: meta.value,
                                placeholder,
                                options,
                                label,
                                selectText,
                            }) || t("select.default.text")}
                        </span>
                        {showCounter && Array.isArray(meta.value) && meta.value.length > 0 && (
                            <span className={counter}>{meta.value.length}</span>
                        )}
                    </div>
                    <span className={selectArrow}>
                        <Arrow className={arrowSvg} />
                    </span>
                </button>
                <input type="hidden" {...field} />
                {isOpen && (
                    <datalist className={`${selectOptionList} ${listClassName}`}>
                        {options &&
                            options.map((option, index) => {
                                return (
                                    <button
                                        type="button"
                                        key={option.id || option.value}
                                        value={option.id || option.value}
                                        tabIndex={index + 2}
                                        className={getOptionClass(meta, option, isMultiselect)}
                                        onClick={handleOptionClick(option)}
                                    >
                                        {isMultiselect ? (
                                            <div className={containerMulti}>
                                                <img
                                                    src={option.imgUrl || ""}
                                                    alt=""
                                                    className={imgMulti}
                                                />
                                                <span>{option.label}</span>
                                                <div className={iconMulti}>
                                                    <Exit className={exit} />
                                                    <Check className={check} />
                                                </div>
                                            </div>
                                        ) : (
                                            option.label
                                        )}
                                    </button>
                                );
                            })}
                    </datalist>
                )}
            </label>
            <ErrorMessage name={name} component={Error} />
        </div>
    );
};

function getColorThemeClass(colorTheme: ISelectProps["colorTheme"]) {
    if (colorTheme === "light") return light;
    return "";
}

function getStyleThemeClass(styleTheme: ISelectProps["styleTheme"]) {
    if (styleTheme === "compact") return compact;
    return "";
}

function isOptionInFieldValue(option: TOption, value: string | number | Array<number | string>) {
    const optionValue = option.value || option.id;

    if (!value || !optionValue) return;

    if (Array.isArray(value)) {
        return value.includes(optionValue);
    }

    return value === optionValue;
}

interface IGetSelectTextConfig {
    value: string | number | Array<string | number>;
    options: TOption[];
    selectText: ISelectProps["selectText"];
    label: ISelectProps["label"];
    placeholder: ISelectProps["placeholder"];
}

function getSelectText({
    value,
    options,
    selectText,
    placeholder,
    label,
}: IGetSelectTextConfig): string | undefined {
    if (selectText === "label") return label;
    if (selectText === "placeholder") return placeholder;
    if (!value || (Array.isArray(value) && !value.length)) {
        return placeholder;
    }
    return getLabelFromValue(value, options);
}

function getLabelFromValue(
    value: string | number | Array<string | number>,
    options: TOption[] = []
) {
    const localValue = Array.isArray(value) ? value : [value];

    const labelArr = localValue
        .map((value) => {
            return options.find((option) => (option.id || option.value) === value)?.label;
        })
        .filter((label) => !!label);

    return labelArr.join(", ");
}

function getButtonClass(val: string | Array<string | number> | number) {
    if (typeof val === "number") {
        val = val.toString();
    }
    return `${selectButton} ${val?.length ? chosenOption : ""}`;
}

function getOptionClass(meta: FieldMetaProps<any>, option: TOption, isMultiselect = false) {
    const optionValue = option.id || option.value;
    if (isMultiselect) {
        return `${selectOption} ${isOptionInFieldValue(option, meta.value) ? showCheck : showExit}`;
    }
    return `${selectOption} ${parseInt(meta.value) === optionValue ? selected : ""}`;
}

export default Select;
