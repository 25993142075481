import React from "react";
import { ISurveyOptionProps } from "../../../models/survey/survey-question-option-props.model";

import {
    hoverOption,
    labelText,
    hoverInput,
    hoverWrapper,
    imgHover,
    hoverIcon,
} from "./survey-option-square-hover.module.scss";

import Checked from "../../../assets/images/svg/checked.svg";
import { input } from "../../atoms/form/input.module.scss";
import { FieldHookConfig, useField } from "formik";

const SurveyOptionSquareHover = (props: ISurveyOptionProps) => {
    const { option, inputType, questionId } = props;
    const { id, label, imgUrl } = option;

    const optionConfig: FieldHookConfig<any> = {
        name: questionId.toString(),
        type: inputType,
        value: id.toString(),
    };

    const [field] = useField(optionConfig);

    return (
        <label className={hoverOption}>
            <input className={hoverInput} {...field} type={inputType} />
            <div className={hoverWrapper}>
                <Checked className={hoverIcon} />
                {imgUrl && <img className={imgHover} src={imgUrl} alt="" />}
                {label && <p className={labelText}>{label}</p>}
            </div>
        </label>
    );
};

export default SurveyOptionSquareHover;
