import React from "react";
import { navigate } from "gatsby";
import { RouteComponentProps, useLocation } from "@reach/router";

import useCheckPermissions from "../hooks/use-check-permissions";
import { processRedirect } from "../utility/routes.utility";
import { IRoute } from "../models/route.model";
import windowAccessible from "../../utils/check-window";

export type PrivateRouteProps = {
    children?: React.ReactElement[] | React.ReactElement;
    redirect?: IRoute["redirect"];
    Component: React.FC<any>;
    permissions?: Parameters<typeof useCheckPermissions>[0];
    accessDeniedPath?: string;
    routeData: IRoute;
} & RouteComponentProps;

export default function PrivateRoute({
    permissions,
    path,
    children,
    redirect: rawRedirect,
    Component,
    accessDeniedPath,
    routeData,
    ...rest
}: PrivateRouteProps) {
    const location = useLocation();

    let component = null,
        navTo = null;

    const redirect = processRedirect(rawRedirect);

    switch (true) {
        case location.pathname.slice(-1) === "/":
            navTo = {
                path: location.pathname.slice(0, -1),
                config: { replace: true },
            };
            break;
        case (redirect && redirect.from === location.pathname && redirect.from !== redirect.to) ||
            (redirect && !redirect.from):
            navTo = { path: redirect && redirect.to, config: { replace: true } };
            break;
        case Component !== null && Component !== undefined:
            component = (
                <Component
                    path={path}
                    location={location}
                    permissions={permissions}
                    accessDeniedPath={accessDeniedPath}
                    {...rest}
                >
                    {children}
                </Component>
            );
            break;
        default:
            component = <>{children}</>;
    }

    if (navTo?.path && windowAccessible()) {
        navigate(navTo.path, navTo.config);
    }

    return component;
}
