import React, { useEffect } from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { Formik } from "formik";
import { navigate } from "gatsby";

import { container, buttons } from "./survey-question-edit.module.scss";
import { config } from "../../../config";
import useSurveySession from "../../../apps/hooks/use-survey-session";
import { genInitialValues, genValidationSchema } from "../../../utils/survey.utils";

import FormikForm from "../../hoc/formik-form";
import Button from "../../atoms/button";
import SurveyQuestion from "./survey-question";
import Error from "../../atoms/form/error";
import Loader from "../../atoms/loader";

interface ISurveyQuestionEditProps {
    className?: string;
    questionId: string | number | undefined;
    sessionId: string | number | undefined;
}

const { statusMap } = config;

const SurveyQuestionEdit: React.FC<ISurveyQuestionEditProps> = ({
    className,
    questionId,
    sessionId,
}) => {
    const { t } = useI18next();
    const { isLoading, singleQuestion, submitAnswer, getSingleSession } = useSurveySession({
        sessionId,
        questionId,
    });

    useEffect(() => {
        if (singleQuestion) return;
        getSingleSession();
    }, [singleQuestion, getSingleSession]);

    return (
        <div className={`${container} ${className}`}>
            {isLoading && <Loader position={singleQuestion ? "absolute" : "rel"} />}
            {singleQuestion !== null && (
                <Formik
                    validationSchema={genValidationSchema([singleQuestion], t)}
                    initialValues={genInitialValues([singleQuestion])}
                    onSubmit={submitAnswer}
                    enableReinitialize
                >
                    {(formik) => {
                        return (
                            <FormikForm
                                formik={formik}
                                successMessage={""}
                                loaderType={"none"}
                                showErrors={false}
                            >
                                {singleQuestion && <SurveyQuestion question={singleQuestion} />}
                                {formik.status === statusMap.fail && formik.errors.global && (
                                    <Error>{formik.errors.global}</Error>
                                )}
                                <div className={buttons}>
                                    <Button onClick={goBack} htmlType="button" type="outlined">
                                        {t("button.back")}
                                    </Button>
                                    <Button type="filled" htmlType="submit">
                                        {t("survey.question.save")}
                                    </Button>
                                </div>
                            </FormikForm>
                        );
                    }}
                </Formik>
            )}
        </div>
    );
};

function goBack() {
    return navigate(-1);
}

export default SurveyQuestionEdit;
