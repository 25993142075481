import React from "react";

import { error } from "./error.module.scss";

interface IErrorProps {
    className?: "";
    children?: React.ReactNode;
}

const Error: React.FC<IErrorProps> = ({ children, className = "" }) => {
    return <p className={`${error} ${className}`}>{children}</p>;
};

export default Error;
