import { ALERTS } from "./alerts.reducer";
import { action } from "../action-creator";
import random from "../../utils/random-string";

const actions = {
    SET_ALERT: `set`,
    CLEAR_ALERT: `clear`,
    CLEAR_ALL_ALERTS: `clear_all`,
};

const setAlert = (payload, meta) => {
    const entity = meta?.entity;
    const actionType = meta?.actionName;
    const enrichedMeta = {
        ...meta,
        id: `${Date.now()}-${random()}${meta.entity ? "-" + meta.entity : ""}`,
    };
    const actionName = `${actionType ? actionType : ""} ${ALERTS} ${actions.SET_ALERT}`;

    return action(entity ? entity : "", actionName, payload, enrichedMeta);
};

const clearAllAlerts = () => action(ALERTS, actions.CLEAR_ALL_ALERTS);

const deleteAlert = (alertId) => action(ALERTS, actions.CLEAR_ALERT, alertId);

export { actions, setAlert, clearAllAlerts, deleteAlert };
