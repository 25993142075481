import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "@reach/router";

import { list, loader } from "./survey-session-list.module.scss";
import { LIST, SURVEY_SESSION } from "../../../redux/survey-session/survey-session.reducer";
import { selectSurveySessionListItems } from "../../../redux/survey-session/survey-session.selectors";
import { getAbsolutePath } from "../../utility/routes.utility";
import useSurveySession from "../../hooks/use-survey-session";

import SurveySessionListItem from "../molecules/survey-session-list-item";
import SurveySessionListHeader from "../molecules/survey-session-list-header";
import GenericListPagination, {
    GenericListTransformPageFunction,
} from "../../../components/molecules/generic-list-pagination";
import Loader from "../../../components/atoms/loader";

interface ISurveySessionListProps {
    className?: string;
}

export default function SurveySessionList({ className = "" }: ISurveySessionListProps) {
    const { pageNumber } = useParams();
    const { isLoading, getSessionsList } = useSurveySession({ page: pageNumber });
    const surveySessions = useSelector(selectSurveySessionListItems);

    useEffect(() => {
        getSessionsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    return (
        <div className={className}>
            <div className={list}>
                <SurveySessionListHeader />
                {isLoading ? (
                    <Loader className={loader} />
                ) : (
                    surveySessions.map((surveySession) => (
                        <SurveySessionListItem
                            key={`survey-session-list-${surveySession.sessionId}`}
                            surveySessionData={surveySession}
                        />
                    ))
                )}
            </div>
            <GenericListPagination
                entity={SURVEY_SESSION}
                subEntity={LIST}
                transformPageSlug={transformSurveySessionListPage}
            />
        </div>
    );
}

const transformSurveySessionListPage: GenericListTransformPageFunction = (pageIndex) => {
    return `${getAbsolutePath("ADM_SURVEY_SESSION_LIST_HOME")}/${pageIndex}`;
};
