module.exports = {
    banner: ["title", "description", "buttonText", "buttonUrl", "media"],
    instagramCard: ["title", "buttonText"],
    singleFaq: ["question", "answer"],
    author: ["firstName", "lastName", "title", "bio"],
    post: ["slug", "title", "lead", "content", "media"],
    tag: ["slug", "name"],
    category: ["slug", "name"],
    opinion: ["name", "content"],
};
