// extracted by mini-css-extract-plugin
export var active = "button-module--active--adtGX";
export var beige = "button-module--beige--Ih2Md";
export var black = "button-module--black--tcOMz";
export var button = "button-module--button--y0y0K";
export var disabled = "button-module--disabled--bheVQ";
export var filled = "button-module--filled--q7w90";
export var grey = "button-module--grey--cZQjk";
export var link = "button-module--link--3EnfM";
export var navItem = "button-module--nav-item--gvUd3";
export var outlined = "button-module--outlined--6kTqr";
export var primary = "button-module--primary--xWZI3";
export var secondary = "button-module--secondary--7fEJf";
export var smallHeightPadding = "button-module--smallHeightPadding--LH2Ju";
export var text = "button-module--text--Te-Ra";
export var textTransform = "button-module--text-transform--Zdqy9";
export var white = "button-module--white--+nREI";