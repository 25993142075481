import React, { createContext, useContext, useState } from "react";

import { TModal, TModalData } from "../models/modal.model";

export interface IModalContext {
    modals: TModal[];
    addModal(modal: TModalData | undefined): void;
    removeModal(modalId: number | undefined): void;
}

const initialContext: IModalContext = {
    modals: [],
    addModal: () => {},
    removeModal: () => {},
}

const ModalContext = createContext(initialContext);

export const ModalContextProvider: React.FC = ({children}) => {
    const [modals, setModals] = useState<TModal[]>([]);

    const addModal: IModalContext['addModal'] = (modal) => {
        setModals((prevModals) => {
            if (!modal) return  prevModals;
            const newModal: TModal = {
                modalId: new Date().getTime(),
                ...modal,
            }
            return [...prevModals, newModal];
        });
    };

    const removeModal: IModalContext['removeModal'] = (modalId) => {
        setModals((prevModals) => {
            return prevModals.filter((modal) => modal.modalId !== modalId);
        });
    };

    return (
        <ModalContext.Provider value={{ modals, addModal, removeModal }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const context = useContext(ModalContext);

    if (typeof context === 'undefined') {
        throw new Error('useModalContext was used outside of its Provider');
    }

    return context;
}