import React from "react";
import { navigate } from "gatsby";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    surveyEnd,
    portrait,
    success,
    happyEnd,
    button,
    imgWrapper,
    logo,
} from "./survey-success.module.scss";
import PortraitSurvey from "../../../assets/images/svg/portrait-survey.svg";
import Success from "../../../assets/images/svg/success.svg";
import getTranslationKey from "../../../utils/get-translation-key";
import { getAbsolutePath } from "../../utility/routes.utility";

import MainLayout from "../../../layouts/main-layout";
import Section from "../../../components/molecules/section";
import Button from "../../../components/atoms/button";
import Logo from "../../../components/atoms/logo";

const SurveySuccess = () => {
    const { t } = useI18next();
    const surveyTranslations = getTranslationKey("survey");

    const navToGenerator = async () => {
        await navigate(getAbsolutePath("GENERATOR_NEW_STYLIZATION"), {
            state: { isSurveySuccessRedirect: true },
            replace: true,
        });
    };

    return (
        <MainLayout HeaderComponent={null} footer={false} isSurvey={true}>
            <Section column={"regular"} className={surveyEnd}>
                <Logo className={logo} color={"black"} type={"responsive"} />

                <div className={imgWrapper}>
                    <PortraitSurvey className={portrait} />
                    <Success className={success} />
                </div>

                <div className={happyEnd}>
                    <p>Udało Ci się ukończyć ankietę.</p>
                </div>

                <Button className={button} type="filled" onClick={navToGenerator}>
                    {t(surveyTranslations("end"))}
                </Button>
            </Section>
        </MainLayout>
    );
};

export default SurveySuccess;
