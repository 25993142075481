import { FILL_SURVEY } from "./survey-fill.reducer";
import { getUniqueListBy } from "../../utils/data-utils";

function selectFillSurvey(state) {
    return state[FILL_SURVEY] || null;
}

function selectFillSurveyId(state) {
    return selectFillSurvey(state)?.sessionId || null;
}

function selectFillSurveyToken(state) {
    return selectFillSurvey(state)?.surveySessionToken || "";
}

function selectFillSurveyPageCount(state) {
    return selectFillSurvey(state)?.pageCount || null;
}

function selectFillSurveyUnansweredPages(state) {
    return selectFillSurvey(state)?.unansweredPages || null;
}

function selectFillSurveyPages(state) {
    return selectFillSurvey(state)?.pages || [];
}

function selectFillSurveyUnansweredPagesCollection(state) {
    let unansweredPages = [];
    if (selectFillSurvey(state) && Array.isArray(selectFillSurveyUnansweredPages(state))) {
        unansweredPages = [...selectFillSurvey(state).unansweredPages];
    }

    return unansweredPages.sort((a, b) => a - b);
}

function selectFillSurveyPageIndex(state) {
    return selectFillSurvey(state)?.pageIndex || null;
}

function selectFillSurveyQuestionsByPageIndex(state, index) {
    if (index === undefined) {
        index = selectFillSurveyPageIndex(state);
    }
    return selectFillSurvey(state)?.questions[index] || null;
}

function selectFillSurveyCategories(state) {
    const categories = selectFillSurveyPages(state).map((page) => page.category);

    return getUniqueListBy(categories, "id");
}

function selectFillSurveyCurrentPage(state) {
    const index = selectFillSurveyPageIndex(state);

    return selectFillSurveyPages(state).find((page) => page.index === index);
}

function selectFillSurveyShowPopup(state) {
    const showPopupFlag = selectFillSurvey(state)?.showPopup;

    if (typeof showPopupFlag !== "boolean") {
        return false;
    }

    return showPopupFlag;
}

export {
    selectFillSurvey,
    selectFillSurveyId,
    selectFillSurveyToken,
    selectFillSurveyPages,
    selectFillSurveyPageIndex,
    selectFillSurveyPageCount,
    selectFillSurveyCategories,
    selectFillSurveyCurrentPage,
    selectFillSurveyQuestionsByPageIndex,
    selectFillSurveyUnansweredPages,
    selectFillSurveyUnansweredPagesCollection,
    selectFillSurveyShowPopup,
};
