import { logo, responsive, black, white } from "../components/atoms/logo.module.scss";

import { ILogoProps } from '../components/atoms/logo';

const getColorClassName = (color: ILogoProps['color']) => {
    switch (color) {
        case 'white':
            return white;
            break;
        case 'black':
        default:
            return black;
    }
}

export default function generateLogoClassName({ type, color, className }: ILogoProps) {
    const colorClassName = getColorClassName(color);

    return `${logo} ${type === 'responsive' ? responsive : ''} ${colorClassName} ${className}`;
}