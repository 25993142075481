import React from "react";

import { headerItem } from "./survey-session-list-header.module.scss";

import SurveySessionColumnItem from "../atoms/survey-session-column-item";

export default function SurveySessionListHeader() {
    return (
        <>
            <SurveySessionColumnItem columnName="email" className={headerItem}>
                MAIL
            </SurveySessionColumnItem>

            <SurveySessionColumnItem columnName="occasion" className={headerItem}>
                STYLIZACJA
            </SurveySessionColumnItem>

            <SurveySessionColumnItem columnName="created-date" className={headerItem}>
                DATA REJESTRACJI
            </SurveySessionColumnItem>

            <SurveySessionColumnItem columnName="status" className={headerItem}>
                STATUS
            </SurveySessionColumnItem>
        </>
    );
}
