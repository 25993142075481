import React, { useEffect } from "react";
import { useParams } from "@reach/router";
import { useSelector } from "react-redux";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { layout, content } from "./mail-confirmation.module.scss";
import useUser from "../hooks/use-user";
import { selectEntityStatus } from "../../../redux/ui/ui.selectors";
import { USER } from "../../../redux/user/user.reducer";
import { config } from "../../../config";

import AuthLayout, { IAuthProps } from "../../../layouts/auth-layout";

const { success, fail } = config.apiStatusMap;

const MailConfirmation = () => {
    const { t } = useI18next();
    const { token } = useParams();
    const { confirm } = useUser();
    const status = useSelector((state) => selectEntityStatus(state, USER));

    const sharedProps: Omit<IAuthProps, "title"> = {
        titleAlign: "center",
        titleSize: "medium",
        className: layout,
        contentClassName: content,
    };

    useEffect(() => {
        if (token && typeof token === "string" && token !== "") {
            confirm(token);
        }
    }, [token, confirm]);

    if (!token) {
        return (
            <AuthLayout title={t("auth.confirmation.noToken.title")} {...sharedProps}>
                <span>{t("auth.confirmation.noToken.text")}</span>
            </AuthLayout>
        );
    }
    if (status === fail) {
        return <AuthLayout title={t("auth.confirmation.fail.title")} {...sharedProps} />;
    }
    if (status === success) {
        return <AuthLayout title={t("auth.confirmation.success.title")} {...sharedProps} />;
    }
    return <AuthLayout title={t("auth.confirmation.loading.title")} {...sharedProps} />;
};

export default MailConfirmation;
