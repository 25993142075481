import { action } from "../action-creator";
import { GENERATOR_FILTERS } from "./generator-filters.reducer";
import { TGeneratorFilters } from "../../models/generator.model";

export const types = {
    GET: "get",
    SET: "set",
    CLEAR: "clear",
    RESTORE_INITIAL: "restore initial",
};

export const getGeneratorFiltersAction = () => action(GENERATOR_FILTERS, types.GET);
export const setGeneratorFiltersAction = (payload: TGeneratorFilters) =>
    action(GENERATOR_FILTERS, types.SET, payload);
export const clearGeneratorFiltersAction = () => action(GENERATOR_FILTERS, types.CLEAR);
export const restoreGeneratorFiltersInitialStateAction = () =>
    action(GENERATOR_FILTERS, types.RESTORE_INITIAL);
