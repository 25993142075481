module.exports = {
    docs: [
        {
            extension: '.pdf',
            mimeType: 'application/pdf',
        },
        {
            extension: '.doc',
            mimeType: 'application/msword',
        },
        {
            extension: '.docx',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingm',
        },
    ]
}