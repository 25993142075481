import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import { authForm, input, button } from "./auth-forms.module.scss";
import { config } from "../../../config";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import useUser from "../hooks/use-user";

import FormikForm from "../../../components/hoc/formik-form";
import Input from "../../../components/atoms/form/input";
import Button from "../../../components/atoms/button";
import { IUserPassResetRequest } from "../models/payloads.model";

export interface IAvatarProps {
    className?: string;
}

const { required, email } = config.messages.form;

const initialValues: IUserPassResetRequest = {
    email: "",
};

const lostPass = Yup.object({
    email: Yup.string().email(email).required(required),
});

const PassChangeRequestForm: React.FC<IAvatarProps> = ({ className = "" }) => {
    const { t } = useI18next();
    const { passResetRequest } = useUser();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={lostPass}
            onSubmit={passResetRequest}
        >
            {(formik) => (
                <FormikForm
                    formik={formik}
                    successMessage={""}
                    className={`${authForm} ${className}`}
                >
                    <Input
                        name="email"
                        type="email"
                        placeholder={t("form.label.email")}
                        className={input}
                        autocomplete="username"
                    />

                    <Button color="primary" type="filled" htmlType="submit" className={button}>
                        {t("button.lostPass")}
                    </Button>
                </FormikForm>
            )}
        </Formik>
    );
};

export default PassChangeRequestForm;
