// extracted by mini-css-extract-plugin
export var actions = "stylization-card-module--actions--uP8tD";
export var buttonDelete = "stylization-card-module--button-delete--Y+j7I";
export var buttonEdit = "stylization-card-module--button-edit--MC5CH";
export var container = "stylization-card-module--container--9QOG0";
export var content = "stylization-card-module--content--KlU5U";
export var date = "stylization-card-module--date--NonLi";
export var iconDelete = "stylization-card-module--icon-delete--irZF3";
export var image = "stylization-card-module--image--PYx-7";
export var imageBox = "stylization-card-module--image-box--Ycefr";
export var imageRatio = "stylization-card-module--image-ratio--d2+9b";
export var images = "stylization-card-module--images--r0LLU";
export var link = "stylization-card-module--link--XTj26";
export var nameBox = "stylization-card-module--name-box--9HcLx";
export var nameText = "stylization-card-module--name-text--5xhbG";
export var occasionText = "stylization-card-module--occasion-text--UjAdW";