// extracted by mini-css-extract-plugin
export var arrowSvg = "select-module--arrow-svg--e4Zne";
export var check = "select-module--check--PI-S7";
export var chosenOption = "select-module--chosen-option--TswsY";
export var closeLayer = "select-module--close-layer--C382A";
export var compact = "select-module--compact--lfcWg";
export var container = "select-module--container--v5Zui";
export var containerMulti = "select-module--container-multi--7drvA";
export var counter = "select-module--counter--OFR3L";
export var exit = "select-module--exit--v5kxo";
export var iconMulti = "select-module--icon-multi--SNS4n";
export var imgMulti = "select-module--img-multi--Skn2V";
export var labelText = "select-module--label-text--NCd2x";
export var light = "select-module--light--VXEd-";
export var open = "select-module--open--NvRXt";
export var select = "select-module--select--cSiKP";
export var selectArrow = "select-module--select-arrow--bw-g6";
export var selectButton = "select-module--select-button--IzwAR";
export var selectButtonContent = "select-module--select-button-content--UCPfB";
export var selectButtonText = "select-module--select-button-text--eFA2Y";
export var selectInput = "select-module--select-input--lgZce";
export var selectOption = "select-module--select-option--Mjgox";
export var selectOptionList = "select-module--select-option-list--Lah4y";
export var selected = "select-module--selected--wN91h";
export var showCheck = "select-module--show-check--q4EI0";
export var showExit = "select-module--show-exit--YO2H2";