import { action } from "../action-creator";
import { FILL_SURVEY } from "./survey-fill.reducer";

const actions = {
    //document actions:
    SET_SESSION: `set_session`,
    SET_CURRENT_PAGE: `set_current_page`,
    SET_QUESTIONS: `set_questions`,
    CLEAR: `clear_data`,
    CLEAR_QUESTIONS: `clear_questions`,

    //event actions:
    INIT: "init",

    //command actions:
    NEXT_PAGE: "next_page",
    PREV_PAGE: "prev_page",
    CHECK_PAGE: "check_page",
    UPDATE_UNANSWERED_PAGES: "update_unanswered_pages",
    SHOW_REGISTER_POPUP: "show_register_popup",

    // api actions
    CREATE_NEW: `create_new`,
    CHECK: `check`,
    GET_PAGE: `get_page`,
    SEND_ANSWERS: `send_answers`,
};

const setFillSurvey = (payload, meta) => action(FILL_SURVEY, actions.SET_SESSION, payload, meta);

const setFillSurveyPage = (payload, meta) =>
    action(FILL_SURVEY, actions.SET_CURRENT_PAGE, payload, meta);

const setFillSurveyQuestions = (payload, meta) =>
    action(FILL_SURVEY, actions.SET_QUESTIONS, payload, meta);

const setFillSurveyUnansweredPages = (payload, meta) =>
    action(FILL_SURVEY, actions.UPDATE_UNANSWERED_PAGES, payload, meta);

const setFillSurveyShowPopup = (payload, meta) =>
    action(FILL_SURVEY, actions.SHOW_REGISTER_POPUP, payload, meta);

const clearFillSurvey = () => action(FILL_SURVEY, actions.CLEAR);

const clearFillSurveyQuestions = () => action(FILL_SURVEY, actions.CLEAR_QUESTIONS);

const initFillSurvey = () => action(FILL_SURVEY, actions.INIT);

const createFillSurvey = (payload, meta) => action(FILL_SURVEY, actions.CREATE_NEW, payload, meta);
const getFillSurveyPage = (payload, meta) => action(FILL_SURVEY, actions.GET_PAGE, payload, meta);
const sendFillSurveyAnswers = (payload, meta) =>
    action(FILL_SURVEY, actions.SEND_ANSWERS, payload, meta);
const checkFillSurvey = () => action(FILL_SURVEY, actions.CHECK);

const checkPage = (payload, meta) => action(FILL_SURVEY, actions.CHECK_PAGE, payload, meta);
const nextPage = (payload) => action(FILL_SURVEY, actions.NEXT_PAGE, payload);
const prevPage = () => action(FILL_SURVEY, actions.PREV_PAGE);

export {
    actions,
    setFillSurvey,
    setFillSurveyPage,
    setFillSurveyQuestions,
    setFillSurveyUnansweredPages,
    setFillSurveyShowPopup,
    clearFillSurvey,
    clearFillSurveyQuestions,
    initFillSurvey,
    createFillSurvey,
    getFillSurveyPage,
    sendFillSurveyAnswers,
    checkFillSurvey,
    checkPage,
    nextPage,
    prevPage,
};
