import { useCallback, useEffect, useState } from "react";

export default function useMediaQuery<MediaQueryType extends number>(
    inputMediaQuery: MediaQueryType
): boolean;
export default function useMediaQuery<MediaQueryType extends string>(
    inputMediaQuery: MediaQueryType[]
): MediaQueryType;
export default function useMediaQuery<MediaQueryType extends number | string>(
    inputMediaQuery: MediaQueryType | MediaQueryType[]
): boolean | MediaQueryType {
    const [mediaQueries] = useState(
        Array.isArray(inputMediaQuery) ? inputMediaQuery : [inputMediaQuery]
    );
    const [mediaQueriesMet, setIsMediaQueriesMet] = useState<
        Partial<Record<MediaQueryType, boolean>>
    >({});

    const handleResize = useCallback(() => {
        mediaQueries.forEach((mediaQuery) => {
            setIsMediaQueriesMet((state) => ({
                ...state,
                [mediaQuery]: window.innerWidth < mediaQuery,
            }));
        });
    }, [mediaQueries]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    if (!Array.isArray(inputMediaQuery)) {
        return !!mediaQueriesMet[inputMediaQuery];
    }

    const mediaQueryMet = Object.entries(mediaQueriesMet).find(([key, value]) => value);

    if (mediaQueryMet) {
        return mediaQueryMet[0] as MediaQueryType;
    }

    return false;
}
