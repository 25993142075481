import React from "react";

import { newsletter, text, title } from "./newsletter.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import Heart from "../../../assets/images/svg/heart.svg";

import Section from "../../../components/molecules/section";
import NewsletterForm from "../../auth/forms/newsletter-form";
import AccountTitle from "../atoms/account-title";

export default function Newsletter() {
    return (
        <div className={mainGrid}>
            <Section column="narrow" className={newsletter}>
                <AccountTitle className={title}>Newsletter</AccountTitle>
                <Heart />
                <div className={text}>
                    Zapisz się do newslettera Fashion Fairy. Bądź na bieżąco z nami!
                </div>
                <NewsletterForm />
            </Section>
        </div>
    );
}
