import imageCompression from "browser-image-compression";

export enum FileErrorEnum {
    OnLoad = "on-load",
    TooMuchFiles = "too-much-files",
    FileNotAllowed = "file-not-allowed",
    TooBig = "too-big",
}

export const DEFAULT_ERROR_MAP: Record<FileErrorEnum, string> = {
    [FileErrorEnum.OnLoad]: "Wystąpił błąd podczas ładowania pliku %name%",
    [FileErrorEnum.TooMuchFiles]:
        "Plik %name% nie został dodany, dodano już maksymalną ilość plików.",
    [FileErrorEnum.FileNotAllowed]: "Plik %name% nie jest dozwolony.",
    [FileErrorEnum.TooBig]: "Plik %name% jest za duży. Maksymalna wielkość to %size% MB",
};

export interface IBase64File {
    name: string;
    content: string | ArrayBuffer | null;
    mimeType: string;
}

export const getBase64 = (
    file: File,
    errorMap = DEFAULT_ERROR_MAP,
    compressOptions?: Record<string, any>
): Promise<IBase64File> => {
    return new Promise((resolve, reject) => {
        if (file.type.includes("image") && compressOptions?.maxWidthOrHeight) {
            imageCompression(file, compressOptions)
                .then((readFile) => imageCompression.getDataUrlFromFile(readFile))
                .then((result) =>
                    resolve({
                        name: file.name,
                        content: result,
                        mimeType: file.type,
                    })
                )
                .catch(() =>
                    reject(processError(FileErrorEnum.OnLoad, { name: file.name }, errorMap))
                );
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                resolve({
                    name: file.name,
                    content: reader.result,
                    mimeType: file.type,
                });
            reader.onerror = () =>
                reject(processError(FileErrorEnum.OnLoad, { name: file.name }, errorMap));
        }
    });
};

export interface IValidateFileOptions {
    maxFileCount?: number;
    maxFileSize?: number;
    fileTypes?: string[];
    errorMessages?: Record<FileErrorEnum, string>;
}

export const validateFiles = (files: File[], options: IValidateFileOptions) => {
    const {
        maxFileCount = 1,
        maxFileSize = 5120000,
        fileTypes = [],
        errorMessages = DEFAULT_ERROR_MAP,
    } = options;

    const validFiles: File[] = [];
    const validationErrors: string[] = [];
    let remainingCount = maxFileCount;

    files.forEach((file) => {
        if (remainingCount <= 0) {
            validationErrors.push(
                processError(FileErrorEnum.TooMuchFiles, { name: file.name }, errorMessages)
            );
        } else if (fileTypes.length && !fileTypes.includes(file.type)) {
            validationErrors.push(
                processError(FileErrorEnum.FileNotAllowed, { name: file.name }, errorMessages)
            );
        } else if (file.size > maxFileSize) {
            validationErrors.push(
                processError(
                    FileErrorEnum.TooBig,
                    {
                        name: file.name,
                        size: maxFileSize.toString(),
                    },
                    errorMessages
                )
            );
        } else {
            validFiles.push(file);
            remainingCount--;
        }
    });

    return {
        validFiles,
        validationErrors,
    };
};

type ErrorMessagePlaceholders = "name" | "size";

export const processError = (
    error: FileErrorEnum,
    placeholders?: Partial<Record<ErrorMessagePlaceholders, string>>,
    errorMap = DEFAULT_ERROR_MAP
) => {
    let errorMessage = errorMap[error];

    if (placeholders) {
        Object.entries(placeholders).forEach(
            ([placeholder, value]) =>
                (errorMessage = errorMessage.replaceAll(`%${placeholder}%`, value))
        );
    }

    return errorMessage;
};
