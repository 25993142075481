import React, { useEffect } from "react";

import { config } from "../../../config";
import useSurveySession from "../../../apps/hooks/use-survey-session";
import useCheckPermissions from "../../../apps/hooks/use-check-permissions";
import SurveySessionState from "../../../enums/survey-session-state.enum";

import SurveySessionHeader from "../../../apps/account/molecules/survey-session-header";
import SurveySessionQuestionList from "../../../apps/account/molecules/survey-session-question-list";
import Loader from "../../atoms/loader";

interface ISurveySessionProps {
    className?: string;
    sessionId: number | string | undefined;
}

const { User, Admin } = config.rbac.permissionMap;

const SurveySession: React.FC<ISurveySessionProps> = ({ className = "", sessionId }) => {
    const {
        isLoading,
        categories,
        questions,
        surveyStatus,
        surveyUser,
        getSingleSession,
        firstSessionId,
        changeStatus,
    } = useSurveySession({ sessionId });

    const isUser = useCheckPermissions(User.role);
    const isAdmin = useCheckPermissions(Admin.role);

    useEffect(() => {
        getSingleSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, firstSessionId]);

    return (
        <div className={className}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <SurveySessionHeader
                        status={surveyStatus}
                        user={surveyUser}
                        sessionId={sessionId}
                        changeStatus={changeStatus}
                    />
                    <SurveySessionQuestionList
                        questions={questions}
                        categories={categories}
                        canEdit={canEdit(isUser, isAdmin, surveyStatus)}
                    />
                </>
            )}
        </div>
    );
};

function canEdit(isUser: boolean, isAdmin: boolean, status: number): boolean {
    switch (true) {
        case isAdmin:
            return true;
        case isUser && status >= SurveySessionState.Processing:
            return false;
        default:
            return true;
    }
}

export default SurveySession;
