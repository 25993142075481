import React from "react";

import { title } from "./survey-sessions.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";

import Section from "../../../components/molecules/section";
import AdmTitle from "../atoms/adm-title";
import SurveySessionList from "../organisms/survey-session-list";

const SurveySessions: React.FC = () => {
    return (
        <div className={mainGrid}>
            <Section>
                <AdmTitle className={title}>Rozwiązane ankiety</AdmTitle>
            </Section>
            <Section>
                <SurveySessionList />
            </Section>
        </div>
    );
};

export default SurveySessions;
