import { actions as apiActions, sendApiRequest } from "../api/api.actions";
import { getSurveyList, setSurvey } from "./survey.actions";
import { config } from "../../config";
import { setAlert } from "../alerts/alerts.actions";

const { entity } = config.endpoints.surveys;

const surveyMiddleware = (state) => (next) => (action) => {
    next(action);

    const { dispatch } = state;
    const { type, payload, meta = {} } = action;

    switch (type) {
        case getSurveyList().type:
            dispatch(sendApiRequest({ url: entity }, meta));
            break;

        case `${getSurveyList().type} ${apiActions.API_SUCCESS}`:
            dispatch(setSurvey(payload.items));
            break;

        case `${getSurveyList().type} ${apiActions.API_FAIL}`:
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się pobrać listy ankiet",
                    },
                    meta
                )
            );
            break;

        // no default
    }
};

export default surveyMiddleware;
