import React from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { content, title } from "./survey-session-view.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";

import Section from "../../../components/molecules/section";
import SurveySession from "../../../components/organisms/survey/survey-session";
import AccountTitle from "../atoms/account-title";

interface ISurveySessionView {
    surveySessionId?: number | string;
}

export default function SurveySessionView({ surveySessionId }: ISurveySessionView) {
    const { t } = useI18next();
    return (
        <div className={`${mainGrid} ${content}`}>
            <Section>
                <AccountTitle className={title}>{t("account.survey.title")}</AccountTitle>
            </Section>
            <Section>
                <SurveySession sessionId={surveySessionId} />
            </Section>
        </div>
    );
}
