import { IRoute } from "../models/route.model";

import GeneratorRoute from "./hoc/generator-route";
import GeneratorLayout from "../../layouts/generator-layout";
import StylizationView from "./templates/stylization-view";
import Generator from "./templates/generator";

export const GENERATOR_ROUTES: IRoute[] = [
    {
        id: "GENERATOR",
        path: "generator",
        label: "",
        redirect: {
            from: "GENERATOR",
            to: "GENERATOR_LAYOUT",
        },
        Component: GeneratorRoute,
        routes: [
            {
                id: "GENERATOR_LAYOUT",
                path: "/",
                label: "",
                Component: GeneratorLayout,
                redirect: {
                    from: "GENERATOR_LAYOUT",
                    to: "GENERATOR_NEW_STYLIZATION",
                },
                routes: [
                    {
                        id: "GENERATOR_NEW_STYLIZATION",
                        path: "wyczaruj-stylizacje",
                        label: "",
                        Component: Generator,
                        routes: [],
                    },
                    {
                        id: "GENERATOR_STYLIZATION",
                        path: "stylizacja",
                        label: "",
                        Component: GeneratorRoute,
                        routes: [
                            {
                                id: "GENERATOR_STYLIZATION_VIEW",
                                path: ":stylizationId",
                                label: "",
                                routeProps: {
                                    isCongratulationSite: false,
                                },
                                Component: StylizationView,
                                routes: [],
                            },
                        ],
                    },
                    {
                        id: "GENERATOR_STYLIZATION",
                        path: "stylizacja-podglad",
                        label: "",
                        Component: GeneratorRoute,
                        routes: [
                            {
                                id: "GENERATOR_STYLIZATION_VIEW",
                                path: ":stylizationId/:guid",
                                label: "",
                                routeProps: {
                                    isCongratulationSite: false,
                                },
                                Component: StylizationView,
                                routes: [],
                            },
                        ],
                    },
                    {
                        id: "GENERATOR_STYLIZATION_CONGRATULATIONS",
                        path: "stylizacja/gratulacje/:stylizationId",
                        label: "",
                        routeProps: {
                            isCongratulationSite: true,
                        },
                        Component: StylizationView,
                        routes: [],
                    },
                ],
            },
        ],
    },
];
