import React, { useEffect, useState } from "react";
import { FieldHookConfig, FieldInputProps, useField } from "formik";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    container,
    dateInputWrapper,
    dateInput,
    inputIcon,
    calendarWrapper,
} from "./survey-input-date.module.scss";
import CalendarIcon from "../../../assets/images/svg/icon-calendar.svg";
import { ISurveyQuestion } from "../../../models/survey/survey-question.model";
import { getFormattedDate } from "../../../utils/date-utils";

import SurveyHint from "./survey-hint";
import DatePicker, { TDateLevel } from "../../organisms/date-picker";

export interface ISurveyInputDateProps {
    question: ISurveyQuestion;
    className?: string;
}

interface IDateValue {
    year?: number;
    month?: number;
    day?: number;
}

const SurveyInputDate = ({ question, className = "" }: ISurveyInputDateProps) => {
    const { inputType, hint, questionId } = question;
    const optionConfig: FieldHookConfig<any> = {
        name: questionId.toString(),
        type: inputType,
    };
    const { language } = useI18next();
    const [field, , helpers] = useField(optionConfig);
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<IDateValue>({});
    const [dateDetailLevel, setDateDetailLevel] = useState<TDateLevel>("decade");

    const dateFromDateValue = getDateFromDateValue(value);

    const handleClick = () => {
        if (isOpen) {
            setDateDetailLevel("decade");
        }
        setIsOpen(!isOpen);
    };

    const handleDateClick = (date: Date, dateLevel: "year" | "month" | "day") => {
        let levelValue = 0;
        if (dateLevel === "year") {
            levelValue = date.getFullYear();
        }
        if (dateLevel === "month") {
            levelValue = date.getMonth();
        }
        if (dateLevel === "day") {
            levelValue = date.getDate();
        }

        // console.log('handleDateClick - dateLevel: ', dateLevel);
        // console.log('handleDateClick - levelValue: ', levelValue);

        setDateDetailLevel(dateLevel !== "day" ? dateLevel : "decade");
        setValue((prevValue) => {
            return {
                ...prevValue,
                [dateLevel]: levelValue,
            };
        });

        // console.log('handleDateClick - month: ', value.month);
        if (dateLevel === "day" && value.year && value.month!=null) {
            const finalDate = new Date(value.year, value.month, levelValue);
            const formFormattedDate = getFormattedDate(finalDate);
            helpers.setValue(formFormattedDate);
            setIsOpen(false);
        }
    };

    useEffect(() => {
        setValue(getDateValueFromField(field));
    }, [field.value]);

    return (
        <div className={`${container} ${className}`}>
            <label className={dateInputWrapper}>
                <input
                    {...field}
                    value={getFormattedDateValue(value)}
                    className={dateInput}
                    type="text"
                    onClick={handleClick}
                    readOnly={true}
                />
                <div className={inputIcon}>
                    <CalendarIcon />
                </div>
                {hint && <SurveyHint hint={hint} />}
            </label>
            <DatePicker
                locale={language}
                isOpen={isOpen}
                wrapperClassName={calendarWrapper}
                maxDate={new Date()}
                activeStartDate={dateDetailLevel !== "decade" ? dateFromDateValue : undefined}
                defaultActiveStartDate={dateFromDateValue}
                minDetail={dateDetailLevel}
                maxDetail={dateDetailLevel}
                nextLabel={dateDetailLevel !== "decade" ? null : undefined}
                next2Label={null}
                prevLabel={dateDetailLevel !== "decade" ? null : undefined}
                prev2Label={null}
                value={dateFromDateValue}
                showNeighboringMonth={false}
                onClickYear={(date) => handleDateClick(date, "year")}
                onClickMonth={(date) => handleDateClick(date, "month")}
                onClickDay={(date) => handleDateClick(date, "day")}
            />
        </div>
    );
};

function getDateValueFromField(field: FieldInputProps<any>): IDateValue {
    const { value } = field;
    let initialDateValue: IDateValue = {};
    let initialDate: Date | undefined;
    // unix timestamp from backend
    if (typeof value === "number") {
        initialDate = new Date(value * 1000);
    }
    // string YYYY-MM-DD from localStorage
    if (value && typeof value === "string") {
        initialDate = new Date(value);
    }
    if (initialDate) {
        initialDateValue = {
            year: initialDate.getFullYear(),
            month: initialDate.getMonth(),
            day: initialDate.getDate(),
        };
    }
    return initialDateValue;
}

function getDateFromDateValue(value: IDateValue): Date {
    let { year, month, day } = value;
    if (!year) {
        year = new Date().getFullYear() - 18;
    }
    if (!month && month !== 0) {
        month = 0;
    }
    if (!day) {
        day = 1;
    }
    return new Date(year, month, day);
}

function getFormattedDateValue(value: IDateValue): string {
    const { year, month, day } = value;
    let yearString = "Rok";
    let monthString = "Miesiąc";
    let dayString = "Dzień";
    if (year) {
        yearString = year.toString();
    }
    if (month || month === 0) {
        monthString = getFormattedDayOrMonth(month + 1);
    }
    if (day) {
        dayString = getFormattedDayOrMonth(day);
    }
    return `${dayString} / ${monthString} / ${yearString}`;
}

function getFormattedDayOrMonth(value: number): string {
    let newValue = value.toString();
    if (newValue.length === 1) {
        newValue = `0${newValue}`;
    }
    return newValue;
}

export default SurveyInputDate;
