import { useSelector } from "react-redux";

import { IMenuLinksElement } from "../models/menu-links-element.model";
import { selectRbac } from "../redux/rbac/rbac.selectors";
import { recursiveIncludesRights } from "../apps/hooks/use-check-permissions";

export default function useMenuWithPermissions(menuItems: IMenuLinksElement[]) {
    const permissions = useSelector(selectRbac);

    return menuItems.filter((menuItem) => {
        let menuItemPermissions: string[] = [];

        if (menuItem.permissions) {
            if (Array.isArray(menuItem.permissions)) {
                menuItemPermissions = menuItem.permissions;
            } else {
                menuItemPermissions = [menuItem.permissions];
            }
        }

        return recursiveIncludesRights(permissions, menuItemPermissions);
    });
}
