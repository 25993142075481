import { action } from "../action-creator";
import { LIST, SURVEY_SESSION } from "./survey-session.reducer";
import { actions as listActions } from "../generic-list/generic-list.actions";
import { config } from "../../config";
import actionName from "../action-creator";

const { command } = listActions;
const { entity, sessions } = config.endpoints.surveys;

export const actions = {
    //commands
    UPDATE_ANSWER: "update_answer",
    UPDATE_STATUS: "update_status",
    GET_SINGLE_SURVEY_SESSION_QUESTIONS: "get_single_survey_session_questions",

    //document
    SET_QUESTION_LIST: "set_question_list",
    SET_QUESTION_SINGLE: "set_question_single",
};

export const getSurveySessions = (payload, meta) => {
    return {
        type: actionName(`${SURVEY_SESSION} ${LIST}`, command.get),
        payload: {
            url: `${entity}${sessions}`,
            ...payload,
        },
        meta,
    };
};

export const searchSurveySessions = (payload, meta) => {
    return {
        type: actionName(`${SURVEY_SESSION} ${LIST}`, command.search),
        payload,
        meta,
    };
};

export const sortSurveySessions = (payload, meta) => {
    return {
        type: actionName(`${SURVEY_SESSION} ${LIST}`, command.sort),
        payload,
        meta,
    };
};

export const filterSurveySessions = (payload, meta) => {
    return {
        type: actionName(`${SURVEY_SESSION} ${LIST}`, command.filter),
        payload,
        meta,
    };
};

export const getSingleSurveySessionQuestions = (payload, meta) =>
    action(SURVEY_SESSION, actions.GET_SINGLE_SURVEY_SESSION_QUESTIONS, payload, meta);

export const updateAnswer = (payload, meta) =>
    action(SURVEY_SESSION, actions.UPDATE_ANSWER, payload, meta);

export const updateStatus = (payload, meta) =>
    action(SURVEY_SESSION, actions.UPDATE_STATUS, payload, meta);

export const setSurveySessionQuestionList = (payload, meta) =>
    action(SURVEY_SESSION, actions.SET_QUESTION_LIST, payload, meta);

export const setSurveySessionQuestionSingle = (payload, meta) =>
    action(SURVEY_SESSION, actions.SET_QUESTION_SINGLE, payload, meta);
