import React from 'react';
import * as CSS from 'csstype';

import { mainGrid } from '../../../styles/grid.module.scss';
import { wrapper } from './section-wrapper.module.scss';

export interface ISectionProps {
    className?: string;
    bgColor?:
        | 'white'
        | 'grey-light'
        | 'violet-dark'
        | 'beige-light'
        | 'blue-light'
        | 'pink'
        | 'blue'
        | 'anthracite';
    children?: any;
    style?: CSS.Properties
}

const SectionWrapper = React.forwardRef<HTMLDivElement, ISectionProps>(
    (props, ref) => {
        const {
            className = '',
            children,
            bgColor = 'white',
            style
        } = props;

        const cssClass = `${mainGrid} ${wrapper} ${className}`;

        const bgStyle: CSS.Properties = {
            ['--bg-color' as any]: `var(--color-${bgColor})`,
        };

        return (
            <div className={cssClass} style={{ ...bgStyle, ...style }} ref={ref}>
                {children}
            </div>
        );
    }
);

SectionWrapper.displayName = 'SectionWrapper';

export default SectionWrapper;
