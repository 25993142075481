import React from 'react';

interface II18NextContext {
    language: string,
    languages: string[],
    routed: boolean,
    defaultLanguage: string,
    generateDefaultLanguagePage: boolean,
    originalPath: string,
    path: string,
    pagePaths: {
        [key: string]: string
    },
    pageKey: string,
}

export const I18nextContext = React.createContext<II18NextContext>({
    language: 'en',
    languages: ['en'],
    routed: false,
    defaultLanguage: 'en',
    generateDefaultLanguagePage: false,
    originalPath: '/',
    path: '/',
    pagePaths: {},
    pageKey: '/',
});