import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import FormikForm from "../../../components/hoc/formik-form";

import { button, authForm, input } from "./auth-forms.module.scss";
import { config } from "../../../config";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import useUser from "../hooks/use-user";

import Input from "../../../components/atoms/form/input";
import Button from "../../../components/atoms/button";
import { IUserPassReset } from "../models/payloads.model";

export interface IAvatarProps {
    className?: string;
    token: string;
}

const { min, repeat } = config.messages.password;
const { required } = config.messages.form;

const initialValues: IUserPassReset = {
    token: "",
    password: "",
    passwordRepeat: "",
};

const lostPass = Yup.object({
    token: Yup.string().required(required),
    password: Yup.string().required(required).min(8, min),
    passwordRepeat: Yup.string()
        .required(required)
        .oneOf([Yup.ref("password")], repeat),
});

const PassResetForm: React.FC<IAvatarProps> = ({ className = "", token }) => {
    const { t } = useI18next();
    const { passReset } = useUser();

    return (
        <Formik
            initialValues={{ ...initialValues, token }}
            validationSchema={lostPass}
            onSubmit={passReset}
        >
            {(formik) => {
                return (
                    <FormikForm
                        formik={formik}
                        successMessage={""}
                        className={`${authForm} ${className}`}
                    >
                        <Input
                            name="password"
                            type="password"
                            placeholder={t("form.label.newPassword")}
                            className={input}
                            autoComplete="new-password"
                        />

                        <Input
                            name="passwordRepeat"
                            type="password"
                            placeholder={t("form.label.repeatPassword")}
                            className={input}
                            autoComplete="new-password"
                        />

                        <Button color="primary" type="filled" htmlType="submit" className={button}>
                            {t("button.newPass")}
                        </Button>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

export default PassResetForm;
