import React from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { passResetText } from "./pass-request-reset.module.scss";

import AuthLayout from "../../../layouts/auth-layout";
import PassChangeRequestForm from "../forms/pass-request-reset-form";

const PassChangeRequest: React.FC = () => {
    const { t } = useI18next();
    return (
        <AuthLayout title={t("auth.lostPassword.title")}>
            <div className={passResetText}>
                <span>Zapomniałeś hasła?</span>
                <p>
                    Wpisz swój adres e-mail. <br />W mailu dostaniesz link do stworzenia nowego
                    hasła.
                </p>
            </div>
            <PassChangeRequestForm />
        </AuthLayout>
    );
};

export default PassChangeRequest;
