import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import * as Yup from "yup";
import { Formik } from "formik";

import FormikForm from "../../../components/hoc/formik-form";
import useUser from "../../auth/hooks/use-user";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { authForm, button, input, newsletterForm } from "../../auth/forms/auth-forms.module.scss";

import CheckedNewsletter from "../../../components/atoms/form/checked-newsletter";
import Button from "../../../components/atoms/button";

const newsletterSchema = Yup.object({
    subscribeNewsletter: Yup.boolean(),
});

const NewsletterForm = () => {
    const { t } = useI18next();
    const { updateNewsletter, data } = useUser();

    return (
        <Formik
            initialValues={{ subscribeNewsletter: data?.isNewsletterSubscriber }}
            validationSchema={newsletterSchema}
            onSubmit={updateNewsletter}
            enableReinitialize
        >
            {(formik) => {
                return (
                    <FormikForm
                        formik={formik}
                        successMessage={""}
                        className={`${authForm} ${newsletterForm}`}
                    >
                        <CheckedNewsletter className={input} name="subscribeNewsletter">
                            <ReactMarkdown
                                // @ts-ignore
                                rehypePlugins={[rehypeRaw]}
                            >
                                Chcę dostawać newsletter!
                            </ReactMarkdown>
                        </CheckedNewsletter>

                        <Button color="primary" type="filled" htmlType="submit" className={button}>
                            {t("button.newsletter")}
                        </Button>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

export default NewsletterForm;
