import React from "react";

import { surveySessionColumnItem } from "./survey-session-column-item.module.scss";

type SurveySessionListColumnName = "email" | "occasion" | "created-date" | "status";

export type SurveySessionColumnItemProps<ComponentType extends React.ElementType> = {
    Component?: ComponentType;
    columnName: SurveySessionListColumnName;
};

export default function SurveySessionColumnItem<ComponentType extends React.ElementType = "p">({
    Component = "p",
    columnName,
    ...props
}: SurveySessionColumnItemProps<ComponentType> & React.ComponentProps<ComponentType>) {
    const styles = {
        gridColumn: columnName,
    };

    return (
        <div style={styles} className={surveySessionColumnItem}>
            <Component {...props} />
        </div>
    );
}
