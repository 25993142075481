import React, { TransitionEventHandler, useCallback, useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import {
    panel,
    panelAdmin,
    panelNav,
    panelNav__btn,
    panelNav__btnActive,
    header,
    footer,
    closeButton,
    panel__contentWrapper,
    panelNav__separator,
    panelNav__avatarWrapper,
} from "./side-panel.module.scss";
import { config } from "../../config";
import { getAbsolutePath } from "../../apps/utility/routes.utility";
import useCheckPermissions from "../../apps/hooks/use-check-permissions";
import useMenuClosingButton from "../../hooks/use-menu-closing-button";
import useUser from "../../apps/auth/hooks/use-user";
import useSurveySession from "../../apps/hooks/use-survey-session";

import Button from "../atoms/button";
import MenuCloseButton from "../atoms/menu-close-button";
import Logo from "../atoms/logo";
import Link from "../atoms/link";
import UserAvatarWidget from "./user-avatar-widget";

const { accountLinks, admLinks, rbac } = config;

type MenuLinksElement = {
    labelKey: string;
    to: string;
    pathname?: string;
    param?: string | number;
    onClick?: () => void;
};

export interface ISidePanelProps {
    className?: string;
    onClose?: () => void;
}

function NavItem({ labelKey, to, pathname, param, onClick }: MenuLinksElement) {
    const path = getAbsolutePath(to, undefined, param);

    const handleClick = () => {
        if (typeof onClick === "function") {
            onClick();
        }
        if (path) {
            navigate(path);
        }
    };

    return (
        <Button
            className={`${panelNav__btn}`}
            key={`side-panel-${labelKey}`}
            onClick={handleClick}
            textUppercase
            color={"white"}
            activeClass={panelNav__btnActive}
            active={pathname === path}
        >
            {labelKey}
        </Button>
    );
}

export default function SidePanel({ className, onClose }: ISidePanelProps) {
    const location = useLocation();

    const isAdmin = useCheckPermissions(rbac.permissionMap.Admin.role);
    const isUser = useCheckPermissions(rbac.permissionMap.User.role);
    const { firstSessionId } = useSurveySession();

    const [navItems, setNavItems] = useState<MenuLinksElement[]>([]);

    const { animatedElementRef, closing, onAnimationEnd } = useMenuClosingButton(onClose);
    const { logOut } = useUser();

    const handleMenuCloseButtonClick = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleTransitionEnd: TransitionEventHandler = useCallback(
        (event) => {
            onAnimationEnd(event.target);
        },
        [onAnimationEnd]
    );

    useEffect(() => {
        if (isAdmin) {
            setNavItems(admLinks);
        } else if (isUser) {
            const links = accountLinks.map((link) => {
                if (link.to === "ACCOUNT_SURVEY_SESSION") {
                    return { ...link, param: firstSessionId };
                }
                return link;
            });
            setNavItems(links);
        }
    }, [isAdmin, isUser, firstSessionId]);

    return (
        <aside
            ref={animatedElementRef}
            className={`${panel} ${className} ${isAdmin ? panelAdmin : ""}`}
            onTransitionEnd={handleTransitionEnd}
        >
            <div className={panel__contentWrapper}>
                <div className={header}>
                    <MenuCloseButton
                        className={closeButton}
                        closing={closing}
                        onClick={() => handleMenuCloseButtonClick()}
                    />
                </div>
                <nav className={panelNav}>
                    <div className={panelNav__avatarWrapper}>
                        <UserAvatarWidget key={`user-avatar-3VA6UXHldj`} />
                    </div>
                    {navItems.map((item) => {
                        return (
                            <NavItem
                                key={item.to}
                                pathname={location.pathname}
                                to={item.to}
                                labelKey={item.labelKey}
                                param={item.param}
                                onClick={handleMenuCloseButtonClick}
                            />
                        );
                    })}
                    <hr className={panelNav__separator} />
                    <NavItem labelKey="Wyloguj się" to="" onClick={() => logOut()} />
                </nav>
            </div>
            <Link
                to="/"
                additionalProps={{ onClick: handleMenuCloseButtonClick }}
                className={footer}
            >
                <Logo color="white" type="logo" />
            </Link>
        </aside>
    );
}
