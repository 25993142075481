// extracted by mini-css-extract-plugin
export var beige = "menu-template-module--beige--M2uZU";
export var closing = "menu-template-module--closing--JTUM-";
export var content = "menu-template-module--content--utezC";
export var fadeIn = "menu-template-module--fade-in--ztQfF";
export var fadeOut = "menu-template-module--fade-out--dTpFx";
export var footer = "menu-template-module--footer--YZkup";
export var header = "menu-template-module--header--qkN6-";
export var menuMobile = "menu-template-module--menu-mobile--WVWRh";
export var slideFromBottom = "menu-template-module--slide-from-bottom--zbWl-";
export var slideFromLeft = "menu-template-module--slide-from-left--iC1u+";
export var slideFromRight = "menu-template-module--slide-from-right--nfMmd";
export var slideFromTop = "menu-template-module--slide-from-top--PXaUy";
export var slideToBottom = "menu-template-module--slide-to-bottom--G3JEj";
export var slideToLeft = "menu-template-module--slide-to-left--VSIpU";
export var slideToRight = "menu-template-module--slide-to-right--9fI7U";
export var slideToTop = "menu-template-module--slide-to-top--Nwz0b";
export var violet = "menu-template-module--violet--8vP2I";
export var zoomIn = "menu-template-module--zoom-in--FVvjc";
export var zoomOut = "menu-template-module--zoom-out--6OhB5";