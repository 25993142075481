import React from "react";
import { RouteComponentProps } from "@reach/router";

import {
    inactiveAccount,
    envelope,
    activateLink,
    confirmMail,
    confirmButton,
    title,
} from "./confirm-email.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import Envelope from "../../../assets/images/svg/envelope.svg";
import useUser from "../../auth/hooks/use-user";

import Section from "../../../components/molecules/section";
import AccountTitle from "../atoms/account-title";

export default function ConfirmEmail(props: RouteComponentProps) {
    const { resendActivationEmail } = useUser();

    return (
        <div className={mainGrid}>
            <Section column={"regular"} className={inactiveAccount}>
                <AccountTitle className={title}>Twoje konto jest jeszcze nieaktywne</AccountTitle>
                <Envelope className={envelope} />
                <div className={activateLink}>
                    Aby w pełni korzystać ze swojego konta, musisz{" "}
                    <strong>potwierdzić adres e-mail</strong> na swojej poczcie. Zaloguj się na
                    swoją skrzynkę pocztową i kliknij w link aktywujący.
                </div>
                <div className={confirmMail}>
                    Jeśli nie otrzymałaś e-maila z potwierdzeniem{" "}
                    <button className={confirmButton} onClick={resendActivationEmail}>
                        kliknij tutaj
                    </button>
                    .
                </div>
            </Section>
        </div>
    );
}
