enum SurveySessionState {
    Undefined = 0, //only on frontend!
    New = 1,
    InProgress = 2,
    Dropped = 3,
    Completed = 4,
    Processing = 100,
    Refused = 101,
    Finished = 102,
}

export const SurveySessionStateLabels: Record<SurveySessionState, string> = {
    [SurveySessionState.Undefined]: "Brak",
    [SurveySessionState.New]: "Nowa",
    [SurveySessionState.InProgress]: "W trakcie",
    [SurveySessionState.Dropped]: "Odrzucona",
    [SurveySessionState.Completed]: "Uzupełniona",
    [SurveySessionState.Processing]: "W realizacji",
    [SurveySessionState.Refused]: "Anulowana",
    [SurveySessionState.Finished]: "Gotowa",
};

export default SurveySessionState;
