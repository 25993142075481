import React from "react";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { button, icon, red, green, gold } from "./button-edit.module.scss";
import DrawIcon from "../../assets/images/svg/draw.svg";

type TColor = "grey" | "red" | "green" | "gold";

export interface IButtonEditProps {
    className?: string;
    onClick?(): void;
    hoverColor?: TColor;
}

const ButtonEdit: React.FC<IButtonEditProps> = ({
    className = "",
    onClick,
    hoverColor = "grey",
    children,
}) => {
    const { t } = useI18next();
    const colorClass = colorClasses[hoverColor];

    const handleClick = () => {
        if (typeof onClick !== "function") return;
        onClick();
    };

    return (
        <button className={`${button} ${className} ${colorClass}`} onClick={handleClick}>
            {children || t("button.edit")}
            <DrawIcon className={icon} />
        </button>
    );
};

const colorClasses: Record<TColor, string> = {
    grey: "",
    red: red,
    green: green,
    gold: gold,
};

export default ButtonEdit;
