import React from "react";
import { Provider } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import { compose, createStore } from "redux";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

import middlewareList from "./global-state.middlewares";
import windowAccessible from "../utils/check-window";
import rootReducer from "./global-state.reducers";
import { USER } from "./user/user.reducer";
import { RBAC } from "./rbac/rbac.reducer";
import { FILL_SURVEY } from "./survey-fill/survey-fill.reducer";

const persistConfig = {
    key: "root",
    whitelist: [USER, RBAC, FILL_SURVEY],
    storage,
};

let composeEnhancers = windowAccessible()
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const persistedReducer = windowAccessible()
    ? persistReducer(persistConfig, rootReducer)
    : rootReducer;

const store = createStore(persistedReducer, composeEnhancers(middlewareList));

const persistState = windowAccessible() ? persistStore(store) : undefined;

export const STORE_INIT = `STORE_INIT`;

store.dispatch({ type: STORE_INIT });

const ReduxStore = ({ children }) => {
    return (
        <Provider store={store}>
            {windowAccessible() ? (
                <PersistGate loading={null} persistor={persistState}>
                    {children}
                </PersistGate>
            ) : (
                children
            )}
        </Provider>
    );
};

export default ReduxStore;
