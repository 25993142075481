import { combineReducers } from "redux";

import { genericListReducer } from "../generic-list/generic-list.reducer";
import {
    setSurveySessionQuestionList,
    setSurveySessionQuestionSingle,
} from "./survey-session.actions";

export const SURVEY_SESSION = "[SURVEY_SESSION]";
export const LIST = "list";
export const QUESTIONS = "questions";

const QUESTIONS_INITIAL_STATE = {};

const surveySessionQuestionsReducer = (state = QUESTIONS_INITIAL_STATE, action) => {
    const { type, payload, meta } = action;

    switch (type) {
        case setSurveySessionQuestionList().type:
            return {
                ...state,
                [meta.sessionId]: payload,
            };
        case setSurveySessionQuestionSingle().type:
            return {
                ...state,
                [meta.sessionId]: {
                    ...state[meta.sessionId],
                    [meta.questionId]: payload,
                },
            };
        default:
            return state;
    }
};

const surveySessionReducer = combineReducers({
    [LIST]: genericListReducer(SURVEY_SESSION, LIST),
    [QUESTIONS]: surveySessionQuestionsReducer,
});

export default surveySessionReducer;
