import React, { useEffect, useRef } from "react";

import { dropdown, dropdownWithTriangle, open as openClass } from "./dropdown.module.scss";

export interface IDropdownProps {
    children?: React.ReactNode | React.ReactNode[];
    open?: boolean;
    onClose?: () => void;
    isTriangle?: boolean;
    className?: string;
}

const Dropdown = ({
    children,
    open = false,
    onClose,
    isTriangle = true,
    className = "",
}: IDropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleDropdownClose = (e: MouseEvent) => {
            const dropdown = dropdownRef.current;

            if (dropdown) {
                if (e.target === dropdown || dropdown.contains(e.target as Node)) return;
            }

            if (typeof onClose === "function") {
                onClose();
            }
        };

        if (open) {
            document.addEventListener("click", handleDropdownClose);
        }

        return () => document.removeEventListener("click", handleDropdownClose);
    }, [open, onClose]);

    return (
        <div
            className={`
                ${dropdown}
                ${isTriangle ? dropdownWithTriangle : ""}
                ${className}
                ${open ? openClass : ""}
            `}
            ref={dropdownRef}
        >
            {children}
        </div>
    );
};

export default Dropdown;
