import React from "react";
import { useLocation } from "@reach/router";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { useSelector } from "react-redux";

import {
    menu,
    logo as logoClass,
    left,
    center,
    right,
    stylize,
    userLogged,
} from "./menu.module.scss";
import { navItem } from "../atoms/button.module.scss";
import { selectUserId } from "../../redux/user/user.selectors";
import { IMenuLinksElement } from "../../models/menu-links-element.model";

import Link, { LinkProps } from "../atoms/link";
import Logo from "../atoms/logo";
import Button from "../atoms/button";

export interface IMenuProps {
    navItems: IMenuLinksElement[];
    className?: string;
    color?: "black" | "white";
}

const AREAS: Array<IMenuLinksElement["area"]> = ["left", "center", "right"];
const AREAS_STYLES_MAP = {
    left: left,
    center: center,
    right: right,
};

export default function Menu({ navItems, color }: IMenuProps) {
    const { t } = useI18next();
    const { pathname } = useLocation();
    const userId = useSelector(selectUserId);

    const currentLabelKey = navItems.find((item) => item.to === pathname)?.labelKey;

    const navItemMap = ({ labelKey, to, type, logo, as }: IMenuLinksElement) => {
        const Tag = as === "button" ? Button : Link;

        return (
            <Tag
                key={`menu-horizontal-${labelKey}`}
                type={type}
                to={to}
                color={color as LinkProps["color"]}
                textUppercase
                active={!logo ? currentLabelKey === labelKey : false}
                className={`${logo ? logoClass : ""} ${
                    type === "outlined" ? `${navItem} ${stylize}` : ""
                }`}
            >
                {logo ? <Logo color={color ? color : "black"} /> : t(labelKey)}
            </Tag>
        );
    };

    return (
        <div className={menu}>
            {AREAS.map((area) => (
                <div
                    key={`menu-area-${area}`}
                    className={`${AREAS_STYLES_MAP[area]} ${userId ? userLogged : ""}`}
                >
                    {navItems.filter((item) => item.area === area).map(navItemMap)}
                </div>
            ))}
        </div>
    );
}
