import React from "react";
import { ISurveyQuestion } from "../../../models/survey/survey-question.model";

import { numberInputWrapper, numberInput, inputUnit } from "./survey-input-number.module.scss";
import { input } from "../../atoms/form/input.module.scss";
import { FieldHookConfig, useField } from "formik";
import SurveyHint from "./survey-hint";

export interface ISurveyInputNumberProps {
    question: ISurveyQuestion;
}

const SurveyInputNumber = ({ question }: ISurveyInputNumberProps) => {
    const { inputType, hint, unit, questionId, placeholder } = question;

    const optionConfig: FieldHookConfig<any> = {
        name: questionId.toString(),
        type: inputType,
    };

    const [field] = useField(optionConfig);

    return (
        <label className={`${numberInputWrapper}`}>
            <input
                {...field}
                className={`${numberInput}`}
                type={inputType}
                placeholder={placeholder ? placeholder : ""}
                // min={min ? min : "0"}
                // max={max ? max : ""}
            />

            {unit && <span className={inputUnit}>{unit}</span>}
            {hint && <SurveyHint hint={hint} />}
        </label>
    );
};

export default SurveyInputNumber;
