import React from "react";
import { ErrorMessage, Field, FieldConfig } from "formik";
import TextareaAutosize from "react-textarea-autosize";

import {
    container,
    iconBox,
    icon,
    inputBox,
    input,
    labelBox,
    visible,
    transparent,
} from "./input.module.scss";

import Error from "./error";

type TInputTheme = 'white' | 'transparent';

export interface IInputProps {
    className?: string;
    name: string;
    label?: string;
    placeholder?: string;
    as?: FieldConfig["as"];
    rows?: number;
    identifier?: string | number;
    Icon?: React.SVGFactory;
    theme?: TInputTheme;
    showLabel?: boolean,
}

const Input = React.forwardRef<HTMLInputElement, IInputProps & Record<string, any>>(
    (
        {
            className = "",
            name,
            label = "",
            placeholder = "",
            as,
            rows = 3,
            identifier = "",
            Icon,
            theme = "white",
            showLabel = false,
            ...rest
        },
        ref
    ) => {
        const labelText = label || placeholder;
        const themeClass = themeClasses[theme];

        return (
            <div className={`${container} ${className} ${themeClass}`}>
                {labelText && (
                    <label
                        className={`${labelBox} ${showLabel ? visible : ''}`}
                        htmlFor={`${name}${identifier}`}
                    >
                        {labelText}
                    </label>
                )}
                <div className={`${inputBox}`}>
                    {Icon && (
                        <div className={iconBox}>
                            <Icon className={icon} />
                        </div>
                    )}
                    <Field
                        as={as === "textarea" ? TextareaAutosize : ""}
                        {...(as === "textarea" ? { minRows: rows } : {})}
                        id={`${name}${identifier}`}
                        name={name}
                        className={input}
                        placeholder={placeholder}
                        innerRef={ref}
                        {...rest}
                    />
                </div>
                <ErrorMessage name={name} component={Error} />
            </div>
        );
    }
);

const themeClasses: Record<TInputTheme, string> = {
    transparent: transparent,
    white: '',
};

export default Input;
