import { types } from "./user.actions";
import actionName from "../action-creator";

const USER = "[USER]";

const initialState = {};

function userReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actionName(USER, types.SET):
            return {
                ...state,
                ...payload,
            };
        case actionName(USER, types.CLEAR):
            return {
                ...initialState,
            };
        case actionName(USER, types.SET_PROFILE):
            return {
                ...state,
                profiles: [payload],
            };
        default:
            return state;
    }
}

export default userReducer;
export { USER, initialState };
