import React from "react";

import "./src/styles/reset.scss";
import "./src/styles/variables.scss";
import "./src/styles/global.scss";
import "./src/styles/markdown.scss";

import ReduxStore from "./src/redux/global-state.store";
import { ModalContextProvider } from "./src/contexts/modal.context";

if (process.env.NODE_ENV === "development") {
    import("./src/styles/dev.scss");
}

export const wrapRootElement = ({ element }) => {
    return (
        <ReduxStore>
            <ModalContextProvider>{element}</ModalContextProvider>
        </ReduxStore>
    );
};
