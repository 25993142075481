import { SURVEY } from "./survey.reducer";

const selectFirstSurvey = (state) => {
    return state[SURVEY].list ? state[SURVEY].list[0] : null;
};

const selectFirstSurveyId = (state) => {
    const survey = selectFirstSurvey(state);
    return survey ? survey.id : null;
};

const selectIsSurveyListEmpty = (state) =>
    state[SURVEY].list === null || state[SURVEY].list.length === 0;

export { selectFirstSurvey, selectFirstSurveyId, selectIsSurveyListEmpty };
