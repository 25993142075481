// extracted by mini-css-extract-plugin
export var closeButton = "side-panel-module--close-button--wdbS4";
export var footer = "side-panel-module--footer--dFnx7";
export var header = "side-panel-module--header---bxQI";
export var panel = "side-panel-module--panel--WET6C";
export var panelAdmin = "side-panel-module--panel--admin--E-wUp";
export var panelNav = "side-panel-module--panel-nav--i3B6F";
export var panelNav__avatarWrapper = "side-panel-module--panel-nav__avatar-wrapper--zeK5c";
export var panelNav__btn = "side-panel-module--panel-nav__btn--VAXw8";
export var panelNav__btnActive = "side-panel-module--panel-nav__btn--active--iRa6N";
export var panelNav__separator = "side-panel-module--panel-nav__separator--rgv4m";
export var panel__contentWrapper = "side-panel-module--panel__content-wrapper--SDNMS";