import {
    filled,
    outlined,
    white,
    black,
    primary,
    button,
    text,
    grey,
    secondary,
    smallHeightPadding,
    textTransform,
    disabled,
    navItem,
    beige,
} from "../components/atoms/button.module.scss";

import { IButtonBase } from "../models/button-base.model";

const getButtonTypeClass = (type: IButtonBase["type"]) => {
    switch (type) {
        case "filled":
            return filled;
        case "outlined":
            return outlined;
        case "nav-item":
            return navItem;
        default:
            return text;
    }
};

const getButtonColorClass = (color: IButtonBase["color"]) => {
    switch (color) {
        case "primary":
            return primary;
        case "white":
            return white;
        case "grey":
            return grey;
        case "secondary":
            return secondary;
        case "beige":
            return beige;
        default:
            return black;
    }
};

const getButtonTransformClass = (textUppercase: IButtonBase["textUppercase"]) =>
    textUppercase ? textTransform : "";

const getButtonDisabledClass = (disabledClass: IButtonBase["disabled"]) =>
    disabledClass ? disabled : "";

type GenerateButtonClassNameProps = Omit<IButtonBase, "children">;

export function generateButtonClassName(props: GenerateButtonClassNameProps) {
    const {
        type,
        color,
        className,
        smallPaddingHeight,
        textUppercase,
        disabled,
        active,
        activeClass,
    } = props;

    const typeClass = getButtonTypeClass(type);
    const colorClass = getButtonColorClass(color);
    const paddingClass = smallPaddingHeight ? smallHeightPadding : "";
    const textTransformClass = getButtonTransformClass(textUppercase);
    const disabledClass = getButtonDisabledClass(disabled);

    return `${button}\
        ${typeClass}\
        ${colorClass}\
        ${paddingClass}\
        ${textTransformClass}\
        ${disabledClass}\
        ${active ? activeClass : ""}\
        ${className ? className : ""}`;
}
