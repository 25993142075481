import React from "react";
import { Link } from "gatsby";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { container, backProfile, arrowBackProfile, formTop, formBottom, title, formBreak, formButton, inputTop, textBackProfile } from './generator-start-fields.module.scss';
import Arrow from "../../../assets/images/svg/arrow.svg";
import { priceFilters, selectFiltersConfigs } from "../config";
import { TGeneratorFilters } from "../../../models/generator.model";
import { getAbsolutePath } from "../../utility/routes.utility";

import Title from "../../../components/atoms/title";
import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/form/input";
import Select from "../../../components/atoms/form/select";

interface IGeneratorStartFieldsProps {
    className?: string;
    filters: TGeneratorFilters;
}

const GeneratorStartFields: React.FC<IGeneratorStartFieldsProps> = ({
    className = '',
    filters,
}) => {
    const { t } = useI18next();

    return (
        <div className={`${className} ${container}`}>
            <Title
                className={title}
                fontStyle="italic"
                size="medium"
                subtitle={t('generator.subtitle')}
                align="center"
            >
                {t('generator.title')}
            </Title>
            <div className={formTop}>
                <Input
                    name="name"
                    type="text"
                    placeholder={t('generator.form.placeholder.name')}
                    label={t('generator.form.label.name')}
                    className={inputTop}
                    theme="transparent"
                    showLabel={true}
                />
                {selectFiltersConfigs.map((config) => {
                    const { isMultiselect, filterKey } = config;
                    return (
                        <Select
                            key={filterKey}
                            options={filters[filterKey]}
                            name={filterKey}
                            label={t(`generator.form.label.${filterKey}`)}
                            placeholder={t(`generator.form.placeholder.${filterKey}`)}
                            isMultiselect={isMultiselect}
                            showCounter={isMultiselect}
                            colorTheme="light"
                            showLabel={true}
                        />
                    );
                })}
            </div>
            <p className={formBreak}>{t('generator.prices')}</p>
            <div className={formBottom}>
                {priceFilters.map((filterKey) => {
                    return (
                        <Select
                            key={filterKey}
                            options={filters[filterKey]}
                            name={filterKey}
                            colorTheme="light"
                            label={t(`generator.form.label.${filterKey}`)}
                            placeholder={t(`generator.form.placeholder.${filterKey}`)}
                            showLabel={true}
                        />
                    );
                })}
            </div>
            <Button
                htmlType="submit"
                type="filled"
                color="primary"
                className={formButton}
            >
                {t('generator.button.generate')}
            </Button>
            <Link
                to={getAbsolutePath('ACCOUNT_STYLIZATIONS')}
                className={backProfile}
            >
                <Arrow className={arrowBackProfile} />
                <p className={textBackProfile}>{t('generator.back')}</p>
            </Link>
        </div>
    );
};

export default GeneratorStartFields;