import React from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";

import { selectListPagination } from "../../redux/generic-list/generic-list.selectors";
import { IGenericListPagination } from "../../models/generic-list.model";

import Pagination from "./pagination";

export type GenericListTransformPageFunction = (pageIndex: number) => string;

export interface IGenericListPaginationProps {
    entity: string;
    subEntity: string;
    transformPageSlug?: GenericListTransformPageFunction;
}

export default function GenericListPagination({
    entity,
    subEntity,
    transformPageSlug,
}: IGenericListPaginationProps) {
    const pagination = useSelector((state) => selectListPagination(state, entity, subEntity));

    return (
        <Pagination
            {...transformPagination(pagination, transformPageSlug)}
            PageLinkComponent={Link}
        />
    );
}

const defaultTransformPage: GenericListTransformPageFunction = (pageIndex) => {
    return pageIndex.toString();
};

const transformPagination = (
    pagination: IGenericListPagination,
    transformPageSlug: GenericListTransformPageFunction = defaultTransformPage
) => {
    const keys = [];

    for (let i = 0; i < pagination.pageCount; i++) {
        keys.push(transformPageSlug(i + 1));
    }

    const pageKey = transformPageSlug(pagination.currentPage);

    return {
        keys,
        pageKey,
    };
};
