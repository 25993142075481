import { USER } from "./user.reducer";
import { selectEntityStatus } from "../ui/ui.selectors";

const selectUser = (state) => {
    return state[USER];
};

const selectUserId = (state) => {
    return state[USER].userId;
};

const selectUserEmail = (state) => {
    return state[USER]?.email || "";
};

const selectUserHasConfirmedEmail = (state) => {
    return state[USER].hasConfirmedEmail || false;
};

const selectUserSubscribeNewsletter = (state) => {
    return state[USER].isNewsletterSubscriber || true;
};

const selectUserProfile = (state) => {
    const user = selectUser(state);

    if (user && Array.isArray(user.profiles) && user.profiles.length > 0) {
        return user.profiles[0];
    }
    return null;
};

const selectUserLatestSurveySessionId = (state) => {
    const user = selectUser(state);
    let sessionId;
    if (user.surveySessions && Array.isArray(user.surveySessions)) {
        const sortedSessions = [...user.surveySessions].sort((a, b) => b.sessionId - a.sessionId);
        sessionId = sortedSessions[0]?.sessionId;
    }
    return sessionId;
};

const selectUserApiStatus = (state) => selectEntityStatus(state, USER);

export {
    selectUser,
    selectUserId,
    selectUserEmail,
    selectUserHasConfirmedEmail,
    selectUserSubscribeNewsletter,
    selectUserProfile,
    selectUserLatestSurveySessionId,
    selectUserApiStatus,
};
