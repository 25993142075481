import { action } from "../action-creator";
import { STYLIZATIONS } from "./stylizations.reducer";
import { IStylizationsGetPayload, IStylizationsSetPayload } from "../../models/stylization.model";

export const types = {
    GET: "get",
    SET: "set",
    CLEAR: "clear",
    RESTORE_INITIAL: "restore initial",
};

export const getStylizationsAction = (payload: IStylizationsGetPayload = {}) =>
    action(STYLIZATIONS, types.GET, payload);
export const setStylizationsAction = (payload: IStylizationsSetPayload) =>
    action(STYLIZATIONS, types.SET, payload);
export const clearStylizationsAction = () => action(STYLIZATIONS, types.CLEAR);
export const restoreStylizationsInitialStateAction = () =>
    action(STYLIZATIONS, types.RESTORE_INITIAL);
