import { action } from "../action-creator";

export const API = "[API]";

export const actions = {
    API_REQUEST: `${API} request`,
    API_SUCCESS: `${API} success`,
    API_FAIL: `${API} fail`,
};

const sendApiRequest = (payload, meta) => {
    const actionName = `${meta?.actionName} ${actions.API_REQUEST}`;
    return action(meta?.entity, actionName, payload, meta);
};

const receiveApiSuccess = (payload, meta) => {
    const actionName = `${meta?.actionName} ${actions.API_SUCCESS}`;
    return action(meta?.entity, actionName, payload, meta);
};

const receiveApiFail = (payload, meta) => {
    const actionName = `${meta?.actionName} ${actions.API_FAIL}`;
    return action(meta?.entity, actionName, payload, meta);
};

export { sendApiRequest, receiveApiSuccess, receiveApiFail };
