import React from "react";
import { Link } from "gatsby";

import { surveyHeader, surveyLogo, alerts } from "./headerSurvey.module.scss";

import Logo from "../../atoms/logo";
import AlertList from "../alert-list";

interface IHeaderSurveyProps {
    className?: string;
}

const HeaderSurvey: React.FC<IHeaderSurveyProps> = ({ className }) => {
    return (
        <header className={`${surveyHeader} ${className}`}>
            <Link to={"/"}>
                <Logo className={surveyLogo} color={"black"} type={"responsive"} />
            </Link>
            <AlertList className={alerts} />
        </header>
    );
};
export default HeaderSurvey;
