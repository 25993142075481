const actionName = (entity, actionName) => `${entity} ${actionName}`;

const action = (entity, actionName, payload, meta) => {
    return {
        type: `${entity} ${actionName}`,
        payload,
        meta: {
            entity,
            actionName,
            ...meta,
        },
    };
};

export default actionName;
export { action };
