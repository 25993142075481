import React, {useEffect, useRef, useState} from "react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";

import { useI18next } from "./useI18next";
import { getPathFromSlug } from "./get-path-from-slug";

const Link: React.FC<GatsbyLinkProps<any>> = ({
    children,
    to,
    ...rest
}) => {
    const { language } = useI18next();
    const [path, setPath] = useState<string | undefined>(getPathFromSlug(to, language))
    const prevToRef = useRef(to);

    useEffect(() => {
        if (prevToRef.current === to) return;
        setPath(getPathFromSlug(to, language));
    }, [to]);

    return (
        // @ts-ignore
        <GatsbyLink {...rest} to={path}>
            {children}
        </GatsbyLink>
    );
};

export default Link;
