import { combineReducers } from "redux";

import uiReducer, { UI } from "./ui/ui.reducer";
import alertReducer, { ALERTS } from "./alerts/alerts.reducer";
import rbacReducer, { RBAC } from "./rbac/rbac.reducer";
import userReducer, { USER } from "./user/user.reducer";
import formikReducer, { FORMIK } from "./formik/formik.reducer";
import surveyReducer, { SURVEY } from "./survey/survey.reducer";
import surveyFillReducer, { FILL_SURVEY } from "./survey-fill/survey-fill.reducer";
import surveySessionReducer, { SURVEY_SESSION } from "./survey-session/survey-session.reducer";
import stylizationsReducer, { STYLIZATIONS } from "./stylizations/stylizations.reducer";
import singleStylizationReducer, {
    SINGLE_STYLIZATION,
} from "./single-stylization/single-stylization.reducer";
import generatorFiltersReducer, {
    GENERATOR_FILTERS,
} from "./generator-filters/generator-filters.reducer";
import generatorProductsReducer, { GENERATOR_PRODUCTS } from "./generator-products/generator-products.reducer";

const rootReducer = combineReducers({
    [USER]: userReducer,
    [FORMIK]: formikReducer,
    [RBAC]: rbacReducer,
    [ALERTS]: alertReducer,
    [UI]: uiReducer,
    [SURVEY]: surveyReducer,
    [FILL_SURVEY]: surveyFillReducer,
    [SURVEY_SESSION]: surveySessionReducer,
    [STYLIZATIONS]: stylizationsReducer,
    [SINGLE_STYLIZATION]: singleStylizationReducer,
    [GENERATOR_FILTERS]: generatorFiltersReducer,
    [GENERATOR_PRODUCTS]: generatorProductsReducer,
});

export default rootReducer;
