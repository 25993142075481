import genericListMiddleware from "../generic-list/generic-list.middleware";
import { SURVEY_SESSION, LIST } from "./survey-session.reducer";
import {
    getSingleSurveySessionQuestions,
    setSurveySessionQuestionList,
    setSurveySessionQuestionSingle,
    updateAnswer,
    updateStatus,
} from "./survey-session.actions";
import { config } from "../../config";
import { sendApiRequest } from "../api/api.actions";
import {
    selectFirstSurveySessionId,
    selectSurveySessionListItems,
} from "./survey-session.selectors";
import { actions as apiActions } from "../api/api.actions";
import { actions as genericListAction } from "../generic-list/generic-list.actions";
import { setAlert } from "../alerts/alerts.actions";
import { action as actionCreator } from "../action-creator";

const { entity, sessions, questions } = config.endpoints.surveys;

const surveySessionMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;

    next(action);

    const sessionId = meta?.sessionId || selectFirstSurveySessionId(getState());
    const items = selectSurveySessionListItems(getState());
    let fetchConfig, collection;

    switch (type) {
        case getSingleSurveySessionQuestions().type:
            fetchConfig = {
                url: `${entity}${sessions}/${payload}${questions}`,
                withCredentials: true,
            };

            dispatch(
                sendApiRequest(fetchConfig, { ...meta, entity: SURVEY_SESSION, sessionId: payload })
            );
            break;

        case `${getSingleSurveySessionQuestions().type} ${apiActions.API_SUCCESS}`:
            collection = {};
            payload.forEach((question) => {
                collection[question.id] = question;
            });

            dispatch(setSurveySessionQuestionList(collection, meta));
            break;

        case `${getSingleSurveySessionQuestions().type} ${apiActions.API_FAIL}`:
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się pobrać ankiety",
                    },
                    meta
                )
            );
            break;

        case updateAnswer().type:
            if (sessionId !== null) {
                const fetchConfig = {
                    url: `${entity}${sessions}/${sessionId}${questions}`,
                    method: "patch",
                    data: payload,
                    withCredentials: true,
                };
                dispatch(sendApiRequest(fetchConfig, { ...meta, entity: SURVEY_SESSION }));
            }
            break;

        case `${updateAnswer().type} ${apiActions.API_SUCCESS}`:
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: "Zaktualizowano pytanie",
                    },
                    meta
                )
            );

            dispatch(setSurveySessionQuestionSingle(payload[0], meta));
            break;

        case `${updateAnswer().type} ${apiActions.API_FAIL}`:
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się zaktualizować pytania",
                    },
                    meta
                )
            );
            break;

        case updateStatus().type:
            if (sessionId !== null) {
                const fetchConfig = {
                    url: `${entity}${sessions}/${sessionId}`,
                    method: "patch",
                    data: { status: payload },
                    withCredentials: true,
                    params: {
                        expand: "user",
                    },
                };
                dispatch(sendApiRequest(fetchConfig, { ...meta, entity: SURVEY_SESSION }));
            }
            break;

        case `${updateStatus().type} ${apiActions.API_SUCCESS}`:
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: "Zaktualizowano status",
                    },
                    meta
                )
            );
            collection = items.map((item) => {
                if (payload.id === item.id) {
                    return payload;
                }
                return item;
            });
            dispatch(
                actionCreator(
                    `${SURVEY_SESSION} ${LIST}`,
                    genericListAction.document.set,
                    {
                        items: collection,
                    },
                    meta
                )
            );
            break;

        case `${updateStatus().type} ${apiActions.API_FAIL}`:
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się zaktualizować statusu",
                    },
                    meta
                )
            );
            break;
        //no default
    }
};

const surveySessionListMiddleware = genericListMiddleware(SURVEY_SESSION, LIST, "", "user");

const surveySessionMiddlewares = [surveySessionMiddleware, surveySessionListMiddleware];

export default surveySessionMiddlewares;
