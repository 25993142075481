import { action } from "../action-creator";
import { FORMIK } from "./formik.reducer";

const actions = {
    SET_FORM_ID: `set_form_id`,
    SET_FORM_DATA: `set_form_data`,
    CLEAR_FORM: `clear_form`,
    CLEAR_ALL_FORMS: `clear_all_forms`,
};

const setForm = (formId) => action(FORMIK, actions.SET_FORM_ID, formId, {});

const setFormData = (payload, meta) => action(FORMIK, actions.SET_FORM_DATA, payload, meta);

const clearForm = (formId) => action(FORMIK, actions.CLEAR_FORM, formId);

const clearAllForms = () => action(FORMIK, actions.CLEAR_FORM);

export { setForm, setFormData, clearForm, clearAllForms };
