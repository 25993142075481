import { ComponentProps } from "react";
import type ConfirmationModal from "../components/modals/confirmation-modal";

export enum EModal {
    CONFIRMATION = 'CONFIRMATION',
    SURVEY_REGISTER = 'SURVEY_REGISTER',
}

export interface IModalBase {
    canBeClosed?: boolean;
}

export interface IConfirmationModal extends IModalBase {
    key: EModal.CONFIRMATION;
    props: Omit<ComponentProps<typeof ConfirmationModal>, 'modalId'>;
}

export interface IRegisterModal extends IModalBase {
    key: EModal.SURVEY_REGISTER;
    props?: never,
}

export type TModalData = IConfirmationModal | IRegisterModal;

export type TModal = TModalData & TModalBaseProps;

export type TModalBaseProps = {
    modalId: number;
}
