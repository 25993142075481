import actionName from "../action-creator";
import { GENERATOR_FILTERS } from "./generator-filters.reducer";
import {
    setGeneratorFiltersAction,
    restoreGeneratorFiltersInitialStateAction,
    types,
} from "./generator-filters.actions";
import endpoints from "../../config/endpoints";
import { actions, sendApiRequest } from "../api/api.actions";
import { statusIdle } from "../ui/ui.actions";

const generatorFiltersMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta } = action;

    next(action);

    let fetchConfig;

    switch (type) {
        case actionName(GENERATOR_FILTERS, types.GET):
            fetchConfig = {
                url: endpoints.stylizations.filters,
                method: "GET",
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;
        case actionName(GENERATOR_FILTERS, `${types.GET} ${actions.API_SUCCESS}`):
            dispatch(setGeneratorFiltersAction(payload));
            break;
        case actionName(GENERATOR_FILTERS, types.CLEAR):
            dispatch(statusIdle({ actionName: types.CLEAR, entity: GENERATOR_FILTERS }));
            dispatch(restoreGeneratorFiltersInitialStateAction());
            break;
        default:
            break;
    }
};

export default generatorFiltersMiddleware;
