import {
    setFillSurvey,
    setFillSurveyPage,
    clearFillSurvey,
    setFillSurveyUnansweredPages,
    setFillSurveyQuestions,
    setFillSurveyShowPopup,
    clearFillSurveyQuestions,
} from "./survey-fill.actions";

const FILL_SURVEY = "[FILL_SURVEY]";

const initFillSurveyState = {
    sessionId: null,
    user: null,
    pageIndex: null,
    pageCount: null,
    unansweredPages: null,
    questions: {},
    showPopup: false,
};

function surveyFillReducer(state = initFillSurveyState, action) {
    const { type, payload } = action;

    switch (type) {
        case setFillSurvey().type:
            return {
                ...state,
                ...payload,
            };
        case setFillSurveyPage().type:
            if (Number.isSafeInteger(payload)) {
                return {
                    ...state,
                    pageIndex: payload,
                };
            }
            return state;
        case setFillSurveyQuestions().type:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    [payload.index]: payload.items,
                },
            };
        case setFillSurveyUnansweredPages().type:
            return {
                ...state,
                unansweredPages: payload,
            };
        case setFillSurveyShowPopup().type:
            return {
                ...state,
                showPopup: payload,
            };
        case clearFillSurveyQuestions().type:
            return {
                ...state,
                questions: initFillSurveyState.questions,
            };
        case clearFillSurvey().type:
            return initFillSurveyState;
        default:
            return state;
    }
}

export default surveyFillReducer;
export { FILL_SURVEY, initFillSurveyState };
