import React from "react";

import { IRoute, ISimpleRouteObject } from "./models/route.model";
import { objectFromRouteArray, processRedirect } from "./utility/routes.utility";
import { AUTH_ROUTES } from "./auth/auth.routes";
import { ACCOUNT_ROUTES } from "./account/account.routes";
import { SURVEY_ROUTES } from "./survey/survey.routes";
import { ADM_ROUTES } from "./adm/adm.routes";
import { GENERATOR_ROUTES } from "./generator/generator.routes";

import PrivateRoute from "./hoc/private-route";

export const ROUTES: IRoute[] = [
    ...ACCOUNT_ROUTES,
    ...AUTH_ROUTES,
    ...SURVEY_ROUTES,
    ...ADM_ROUTES,
    ...GENERATOR_ROUTES,
];

export const ROUTES_MAP: ISimpleRouteObject = objectFromRouteArray(ROUTES, false);

export const renderRoutes = (routes: IRoute[], Component = PrivateRoute) => {
    return routes.map((route) => {
        //TODO: delete this? (because it has be also in PrivateRoute component)
        const processedRedirect = processRedirect(route.redirect);

        if (route.routes && route.routes.length) {
            return (
                <Component
                    path={route.path}
                    key={route.id}
                    routeData={route}
                    Component={route.Component}
                    redirect={processedRedirect}
                    {...route.routeProps}
                >
                    {renderRoutes(route.routes)}
                </Component>
            );
        }
        return (
            <Component
                path={route.path}
                key={route.id}
                routeData={route}
                Component={route.Component}
                redirect={processedRedirect}
                {...route.routeProps}
            />
        );
    });
};
