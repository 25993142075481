import React from "react";
import * as CSS from "csstype";

import { section } from "./section.module.scss";
import Title, { ITitleProps } from "../../atoms/title";

type columnName =
    | "full"
    | "regular"
    | "narrow"
    | "left"
    | "right"
    | "full-left"
    | "full-right"
    | "narrow-right";

export interface ISectionProps {
    className?: string;
    columnPhone?: columnName;
    columnTablet?: columnName;
    column?: columnName;
    children?: any;
    title?: ITitleProps;
}

const Section = React.forwardRef<HTMLDivElement, ISectionProps>((props, ref) => {
    const {
        className = "",
        title,
        children,
        column = "regular",
        columnPhone = column,
        columnTablet = column,
    } = props;

    const columnStyle: CSS.Properties = {
        ["--column-phone" as any]: `${columnPhone}`,
        ["--column-tablet" as any]: `${columnTablet}`,
        ["--column" as any]: `${column}`,
    };

    return (
        <section className={`${section} ${className}`} style={columnStyle} ref={ref}>
            {title && <Title {...title} />}
            {children}
        </section>
    );
});

Section.displayName = "Section";

export default Section;
