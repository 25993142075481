import { ROUTES } from "../routes";
import { IRoute, IRouteObject, ISimpleRouteObject } from "../models/route.model";

export function getRouteNode(
    routes: IRoute[],
    nodeKey: string,
    prefix: string
): IRoute | undefined {
    for (const route of routes) {
        route.prefix =
            route.path === "/" || route.path === "./" ? prefix : `${prefix}/${route.path}`;
        if (route.id === nodeKey) {
            return route;
        }
        if (route.routes && routes.length > 0) {
            const childRoute = getRouteNode(route.routes, nodeKey, route.prefix);
            if (childRoute) {
                return childRoute;
            }
        }
    }
}

export function getAbsolutePath(
    nodeKey: string,
    routes: IRoute[] = ROUTES,
    param: string | number = ""
): string {
    const node = getRouteNode(routes, nodeKey, "");
    if (node?.prefix?.includes(node.path)) {
        return `${node.prefix}${param ? "/" + param : ""}`;
    }
    return node ? `${node.prefix ? node.prefix : ""}/${node.path}${param ? "/" + param : ""}` : "";
}

export const checkRouteStringType = (text: string) => {
    return text.match(/\/+/g) ? "path" : "id";
};

export const processRouteString = (value: string) => {
    const path = checkRouteStringType(value) === "id" ? getAbsolutePath(value) : value;

    if (path === "") {
        // eslint-disable-next-line no-console
        console.error(
            `getAbsolutePath returned empty string for ${value} id. Check if you provided correct route id`
        );
    }
    return path;
};

export const processRedirect = (redirect: IRoute["redirect"]) => {
    if (redirect) {
        return {
            from: redirect.from ? processRouteString(redirect.from) : undefined,
            to: processRouteString(redirect.to),
        };
    }
};

export function objectFromRouteArray(routes: IRoute[], recursive: false): ISimpleRouteObject;
export function objectFromRouteArray(routes: IRoute[], recursive: true): IRouteObject;
export function objectFromRouteArray(routes: IRoute[] = ROUTES, recursive: boolean) {
    return routes.reduce<IRouteObject | ISimpleRouteObject>((acc, route) => {
        if (recursive) {
            acc[route.id] = {
                ...route,
                routes: objectFromRouteArray(route.routes, recursive),
            };
        } else {
            acc[route.id] = {
                ...route,
                routes: route.routes,
            };
        }
        return acc;
    }, {});
}
