import { useSelector, useDispatch } from "react-redux";
import { nextPage, prevPage } from "../../../redux/survey-fill/survey-fill.actions";
import { selectEntityIsLoading } from "../../../redux/ui/ui.selectors";
import {
    selectFillSurvey,
    selectFillSurveyCategories,
    selectFillSurveyCurrentPage,
    selectFillSurveyId,
    selectFillSurveyPageCount,
    selectFillSurveyPageIndex,
    selectFillSurveyQuestionsByPageIndex,
    selectFillSurveyUnansweredPages,
} from "../../../redux/survey-fill/survey-fill.selectors";
import { FILL_SURVEY } from "../../../redux/survey-fill/survey-fill.reducer";
import { SURVEY } from "../../../redux/survey/survey.reducer";
import { selectFirstSurveyId } from "../../../redux/survey/survey.selectors";

export default function useFillSurvey() {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => selectEntityIsLoading(state, FILL_SURVEY));
    const isSurveyLoading = useSelector((state) => selectEntityIsLoading(state, SURVEY));

    return {
        goPrevPage: () => dispatch(prevPage()),
        goNextPage: (payload: Record<string, any>) => dispatch(nextPage(payload)),
        isLoading: isLoading || isSurveyLoading,
        sessionId: useSelector(selectFillSurveyId),
        surveyId: useSelector(selectFirstSurveyId),
        categories: useSelector(selectFillSurveyCategories),
        currentPage: useSelector(selectFillSurveyCurrentPage),
        session: useSelector(selectFillSurvey),
        pageIndex: useSelector(selectFillSurveyPageIndex),
        pageCount: useSelector(selectFillSurveyPageCount),
        unansweredPages: useSelector(selectFillSurveyUnansweredPages),
        pageQuestions: useSelector(selectFillSurveyQuestionsByPageIndex),
    };
}
