import React, { AnimationEventHandler, ReactNode } from "react";

import {
    menuMobile,
    header as headerClass,
    content as contentClass,
    footer as footerClass,
    closing as closingClass,
    beige as beigeClass,
    violet as violetClass,
} from "./menu-template.module.scss";

type MenuTemplateColor = "beige" | "violet";

export interface MenuTemplateProps {
    header?: ReactNode;
    content?: ReactNode;
    footer?: ReactNode;
    closing?: boolean;
    onAnimationEnd?: AnimationEventHandler;
    color?: MenuTemplateColor;
}

const MenuTemplate = React.forwardRef<HTMLDivElement, MenuTemplateProps>(
    ({ header, content, footer, closing, onAnimationEnd, color }, ref) => {
        return (
            <div
                className={`${menuMobile} ${closing ? closingClass : ""} ${
                    color ? colorMap[color] : ""
                }`}
                onAnimationEnd={onAnimationEnd}
                ref={ref}
            >
                <div className={headerClass}>{header}</div>
                <div className={contentClass}>{content}</div>
                <div className={footerClass}>{footer}</div>
            </div>
        );
    }
);

const colorMap: Record<MenuTemplateColor, string> = {
    beige: beigeClass,
    violet: violetClass,
};

export default MenuTemplate;
