import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import FormikForm from "../../../components/hoc/formik-form";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import useUser from "../hooks/use-user";
import { config } from "../../../config";

import { authForm, button, input } from "./auth-forms.module.scss";

import Input from "../../../components/atoms/form/input";
import Button from "../../../components/atoms/button";

const { min, repeat } = config.messages.password;
const { required } = config.messages.form;

const initialValues = {
    currentPassword: "",
    newPassword: "",
    repeatPassword: "",
};

const changePass = Yup.object({
    currentPassword: Yup.string(),
    newPassword: Yup.string().min(8, min),
    repeatPassword: Yup.string()
        .required(required)
        .oneOf([Yup.ref("newPassword")], repeat),
});

const PassChangeForm = () => {
    const { t } = useI18next();
    const { passChange } = useUser();

    return (
        <Formik
            initialValues={{ ...initialValues }}
            validationSchema={changePass}
            onSubmit={passChange}
        >
            {(formik) => (
                <FormikForm formik={formik} successMessage={""} className={authForm}>
                    <Input
                        name="currentPassword"
                        type="password"
                        className={input}
                        autoComplete="old-password"
                        placeholder={t("form.label.currentPassword")}
                    />
                    <Input
                        name="newPassword"
                        type="password"
                        className={input}
                        autoComplete="new-password"
                        placeholder={t("form.label.newPassword")}
                    />
                    <Input
                        name="repeatPassword"
                        type="password"
                        className={input}
                        autoComplete="new-password"
                        placeholder={t("form.label.repeatPassword")}
                    />
                    <Button color="primary" type="filled" htmlType="submit" className={button}>
                        {t("button.newPass")}
                    </Button>
                </FormikForm>
            )}
        </Formik>
    );
};

export default PassChangeForm;
