import { clearForm, setForm, setFormData } from "./formik.actions";

import { config } from "../../config";

const FORMIK = "[FORMIK]";

const initialState = {};

function formikReducer(state = initialState, action) {
    const { type, payload, meta } = action;

    switch (type) {
        case setForm().type:
            return {
                ...state,
                [payload]: {
                    errors: {},
                    status: config.apiStatusMap.idle,
                },
            };
        case setFormData().type:
            return {
                ...state,
                [meta.formId]: payload,
            };
        case clearForm().type:
            const newState = { ...state };
            delete newState[payload];
            return { ...newState };
        default:
            return state;
    }
}

export default formikReducer;
export { FORMIK, initialState };
