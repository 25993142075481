import { config } from "../config";

export const errorHandler = (error) => {
    let messages = [];

    if (error?.messages && Array.isArray(error.messages)) {
        error.messages.forEach(function (msg) {
            messages.push({
                content: msg.content,
                field: msg.field ? msg.field : "global",
                type: "error",
            });
        });
    } else {
        messages.push({
            content: config.messages.error,
            field: "global",
            type: "error",
        });
    }

    return messages;
};

export const formatFormikErrors = (errorObj) => {
    const errorsArray = errorHandler(errorObj);
    let formikErrors = {};

    errorsArray.forEach((item) => {
        formikErrors[item.field] = item.content;
    });
    return formikErrors;
};
