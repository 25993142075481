import { IGeneratorFormValues, TGeneratorRequestParams } from "../models/generator.model";

export function getGeneratorParams(values: IGeneratorFormValues): TGeneratorRequestParams {
    const keys = Object.keys(values) as Array<keyof IGeneratorFormValues>;
    return keys.reduce((result: TGeneratorRequestParams, key: keyof IGeneratorFormValues) => {
        const value = values[key];
        if (key === "name") return result;
        if (!value) return result;
        if (key === "blockedProducts") {
            const blockedProducts = values[key];
            blockedProducts.forEach((product) => {
                result[product.categoryGroup] = product.productId;
            });
            return result;
        }
        if (Array.isArray(value) && value.length > 0) {
            result[key] = value.join(",");
            return result;
        }
        if (!Array.isArray(value)) {
            result[key] = value;
            return result;
        }
        return result;
    }, {});
}
