import { RBAC } from "./rbac.reducer";

const actions = {
    SET_PERMISSIONS: `${RBAC} set permissions`,
    CLEAR_PERMISSIONS: `${RBAC} clear permissions`,
    // user triggered actions
    FORBIDDEN_CLICK: `${RBAC} forbidden_click`,
};

const setPermissions = (payload) => ({
    type: actions.SET_PERMISSIONS,
    payload,
});

const clearPermissions = () => ({
    type: actions.CLEAR_PERMISSIONS,
});

// middleware actions
const forbiddenClick = (payload, meta) => ({
    type: actions.FORBIDDEN_CLICK,
    payload,
    meta,
});

export default actions;
export { setPermissions, clearPermissions, forbiddenClick };
