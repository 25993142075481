import { actions } from "./alerts.actions";
import actionName from "../action-creator";

const ALERTS = "[ALERTS]";

const initialState = {};

function alertReducer(state = initialState, action) {
    const { payload, type, meta = {} } = action;
    const { id } = meta;

    switch (true) {
        case type.includes(actionName(ALERTS, actions.SET_ALERT)):
            return { ...state, [id]: payload };
        case type.includes(actionName(ALERTS, actions.CLEAR_ALL_ALERTS)):
            return { ...initialState };
        case type.includes(actionName(ALERTS, actions.CLEAR_ALERT)):
            const newState = { ...state };
            delete newState[payload];
            return { ...newState };
        default:
            return state;
    }
}

export default alertReducer;
export { ALERTS };
