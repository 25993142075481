import { actions } from "../api/api.actions";
import { setFormData } from "./formik.actions";
import { formatFormikErrors } from "../../api/utils";

const formikMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload, meta = {} } = action;

    if (action.payload?.formId) {
        action.meta.formId = action.payload.formId;
        delete action.payload.formId;
    }

    if (action.meta?.formId && type.includes(actions.API_REQUEST)) {
        dispatch(setFormData({ errors: {}, status: "loading" }, meta));
    }
    if (action.meta?.formId && type.includes(actions.API_FAIL)) {
        dispatch(setFormData({ errors: formatFormikErrors(payload?.data), status: "fail" }, meta));
    }
    if (action.meta?.formId && type.includes(actions.API_SUCCESS)) {
        dispatch(setFormData({ errors: {}, status: "success" }, meta));
    }

    next(action);
};

export default formikMiddleware;
