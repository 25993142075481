import React, {useRef} from "react";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { button, icon } from './button-close.module.scss';
import CloseIcon from '../../assets/images/svg/letter-x.svg';

interface Props {
    className?: string,
    onClick?(): any;
}

const ButtonClose: React.FC<Props> = ({
    className = '',
    onClick = () => {},
}) => {
    const { t } = useI18next();
    const buttonRef = useRef(null);

    const handleClick = (): void => {
        // @ts-ignore
        buttonRef.current.blur();
        onClick();
    }

    return (
        <button
            ref={buttonRef}
            className={`${button} ${className}`}
            aria-label={t('button.close')}
            onClick={handleClick}
            type="button"
        >
            <CloseIcon className={icon} />
        </button>
    )
}

export default ButtonClose;