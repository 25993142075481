import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    footer,
    hidden,
    upper,
    lower,
    social,
    logo,
    button,
    logoAp,
    links,
    footerLink,
} from "./footer.module.scss";
import getFooterLinks from "../../utils/get-footer-links";
import LogoAP from "../../assets/images/svg/logo_ap.svg";

import Logo from "../atoms/logo";
import Facebook from "../../assets/images/svg/facebook.svg";
import Instagram from "../../assets/images/svg/instagram.svg";
import Link from "../atoms/link";

interface IFooterProps {
    isVisible?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ isVisible = true }) => {
    const footerLinks = getFooterLinks("footerLinks");
    const { t } = useI18next();

    return (
        <footer className={`${footer} ${isVisible ? "" : hidden}`}>
            <div className={upper}>
                <GatsbyLink to="/" className={logo}>
                    <Logo color="white" type="logo" />
                </GatsbyLink>
                <div className={links}>
                    {footerLinks.map(({ key, url, translationKey, external }) => {
                        if (translationKey) {
                            return (
                                <Link
                                    key={`footer-link-${key}`}
                                    to={url}
                                    className={footerLink}
                                    external={external}
                                >
                                    {t(translationKey)}
                                </Link>
                            );
                        }
                        return null;
                    })}
                </div>
                <div className={social}>
                    <Link
                        external
                        color="white"
                        to={getFooterLinks("socialLinks", "instagram")}
                        className={button}
                    >
                        <Instagram />
                    </Link>
                    <Link
                        external
                        color="white"
                        to={getFooterLinks("socialLinks", "facebook")}
                        className={button}
                    >
                        <Facebook />
                    </Link>
                </div>
            </div>
            <div className={lower}>
                <p>Copyright © 2021 Fashion Fairy</p>
                <p>
                    Created by{" "}
                    <a href="https://alterpage.pl">
                        <LogoAP className={logoAp} />
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
