import React from "react";

import { surveySessionCategoryTitle, title } from "./survey-session-category-title.module.scss";

export interface ISurveySessionCategoryTitle {
    children?: string;
    style?: React.CSSProperties;
}

export default function SurveySessionCategoryTitle({
    children,
    style,
}: ISurveySessionCategoryTitle) {
    return (
        <div className={surveySessionCategoryTitle} style={style}>
            <p className={title}>{children}</p>
        </div>
    );
}
