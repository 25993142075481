import React from "react";
import { Link as GatsbyLink } from "gatsby";

import { container, pageLink, active, arrow } from "./pagination.module.scss";

import ArrowLeft from "../../assets/images/svg/arrow_left.svg";
import ArrowRight from "../../assets/images/svg/arrow_right.svg";

import Link from "../../../plugins/gatsby-plugin-ap-i18next/src/link";

export interface IPaginationProps<PageLinkType extends React.ElementType = typeof GatsbyLink> {
    keys: string[];
    pageKey: string;

    linkClassName?: string;
    wrapperClassName?: string;
    edgeLinksClassName?: string;
    showArrows?: boolean;
    range?: number;
    PageLinkComponent?: PageLinkType;
}

const Pagination: React.FC<IPaginationProps> = (props) => {
    const {
        keys,
        pageKey,
        linkClassName = "",
        wrapperClassName = "",
        edgeLinksClassName = "",
        showArrows = true,
        range = 2,
        PageLinkComponent = Link,
    } = props;

    const activeIndex = keys.indexOf(pageKey);
    const minIndex = activeIndex - range > 0 ? activeIndex - range : 0;
    const maxIndex = keys.length < activeIndex + range ? keys.length : activeIndex + range + 1;

    const keysRange = keys.slice(minIndex, maxIndex);

    return keys.length > 1 ? (
        <div aria-label="pagination navigation" className={`${container} ${wrapperClassName}`}>
            {showArrows && minIndex > 0 && (
                <>
                    <PageLinkComponent
                        to={keys[0]}
                        className={`${pageLink} ${arrow} ${edgeLinksClassName}`}
                    >
                        <ArrowLeft />
                    </PageLinkComponent>
                    ...
                </>
            )}

            {keysRange.map((key, index) => {
                return (
                    <PageLinkComponent
                        key={`pagination-link-${index}`}
                        to={key}
                        className={`${pageLink} ${linkClassName}`}
                        activeClassName={active}
                    >
                        {keys.indexOf(key) + 1}
                    </PageLinkComponent>
                );
            })}

            {showArrows && maxIndex < keys.length && (
                <>
                    ...
                    <PageLinkComponent
                        to={keys[keys.length - 1]}
                        className={`${pageLink} ${arrow} ${edgeLinksClassName}`}
                    >
                        <ArrowRight />
                    </PageLinkComponent>
                </>
            )}
        </div>
    ) : null;
};

export default Pagination;
