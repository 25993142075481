import { IRoute } from "../models/route.model";

import Login from "./templates/login";
import PassChangeRequest from "./templates/pass-request-reset";
import PassReset from "./templates/pass-reset";
import AuthRoute from "./hoc/auth-route";
import MailConfirmation from "./templates/mail-confirmation";

export const AUTH_ROUTES: IRoute[] = [
    {
        path: "auth",
        label: "",
        id: "AUTH",
        redirect: {
            from: "AUTH",
            to: "AUTH_LOGIN",
        },
        Component: AuthRoute,
        routes: [
            {
                path: "logowanie",
                label: "Logowanie",
                id: "AUTH_LOGIN",
                Component: Login,
                routes: [],
            },
            {
                path: "przypomnij-haslo",
                label: "Przypomnij hasło",
                id: "AUTH_PASS_RESET_REQUEST",
                Component: PassChangeRequest,
                routes: [],
            },
            {
                path: "ustaw-nowe-haslo",
                label: "Ustaw nowe hasło",
                id: "AUTH_PASS_RESET",
                Component: AuthRoute,
                routes: [
                    {
                        path: "/",
                        label: "Ustaw nowe hasło",
                        id: "AUTH_PASS_RESET_HOME",
                        Component: PassReset,
                        routes: [],
                    },
                    {
                        path: ":token",
                        label: "Ustaw nowe hasło",
                        id: "AUTH_PASS_RESET_TOKEN",
                        Component: PassReset,
                        routes: [],
                    },
                ],
            },
            // {
            //     path: "rejestracja",
            //     label: "Rejestracja",
            //     id: "AUTH_REGISTER",
            //     Component: Register,
            //     routes: [],
            // },
            {
                path: "potwierdzenie-maila",
                label: "Potwierdzenie maila",
                id: "AUTH_CONFIRMATION",
                Component: AuthRoute,
                routes: [
                    {
                        path: "/",
                        label: "Potwierdzenie maila",
                        id: "AUTH_CONFIRMATION_HOME",
                        Component: MailConfirmation,
                        routes: [],
                    },
                    {
                        path: ":token",
                        label: "Potwierdzenie maila",
                        id: "AUTH_CONFIRMATION_TOKEN",
                        Component: MailConfirmation,
                        routes: [],
                    },
                ],
            },
            {
                path: "*",
                label: "",
                id: "AUTH_REDIRECT",
                Component: AuthRoute,
                redirect: {
                    to: "AUTH_LOGIN",
                },
                routes: [],
            },
        ],
    },
];
