import { EGeneratorFilterKeys } from "../../models/generator.model";
import { large, medium, small } from "./organisms/generator-draw-fields.module.scss";

export const fieldsAvailableToClear = [EGeneratorFilterKeys.colors, EGeneratorFilterKeys.patterns];

export const priceFilters = [
    EGeneratorFilterKeys.priceTop,
    EGeneratorFilterKeys.priceBottom,
    EGeneratorFilterKeys.priceOuterwear,
    EGeneratorFilterKeys.priceOverall,
];

export const selectFiltersConfigs = [
    {
        filterKey: EGeneratorFilterKeys.occasion,
        selectClassName: large,
        isMultiselect: false,
    },
    {
        filterKey: EGeneratorFilterKeys.colors,
        selectClassName: small,
        isMultiselect: true,
    },
    {
        filterKey: EGeneratorFilterKeys.patterns,
        selectClassName: small,
        isMultiselect: true,
    },
    {
        filterKey: EGeneratorFilterKeys.style,
        selectClassName: medium,
        isMultiselect: false,
    },
];
