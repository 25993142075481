module.exports = [
    {
        labelKey: "Witaj",
        to: "ADM_DASHBOARD",
    },
    {
        labelKey: "Ankiety",
        to: "ADM_SURVEY_SESSION",
    },
];
