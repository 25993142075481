import { IRoute } from "../models/route.model";
import UserPanel from "./templates/user-panel";
import ConfirmEmail from "./templates/confirm-email";
import PassChange from "./templates/pass-change";
import Newsletter from "./templates/newsletter";
import Stylizations from "./templates/stylizations";
import AccountData from "./templates/account-data";
import SurveySessionView from "./templates/survey-session-view";
import AccountRoute from "./hoc/account-route";
import AccountLayout from "../../layouts/account-layout";
import SurveyQuestionView from "./templates/survey-question-view";

export const ACCOUNT_ROUTES: IRoute[] = [
    {
        path: "konto",
        label: "",
        id: "ACCOUNT",
        redirect: {
            from: "ACCOUNT",
            to: "ACCOUNT_LAYOUT",
        },
        Component: AccountRoute,
        routes: [
            {
                id: "ACCOUNT_LAYOUT",
                path: "/",
                label: "",
                redirect: {
                    from: "ACCOUNT_LAYOUT",
                    to: "ACCOUNT_DASHBOARD",
                },
                Component: AccountLayout,
                routes: [
                    {
                        path: "panel",
                        label: "Moje konto",
                        id: "ACCOUNT_DASHBOARD",
                        Component: UserPanel,
                        routes: [],
                    },
                    {
                        path: "potwierdz-email",
                        label: "Potwierdź adres email",
                        id: "ACCOUNT_CONFIRM_EMAIL",
                        Component: ConfirmEmail,
                        routes: [],
                    },
                    {
                        path: "zmiana-hasla",
                        label: "Zmiana hasła",
                        id: "ACCOUNT_PASS_CHANGE",
                        Component: PassChange,
                        routes: [],
                    },
                    {
                        path: "newsletter",
                        label: "Newsletter",
                        id: "ACCOUNT_NEWSLETTER",
                        Component: Newsletter,
                        routes: [],
                    },
                    {
                        path: "stylizacje",
                        label: "Twoje stylizacje",
                        id: "ACCOUNT_STYLIZATIONS",
                        Component: Stylizations,
                        routes: [],
                    },
                    {
                        path: "dane",
                        label: "Dane konta",
                        id: "ACCOUNT_DATA",
                        Component: AccountData,
                        routes: [],
                    },
                    {
                        path: "ankieta",
                        label: "Ankieta",
                        id: "ACCOUNT_SURVEY_SESSION",
                        Component: AccountRoute,
                        redirect: {
                            from: "ACCOUNT_SURVEY_SESSION",
                            to: "ACCOUNT_SURVEY_SESSION_VIEW",
                        },
                        routes: [
                            {
                                path: ":surveySessionId",
                                label: "Ankieta",
                                id: "ACCOUNT_SURVEY_SESSION_VIEW",
                                Component: AccountRoute,
                                routes: [
                                    {
                                        path: "/",
                                        label: "Pytanie",
                                        id: "ACCOUNT_SURVEY_SESSION_VIEW_SINGLE",
                                        Component: SurveySessionView,
                                        routes: [],
                                    },
                                    {
                                        path: "pytanie/:questionId",
                                        label: "Pytanie",
                                        id: "ACCOUNT_SURVEY_SESSION_QUESTION",
                                        Component: SurveyQuestionView,
                                        routes: [],
                                    },
                                ],
                            },
                            {
                                path: "*",
                                label: "",
                                id: "ACCOUNT_SURVEY_SESSION_REDIRECT",
                                Component: AccountRoute,
                                redirect: {
                                    to: "ACCOUNT_SURVEY_SESSION",
                                },
                                routes: [],
                            },
                        ],
                    },
                    {
                        path: "*",
                        label: "",
                        id: "ACCOUNT_REDIRECT",
                        Component: AccountRoute,
                        redirect: {
                            to: "ACCOUNT",
                        },
                        routes: [],
                    },
                ],
            },
        ],
    },
];
