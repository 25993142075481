import React from "react";

import { surveySessionQuestionList } from "./survey-session-question-list.module.scss";
import { ISurveyQuestion } from "../../../models/survey/survey-question.model";
import { ISurveyCategory } from "../../../models/survey/survey-session.model";

import SurveySessionCategoryTitle from "../atoms/survey-session-category-title";
import SurveyAnswerQuestionCard, {
    ISurveyAnswerQuestionCardProps,
} from "../atoms/survey-answer-question-card";
import useMediaQuery from "../../../hooks/use-media-query";
import { navigate } from "gatsby";

interface ISurveySessionFragment {
    categories: ISurveyCategory[];
    questions: ISurveyQuestion[] | null;
    canEdit?: boolean;
}

const BREAKPOINTS_MAP = {
    "1610": 3,
    "1290": 2,
    "845": 1,
    "832": 2,
    "710": 1,
};

export default function SurveySessionQuestionList({
    categories = [],
    questions = [],
    canEdit = true,
}: ISurveySessionFragment) {
    const windowWidth = useMediaQuery(
        Object.keys(BREAKPOINTS_MAP) as Array<keyof typeof BREAKPOINTS_MAP>
    );

    const { gridTemplateColumns, fallback, ...categoriesColumnNames } = getGridData(
        categories,
        BREAKPOINTS_MAP[windowWidth]
    );

    const style = {
        gridTemplateColumns,
    };

    const editAction: ISurveyAnswerQuestionCardProps["onEdit"] = (questionId) => {
        const path = `pytanie/${questionId}`;
        navigate(path);
    };

    return (
        <div className={surveySessionQuestionList} style={style}>
            {categories.map((category) => (
                <SurveySessionCategoryTitle
                    key={`survey-session-category-${category.categoryId}`}
                    style={categoriesColumnNames[category.categoryId]}
                >
                    {category.name}
                </SurveySessionCategoryTitle>
            ))}
            {questions &&
                questions.map((question) => (
                    <SurveyAnswerQuestionCard
                        key={`survey-session-card-${question.questionId}`}
                        question={question}
                        onEdit={editAction}
                        canEdit={canEdit}
                        style={
                            question.category !== null
                                ? categoriesColumnNames[question.category.categoryId]
                                : fallback
                        }
                    />
                ))}
        </div>
    );
}

type GridPropertiesFragment = Partial<Pick<React.CSSProperties, "gridColumn" | "display">>;
type ProcessSurveyCategoriesResult = {
    gridTemplateColumns?: string;
    fallback?: GridPropertiesFragment;
} & Record<number, GridPropertiesFragment>;

/**
 * This function divide array of categories into group of predefined columns. For example:
 *
 * array = ['cat-1', 'cat-2', 'cat-3', 'cat-4'];
 * column = 2;
 *
 * result = [['cat-1', 'cat-3'],['cat-2', 'cat-4']];
 */
const divideCategoriesByColumns = (categories: ISurveyCategory[], columns = 4) => {
    const categoriesByColumns = [];

    for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
        const categoryChunk = [];
        let nextCategoryIndex = columnIndex;

        while (categories[nextCategoryIndex]) {
            categoryChunk.push(categories[nextCategoryIndex]);
            nextCategoryIndex += columns;
        }

        categoriesByColumns.push(categoryChunk);
    }

    return categoriesByColumns;
};

/**
 * This function create grid-template-columns property for container and assign grid column for each column id.
 * It also creates fallback for questions without category
 */
const getGridData = (categories: ISurveyCategory[], columns?: number) => {
    const gridTemplateData = divideCategoriesByColumns(
        categories,
        columns
    ).reduce<ProcessSurveyCategoriesResult>((acc, categoryArray) => {
        const columnName = categoryArray.map((category) => `cat-${category.categoryId}`).join(" ");

        return {
            ...acc,
            gridTemplateColumns: acc.gridTemplateColumns
                ? `${acc.gridTemplateColumns} [${columnName}] 1fr`
                : `[${columnName}] 1fr`,
            fallback: {
                display: "none",
            },
        };
    }, {});

    const gridColumnsObject = categories.reduce<ProcessSurveyCategoriesResult>(
        (acc, category, index) => {
            const columnName = `cat-${category.categoryId}`;

            return {
                ...acc,
                [category.categoryId]: {
                    gridColumn: columnName,
                    order: index + 1,
                },
            };
        },
        {}
    );

    return {
        ...gridTemplateData,
        ...gridColumnsObject,
    };
};
