const prepareFilterParams = (filterObj) => {
    const params = {};
    if (filterObj) {
        Object.entries(filterObj).forEach(([key, filter]) => {
            Object.entries(filter.values).forEach(([valName, val]) => {
                if (val.applied) {
                    let separator = params[key] === undefined ? "" : ",";

                    if (valName === "phrase") {
                        //hardcoded condition when filter is search
                        params[key] = val.value;
                    } else {
                        params[key] = (params[key] || "") + `${separator}${valName}`;
                    }
                }
            });
        });
    }

    return params;
};

const prepareMultipleFilterParams = (...params) => {
    return params.reduce(
        (acc, current) => ({
            ...acc,
            ...prepareFilterParams(current),
        }),
        {}
    );
};

const prepareGenericListParams = ({ state, payload, fieldName, page = 1 }) => {
    const { filters, sort, search } = state;

    const payloadFilter = payload[fieldName] ? payload[fieldName] : {};

    return {
        page,
        ...payload.params,
        ...prepareMultipleFilterParams(search, filters, sort, payloadFilter),
    };
};

export { prepareFilterParams, prepareMultipleFilterParams, prepareGenericListParams };
