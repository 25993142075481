import React from "react";
import { useLocation } from "@reach/router";

import PrivateRoute, { PrivateRouteProps } from "../../hoc/private-route";
import { IRoute } from "../../models/route.model";
import useCheckPermissions from "../../hooks/use-check-permissions";
import { config } from "../../../config";

const { Admin } = config.rbac.permissionMap;

export default function AdmRoute({ ...props }: PrivateRouteProps) {
    const { pathname } = useLocation();

    const isAdmin = useCheckPermissions(Admin.role);

    let redirect: IRoute["redirect"] = props?.redirect;

    if (!isAdmin) {
        redirect = {
            from: pathname,
            to: "AUTH",
        };
    }

    return <PrivateRoute {...props} redirect={redirect} />;
}
