import React from "react";
import { useLocation } from "@reach/router";

import PrivateRoute, { PrivateRouteProps } from "../../hoc/private-route";
import { IRoute } from "../../models/route.model";
import useFillSurvey from "../hooks/use-fill-survey";
import { processRouteString } from "../../utility/routes.utility";

export default function SurveyRoute({ ...props }: PrivateRouteProps) {
    const { pathname } = useLocation();
    const { pageIndex, pageCount, unansweredPages } = useFillSurvey();

    let redirect: IRoute["redirect"] = props?.redirect;

    if (!Array.isArray(unansweredPages) && pathname === processRouteString("SURVEY_SUCCESS")) {
        redirect = {
            from: pathname,
            to: "SURVEY_QUESTION_NUMBER",
        };
    }

    if (pageIndex === pageCount + 1 && pathname !== processRouteString("SURVEY_SUCCESS")) {
        redirect = {
            from: pathname,
            to: "SURVEY_SUCCESS",
        };
    }

    if (
        pageIndex !== null &&
        pageIndex <= pageCount &&
        pathname !== `/${props.path}/${pageIndex}`
    ) {
        redirect = {
            from: pathname,
            to: `/ankieta/${pageIndex}`,
        };
    }

    return <PrivateRoute {...props} redirect={redirect} />;
}
