import React from "react";
import { ErrorMessage, useFormikContext } from "formik";

import {
    questionWrapper,
    questionImgWrapper,
    answersWrapper,
    descriptionWrapper,
    questionTitle,
    questionSubtitle,
    optionIcon,
    circleGrid,
    squareGrid,
    squareGridNoText,
    hoverGrid,
    imageText,
    error,
    text,
    questionImg,
    counter,
    alignCenter,
    selectMulti,
} from "./survey-question.module.scss";
import Heart from "../../../assets/images/svg/heart.svg";
import Thumb from "../../../assets/images/svg/thumb.svg";
import { ISurveyQuestion } from "../../../models/survey/survey-question.model";
import { ISurveyOptionProps } from "../../../models/survey/survey-question-option-props.model";

import Title from "../../atoms/title";
import SurveyOptionCircle from "../../molecules/survey/survey-option-circle";
import SurveyOptionSquare from "../../molecules/survey/survey-option-square";
import SurveyOptionSquareNoText from "../../molecules/survey/survey-option-square-no-text";
import Error from "../../atoms/form/error";
import SurveyInputNumber from "../../molecules/survey/survey-input-number";
import SurveyOptionSquareHover from "../../molecules/survey/survey-option-square-hover";
import SurveyInputDate from "../../molecules/survey/survey-input-date";
import SurveyOptionImageText from "../../molecules/survey/survey-option-image-text";
import Select from "../../atoms/form/select";

export interface ISurveyQuestionProps {
    question: ISurveyQuestion;
}

const SurveyQuestion = ({ question }: ISurveyQuestionProps) => {
    const {
        questionId,
        title,
        subtitle,
        description,
        context,
        layout,
        options,
        inputType,
        imgUrl,
    } = question;

    const ContextOptionIcon = getContextClass(context);

    const { values = "" } = useFormikContext();

    const valuesNumber = values[questionId]?.length ? values[questionId].length : 0;

    return (
        <div className={`${questionWrapper} ${getLayoutClass(layout)}`}>
            {imgUrl && (
                <div className={questionImgWrapper}>
                    <img className={questionImg} src={imgUrl} alt="" />
                </div>
            )}

            {title && (
                <Title
                    Tag={"h2"}
                    size={"medium"}
                    containerClassName={questionTitle}
                    subtitle={subtitle}
                    subtitleClassName={questionSubtitle}
                >
                    {title}
                </Title>
            )}

            <div className={optionIcon}>
                {inputType && inputType === "checkbox" && (
                    <p className={text}>Pytanie wielokrotnego wyboru.</p>
                )}
                {ContextOptionIcon && <ContextOptionIcon />}
            </div>

            {description && <div className={descriptionWrapper}>{description}</div>}

            <div className={`${answersWrapper}`}>
                {options &&
                    inputType !== "select" &&
                    options.map((option) => {
                        const Component = getOptionComponent(layout);
                        if (Component !== null) {
                            return (
                                <Component
                                    key={`option-${questionId}-${option.id}`}
                                    option={option}
                                    questionId={questionId}
                                    inputType={inputType}
                                    context={context}
                                />
                            );
                        }
                        return null;
                    })}

                {inputType === "number" && <SurveyInputNumber question={question} />}
                {inputType === "date" && (
                    <SurveyInputDate question={question} className={alignCenter} />
                )}
                {inputType === "select" && (
                    <Select
                        options={question.options}
                        name={question.questionId.toString()}
                        placeholder={question.placeholder}
                        className={selectMulti}
                    />
                )}
                <ErrorMessage name={questionId.toString()} component={Error} className={error} />
            </div>

            {values && inputType === "checkbox" && (
                <p className={counter}>Wybrano: {valuesNumber}</p>
            )}
        </div>
    );
};

function getLayoutClass(layout: ISurveyQuestion["layout"]): string {
    switch (layout) {
        case "circleGrid":
            return circleGrid;
        case "squareGrid":
            return squareGrid;
        case "squareGridNoText":
            return squareGridNoText;
        case "hoverGrid":
            return hoverGrid;
        case "text":
            return text;
        case "imageText":
        default:
            return imageText;
    }
}

function getOptionComponent(
    layout: ISurveyQuestion["layout"]
): React.FC<ISurveyOptionProps> | null {
    switch (layout) {
        case "circleGrid":
            return SurveyOptionCircle;
        case "squareGrid":
            return SurveyOptionSquare;
        case "squareGridNoText":
            return SurveyOptionSquareNoText;
        case "hoverGrid":
            return SurveyOptionSquareHover;
        case "imageText":
            return SurveyOptionImageText;
        default:
            return null;
    }
}

function getContextClass(context: ISurveyQuestion["context"]): React.SVGFactory | null {
    switch (context) {
        case "affirmative":
            return Heart;
        case "negative":
            return Thumb;
        default:
            return null;
    }
}

export default SurveyQuestion;
