import React from "react";
import { useLocation } from "@reach/router";

import { config } from "../../../config";
import { IRoute } from "../../models/route.model";
import { useSelector } from "react-redux";
import useCheckPermissions from "../../hooks/use-check-permissions";
import useFillSurvey from "../../survey/hooks/use-fill-survey";
import { selectUserHasConfirmedEmail } from "../../../redux/user/user.selectors";

import PrivateRoute, { PrivateRouteProps } from "../../hoc/private-route";

const { User, Admin } = config.rbac.permissionMap;

export default function AccountRoute({ ...props }: PrivateRouteProps) {
    const { pathname } = useLocation();
    const { unansweredPages } = useFillSurvey();

    const isAdmin = useCheckPermissions(Admin.role);
    const isUser = useCheckPermissions(User.role);
    const hasUserConfirmedEmail = useSelector(selectUserHasConfirmedEmail);

    let redirect: IRoute["redirect"] = props?.redirect;

    if (isAdmin) {
        redirect = {
            from: pathname,
            to: "ADM",
        };
    } else if (!isUser) {
        redirect = {
            from: pathname,
            to: "AUTH",
        };
    } else if (isUser && unansweredPages?.length > 0) {
        redirect = {
            from: pathname,
            to: `/ankieta/${unansweredPages[0]}`,
        };
        // Check temporary disabled -> manager request
        // } else if (isUser && !hasUserConfirmedEmail) {
        //     redirect = {
        //         from: pathname,
        //         to: "ACCOUNT_CONFIRM_EMAIL",
        //     };
    } else if (hasUserConfirmedEmail && pathname === "/konto/potwierdz-email") {
        redirect = {
            from: pathname,
            to: "ACCOUNT_DASHBOARD",
        };
    }

    return <PrivateRoute {...props} redirect={redirect} />;
}
