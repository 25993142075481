// extracted by mini-css-extract-plugin
export var center = "menu-module--center--IT47K";
export var fadeIn = "menu-module--fade-in--fRJhL";
export var fadeOut = "menu-module--fade-out--VPBl6";
export var left = "menu-module--left--zkBKf";
export var logo = "menu-module--logo--KQ-6I";
export var menu = "menu-module--menu--YSabd";
export var right = "menu-module--right--rzUBK";
export var slideFromBottom = "menu-module--slide-from-bottom--Vd-FI";
export var slideFromLeft = "menu-module--slide-from-left--08YCd";
export var slideFromRight = "menu-module--slide-from-right--0KrtS";
export var slideFromTop = "menu-module--slide-from-top--QQ1C-";
export var slideToBottom = "menu-module--slide-to-bottom--UbFzp";
export var slideToLeft = "menu-module--slide-to-left--DhIXH";
export var slideToRight = "menu-module--slide-to-right--JDPDt";
export var slideToTop = "menu-module--slide-to-top--ppZ8M";
export var stylize = "menu-module--stylize--siCRL";
export var userLogged = "menu-module--user-logged--WoJ02";
export var zoomIn = "menu-module--zoom-in--kU83V";
export var zoomOut = "menu-module--zoom-out--oxwLv";