import React, { useCallback } from "react";
import Calendar, { CalendarProps } from "react-calendar";

import { calendar, calendarWrapper, open } from "./date-picker.module.scss";

export type TDateLevel = "decade" | "year" | "month";

export interface IDatePickerProps
    extends Pick<
        CalendarProps,
        | "className"
        | "activeStartDate"
        | "defaultActiveStartDate"
        | "onClickDay"
        | "onClickMonth"
        | "onClickYear"
        | "locale"
        | "minDetail"
        | "maxDetail"
        | "minDate"
        | "maxDate"
        | "value"
        | "nextLabel"
        | "next2Label"
        | "prevLabel"
        | "prev2Label"
        | "showNeighboringMonth"
    > {
    wrapperClassName?: string;
    isOpen: boolean;
}

export default function DatePicker({
    className,
    wrapperClassName,
    isOpen,
    value,
    activeStartDate,
    defaultActiveStartDate,
    locale,
    minDate,
    maxDate,
    minDetail,
    maxDetail,
    showNeighboringMonth = true,
    nextLabel,
    next2Label,
    prevLabel,
    prev2Label,
    onClickDay,
    onClickYear,
    onClickMonth,
}: IDatePickerProps) {
    const genWrapperCssClass = useCallback(() => {
        return `${calendarWrapper} ${wrapperClassName} ${isOpen ? open : ""}`;
    }, [isOpen, wrapperClassName]);

    return (
        <div className={genWrapperCssClass()}>
            <Calendar
                className={`${calendar} ${className}`}
                activeStartDate={activeStartDate}
                defaultActiveStartDate={defaultActiveStartDate}
                onClickDay={onClickDay}
                onClickMonth={onClickMonth}
                onClickYear={onClickYear}
                locale={locale}
                minDetail={minDetail}
                maxDetail={maxDetail}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                nextLabel={nextLabel}
                next2Label={next2Label}
                prevLabel={prevLabel}
                prev2Label={prev2Label}
                showNeighboringMonth={showNeighboringMonth}
            />
        </div>
    );
}
