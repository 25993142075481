import { ISurveyQuestion } from "../models/survey/survey-question.model";
import * as Yup from "yup";
import { FormikValues } from "formik";
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

const genInitialValues = (questions: ISurveyQuestion[]): FormikValues => {
    const initialValues: FormikValues = {};

    if (Array.isArray(questions)) {
        questions.forEach((question) => {
            initialValues[question.questionId] = genQuestionValue(question);
        });
    }

    return initialValues;
};

const genQuestionValue = (question: ISurveyQuestion) => {
    if (question.answers !== null) {
        return question.answers;
    }

    switch (question.inputType) {
        case "checkbox":
            return [];
        case "number":
        case "date":
        case "radio":
        case "select":
        default:
            return "";
    }
};

const genValidationSchema = (
    questions: ISurveyQuestion[],
    t: ReturnType<typeof useI18next>["t"]
) => {
    const schema: Record<string, any> = {};

    if (Array.isArray(questions)) {
        questions.forEach((question) => {
            schema[question.questionId] = genQuestionSchema(question, t);
        });
    }

    return Yup.object().shape(schema);
};

const genQuestionSchema = (question: ISurveyQuestion, t: ReturnType<typeof useI18next>["t"]) => {
    switch (question.inputType) {
        case "number":
            return genNumberSchema(question, t);
        case "checkbox":
            return genCheckboxSchema(t);
        case "radio":
            return genRadioSchema(t);
        case "date":
            return genDateSchema(t);
        case "select":
            return genSelectSchema(t);
        default:
            return "no-type";
    }
};

const genSelectSchema = (t: ReturnType<typeof useI18next>["t"]) =>
    Yup.number().required(t("survey.validation.radio"));

const genCheckboxSchema = (t: ReturnType<typeof useI18next>["t"]) =>
    Yup.array(Yup.number()).min(1, t("survey.validation.checkbox")).required();

const genRadioSchema = (t: ReturnType<typeof useI18next>["t"]) =>
    Yup.string().required(t("survey.validation.radio"));

const genDateSchema = (t: ReturnType<typeof useI18next>["t"]) =>
    Yup.date().required(t("form.validation.required"));

const genNumberSchema = (question: ISurveyQuestion, t: ReturnType<typeof useI18next>["t"]) =>
    Yup.number().required(t("form.validation.required"));

export { genInitialValues, genValidationSchema };
