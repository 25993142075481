import url from "url";

import * as keyToPage from "./plugin/key-to-page.json";
import * as pageToKey from "./plugin/page-to-key.json";

export function getPathFromSlug(slug: string, language: string) {
    const validSlug = getStringWithoutStartingAndTrailingSlashes(slug);
    // @ts-ignore
    let path = keyToPage[`${language}.${validSlug}`];

    if (!path) {
        let pathname = url.parse(slug).pathname;
        if (!pathname) return path;
        if (pathname.length > 1 && !pathname.endsWith('/')) {
            pathname = `${pathname}/`;
        }
        // @ts-ignore
        const key = pageToKey[pathname];
        // @ts-ignore
        path = keyToPage[`${language}.${key}`];
    }

    return path;
}

function getStringWithoutStartingAndTrailingSlashes(slug: string) {
    let newSlug = slug;
    if (newSlug !== '/' && newSlug.startsWith('/')) {
        newSlug = slug.substring(1);
    }
    if (newSlug !== '/' && newSlug.endsWith('/')) {
        newSlug = newSlug.slice(0, -1);
    }
    return newSlug;
}