import React from "react";

import {
    surveySessionHeaderElement,
    title as titleClass,
    content,
} from "./survey-session-header-element.module.scss";

export interface ISurveySessionHeaderElement {
    title: string;
    children: string | React.ReactNode;
    className?: string;
}

export default function SurveySessionHeaderElement({
    title,
    children,
    className = "",
}: ISurveySessionHeaderElement) {
    return (
        <div className={`${surveySessionHeaderElement} ${className}`}>
            <p className={titleClass}>{title}</p>
            {typeof children === "string" ? <p className={content}>{children}</p> : children}
        </div>
    );
}
