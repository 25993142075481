const socialLinks = [
    {
        key: "instagram",
        url: "https://www.instagram.com/fashionfairy.pl/",
    },
    {
        key: "facebook",
        url: "https://www.facebook.com/Fashionfairy_pl-102671732110010",
    },
];

const footerLinks = [
    {
        key: "regulations",
        url: "/docs/regulamin-serwisu.pdf",
        translationKey: "footer.regulations",
        external: true,
    },
    {
        key: "privacy policy",
        url: "/docs/polityka-prywatnosci.pdf",
        translationKey: "footer.privacyPolicy",
        external: true,
    },
    {
        key: "contact",
        url: "/kontakt",
        translationKey: "nav.contact",
    },
    {
        key: "faq",
        url: "/faq",
        translationKey: "footer.faq",
    },
];

module.exports = {
    socialLinks,
    footerLinks,
};
