import { actions, getItems } from "./generic-list.actions";
import { prepareMultipleFilterParams } from "./generic-list.utils";
import { setAlert } from "../alerts/alerts.actions";
import { errorHandler } from "../../api/utils";
import { sendApiRequest } from "../api/api.actions";
import actionName from "../action-creator";

const { document, api, command } = actions;

export const genericListMiddleware = (entity, subEntity, endpoint, expand = "") => (state) => (
    next
) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    next(action);

    let fetchConfig, params, data, error;
    const actionEntity = `${entity} ${subEntity}`;
    const listState = getState()[entity][subEntity];
    const { items, filters, sort, pagination } = listState;
    const { currentPage } = pagination;

    switch (type) {
        case actionName(actionEntity, command.search):
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    actionEntity,
                    params: {
                        page: 1,
                        ...payload.params,
                        [payload.key]: payload.value !== "" ? payload.value : undefined,
                        ...prepareMultipleFilterParams(filters, sort),
                    },
                })
            );
            break;

        case actionName(actionEntity, command.filter):
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    fieldName: "filters",
                    actionEntity,
                })
            );
            break;

        case actionName(actionEntity, command.sort):
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    fieldName: "sort",
                    actionEntity,
                })
            );
            break;

        case actionName(actionEntity, command.fetch):
            dispatch(
                getItems({
                    state: listState,
                    payload,
                    actionEntity,
                    page: currentPage + 1,
                })
            );
            break;

        case actionName(actionEntity, command.get):
            params = payload?.params;

            fetchConfig = {
                url: payload.url ? payload.url : endpoint,
                withCredentials: true,
                params: {
                    expand: expand,
                    ...params,
                },
            };

            dispatch(sendApiRequest(fetchConfig, { ...meta, entity, actionName: subEntity }));
            break;

        case actionName(actionEntity, api.success): //?
        case actionName(actionEntity, command.del):
            data = {};
            if (payload.pagination.currentPage === 1) {
                data = payload;
            } else {
                data = {
                    ...payload,
                    items: [...items, ...payload.items],
                };
            }
            dispatch({
                type: actionName(actionEntity, document.set),
                payload: data,
            });
            break;

        case actionName(actionEntity, api.fail):
            error = errorHandler(payload.data);
            error.map((err) => dispatch(setAlert(err, meta)));
            break;
        case actionName(actionEntity, command.addItem):
            dispatch({
                type: actionName(actionEntity, document.set),
                payload: {
                    items: [...items, payload],
                },
            });
            break;
        // no default
    }
};

export default genericListMiddleware;
