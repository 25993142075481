import React, { useEffect } from "react";
import { Link as GatsbyLink } from "gatsby";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import CookiesModal from "../../components/organisms/cookies-modal";

import {
    cookiesModule,
    cookiesBar,
    cookiesBarContent,
    cookiesBarButtons,
    cookiesBarButtonFilled
} from "./cookies-box.module.scss";

interface ICookiesBoxProps {
    isVisible?: boolean;
}

const CookiesBox: React.FC<ICookiesBoxProps> = () => {

    const [modalVisible, setModalVisible] = React.useState(false);
    const [cookiesVisible, setCookiesVisible] = React.useState(false);

    useEffect(() => {
        cookiesCheck();
    }, []);

    const cookiesCheck = () => {
        const cookiesNotice = getCookieValue('cookie notice accepted');
        console.log('cookiesNotice: ', cookiesNotice);
        if (cookiesNotice) {
            setModalVisible(false);
            setCookiesVisible(false);
        }
        else {
            setCookiesVisible(true);
        }
    }

    const getCookieValue = (name) => (
        document.cookie.split('; ').find(row => row.startsWith(name + '='))?.split('=')[1]
    );

    const acceptCookies = () => {
        var expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 12);
        document.cookie = `cookie notice accepted=true; expires=${expiryDate.toUTCString()}; path=/`;
        document.cookie = `cookie analytics notice accepted=true; expires=${expiryDate.toUTCString()}; path=/`;
        setModalVisible(false);
        setCookiesVisible(false);
    }

    const acceptCookiesCallBack = () => {
        setTimeout(() => {
            cookiesCheck();
        }, 20);
    }

    return (<div className={cookiesModule}>
        <CookiesModal isVisible={modalVisible} acceptCookiesCallBack={acceptCookiesCallBack} />
        {cookiesVisible && <div className={cookiesBar}>
            <div className={cookiesBarContent}>
                <div>
                    <h3 id="akceptacja-plikow-cookies">Akceptacja plików Cookies</h3>
                    <p>Ta strona używa plików cookies w celu zapewnienia prawidłowego i spersonalizowanego działania. Szczegółowe informacje dotyczące sposobów wykorzystywania plików cookies, a także możliwości ich wyłączenia znajdziesz w <a href="/docs/polityka-prywatnosci.pdf">Polityce Prywatności</a>.</p>
                    <p>Zaznaczając okienko „Zapisz i zamknij” wyrażasz dobrowolną zgodę na przetwarzanie Twoich danych osobowych zbieranych za pośrednictwem plików cookies.</p>
                </div>
            </div>
            <div className={cookiesBarButtons}>
                <button style={{ marginRight: '1em' }} onClick={(e) => setModalVisible(true)}>Ustawienia cookies</button>
                <button className={cookiesBarButtonFilled} onClick={(e) => acceptCookies()}>Zapisz i zamknij</button>
            </div>
        </div>}
    </div>)
}

export default CookiesBox;
