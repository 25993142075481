import React, { useCallback, useEffect, useRef, useState } from "react";
import rehypeRaw from "rehype-raw";

import {
    alert,
    success,
    warning,
    error,
    closeButton,
    closeButtonWrapper,
    open,
    messageWrapper,
} from "./alert.module.scss";
import ButtonClose from "../atoms/button-close";
import ReactMarkdown from "react-markdown";

export interface IAlertProps {
    type: "success" | "error" | "warning";
    message: string;
    canClose: boolean;
    onClose?: () => void;
}

export default function Alert({
    type = "warning",
    message,
    canClose = true,
    onClose,
}: IAlertProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [pendingClose, setPendingClose] = useState(false);
    const alertRef = useRef<HTMLDivElement | null>(null);

    const typeClassName = getAlertTypeClass(type);
    const animationClassName = isOpen ? open : "";

    const handleClick = () => {
        setIsOpen(false);
    };

    const handleTransitionEnd = useCallback(() => {
        if (!isOpen && onClose && !pendingClose) {
            setPendingClose(true);
            onClose();
        }
    }, [isOpen, onClose, pendingClose]);

    useEffect(() => {
        setIsOpen(true);
    }, []);

    useEffect(() => {
        const currentAlertRef = alertRef.current;
        currentAlertRef?.addEventListener("transitionend", handleTransitionEnd);

        return () => {
            currentAlertRef?.removeEventListener("transitionend", handleTransitionEnd);
        };
    }, [handleTransitionEnd]);

    return (
        <div className={`${alert} ${typeClassName} ${animationClassName}`} ref={alertRef}>
            <ReactMarkdown
                className={messageWrapper}
                // @ts-ignore
                rehypePlugins={[rehypeRaw]}
            >
                {message}
            </ReactMarkdown>
            {canClose && (
                <div className={closeButtonWrapper}>
                    <ButtonClose className={closeButton} onClick={handleClick} />
                </div>
            )}
        </div>
    );
}

const getAlertTypeClass = (type: IAlertProps["type"]) => {
    switch (type) {
        case "success":
            return success;
        case "warning":
            return warning;
        case "error":
            return error;
        default:
            return "";
    }
};
