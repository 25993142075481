import { UI } from "./ui.reducer";
import apiStatusMap from "../../config/status-map";

const selectEntityStatus = (state, entity) => {
    return state[UI][entity];
};

const selectEntityIsLoading = (state, entity) => {
    return state[UI][entity] === apiStatusMap.loading;
};

export { selectEntityStatus, selectEntityIsLoading };
