import { actions, receiveApiFail, receiveApiSuccess } from "./api.actions";
import instance from "../../api/axiosInstance";
import instance2 from "../../api/axiosInstance2";
import { clearAllAlerts } from "../alerts/alerts.actions";
import { fetchCheckUser, clearUserData } from "../user/user.actions";

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
const apiMiddleware = (state) => (next) => async (action) => {
    const { dispatch } = state;
    const { type, payload, meta = {} } = action;
    const { entity, actionName, useAlternateApi } = meta;

    let errorPayload;

    let clearAlerts = true;
    if (meta.clearAlerts !== undefined) {
        clearAlerts = meta.clearAlerts;
    }

    next(action);

    if (entity && actionName && type.includes(actions.API_REQUEST)) {
        if (clearAlerts) {
            dispatch(clearAllAlerts());
        }

        try {
            const axiosInstance = useAlternateApi ? instance2 : instance;
            const config = {
                ...payload,
                headers: {
                    ...payload.headers,
                }
            };
            if (useAlternateApi) {
                const token = getCookie('apinew-token');
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            //const response = await axiosInstance(payload);
            const response = await axiosInstance(config);
            dispatch(receiveApiSuccess(response?.data, meta));
        } catch (error) {
            if (error.response) {
                errorPayload = error.response;
                if (
                    [401, 403].includes(errorPayload.status) &&
                    type !== "[USER] fetch_check_user [API] request"
                ) {
                    dispatch(fetchCheckUser());
                    if (useAlternateApi){
                        const token = getCookie('apinew-token');
                        console.log('axios error 401/403 token: '+token);
                        if (!token){
                            console.log('axios error 401/403 token -> logout');
                            dispatch(clearUserData());
                            document.cookie = `api-token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
                            window.location='/auth/logowanie';
                        }
                    }
                }

                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                errorPayload = error.request;
            } else if (error.message) {
                // Something happened in setting up the request that triggered an Error
                errorPayload = error.message;
            }
            console.log(error);

            dispatch(receiveApiFail(errorPayload, meta));
        }
    }
};

export default apiMiddleware;
