// extracted by mini-css-extract-plugin
export var arrowBackProfile = "generator-start-fields-module--arrow-back-profile--Upkbm";
export var backProfile = "generator-start-fields-module--back-profile--x7aRR";
export var container = "generator-start-fields-module--container--d5Fby";
export var formBottom = "generator-start-fields-module--form-bottom--dCBeS";
export var formBreak = "generator-start-fields-module--form-break--TmoGB";
export var formButton = "generator-start-fields-module--form-button--N-oKq";
export var formTop = "generator-start-fields-module--form-top--gMEBL";
export var inputTop = "generator-start-fields-module--input-top--RN5fx";
export var textBackProfile = "generator-start-fields-module--text-back-profile--UnUfu";
export var title = "generator-start-fields-module--title--3j8e6";