import React from "react";

import { closed, icon, userButton, white, black, opened } from "./user-button.module.scss";

import UserAvatar from "../../assets/images/svg/user-avatar.svg";

export interface IUserButtonProps {
    color?: "black" | "white";
    isOpen?: boolean;
    onClick?: () => void;
}

export default function UserButton({ color, isOpen, onClick }: IUserButtonProps) {
    return (
        <button
            className={`${userButton} ${getOpenedClass(isOpen)} ${getColorClass(color)}`}
            onClick={onClick}
        >
            <UserAvatar className={icon} />
        </button>
    );
}

const getOpenedClass = (isOpen?: boolean) => {
    return isOpen ? opened : closed;
};

const getColorClass = (color: IUserButtonProps["color"]) => {
    switch (color) {
        case "white":
            return white;
        case "black":
            return black;
        default:
            return "";
    }
};
