// extracted by mini-css-extract-plugin
export var active = "cookies-modal-module--active--F4J-h";
export var buttonFilled = "cookies-modal-module--button-filled--3TFr4";
export var buttonOutlined = "cookies-modal-module--button-outlined--cC2Rs";
export var checked = "cookies-modal-module--checked--pCIOf";
export var cookiesModal = "cookies-modal-module--cookies-modal--X1zp8";
export var cookiesModalFooter = "cookies-modal-module--cookies-modal-footer--Q0BGs";
export var cookiesModalHeader = "cookies-modal-module--cookies-modal-header--4P1kD";
export var cookiesModalHeaderLogo = "cookies-modal-module--cookies-modal-header-logo--oLoRJ";
export var cookiesModalHeaderTitle = "cookies-modal-module--cookies-modal-header-title--S+crg";
export var cookiesModalListHeader = "cookies-modal-module--cookies-modal-list-header--Q06U0";
export var cookiesModalListItem = "cookies-modal-module--cookies-modal-list-item--TVLAc";
export var cookiesModalNav = "cookies-modal-module--cookies-modal-nav--KGCT1";
export var cookiesModalNavContent = "cookies-modal-module--cookies-modal-nav-content--n-TvT";
export var cookiesModalNavContentPlaceholder = "cookies-modal-module--cookies-modal-nav-content-placeholder--ERfI4";
export var cookiesModalNavItem = "cookies-modal-module--cookies-modal-nav-item--7BTdV";
export var cookiesModalNavWrapper = "cookies-modal-module--cookies-modal-nav-wrapper--xcqyd";
export var cookiesModalSwitch = "cookies-modal-module--cookies-modal-switch--TPCEK";
export var cookiesModalWrapper = "cookies-modal-module--cookies-modal-wrapper--kd+lz";
export var cookiesModule = "cookies-modal-module--cookies-module--oiKkZ";
export var fadeIn = "cookies-modal-module--fade-in--5SIu-";