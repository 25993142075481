import React from "react";

import {
    container,
    imgContainer,
    img,
    textFirst,
    textSecond,
    button,
    padlock,
} from "./product-card.module.scss";
import PadlockClosed from "../../../assets/images/svg/padlock-closed.svg";
import PadlockOpen from "../../../assets/images/svg/padlock-open.svg";
import { IProduct } from "../../../models/product.model";

import Link from "../../../components/atoms/link";

interface IProductCardProps {
    className?: string;
    onPadlockClick?: () => void;
    product: IProduct;
    isLocked?: boolean;
}

const ProductCard: React.FC<IProductCardProps> = ({
    className = "",
    onPadlockClick,
    product,
    isLocked,
}) => {
    const { category, imgUrl, url, affiliateUrl, brandName } = product;

    const handlePadlockClick = () => {
        if (typeof onPadlockClick === "function") {
            onPadlockClick();
        }
    };

    return (
        <div className={`${container} ${className}`}>
            {onPadlockClick && (
                <button onClick={handlePadlockClick} className={padlock} type="button">
                    {isLocked && <PadlockClosed />}
                    {!isLocked && <PadlockOpen />}
                </button>
            )}
            <div className={imgContainer}>
                <img alt="" src={imgUrl} className={img} />
            </div>
            <p className={textFirst}>{category}</p>
            <p className={textSecond}>{brandName}</p>
            <Link
                to={affiliateUrl || url}
                className={button}
                external={true}
                color="primary"
                type="outlined"
            >
                Zobacz
            </Link>
        </div>
    );
};

export default ProductCard;
