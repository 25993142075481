// extracted by mini-css-extract-plugin
export var black = "user-button-module--black--yTkTz";
export var closed = "user-button-module--closed--ES7rC";
export var fadeIn = "user-button-module--fade-in--MRTGc";
export var fadeOut = "user-button-module--fade-out--8Od3b";
export var icon = "user-button-module--icon--kIdqS";
export var opened = "user-button-module--opened--fTcjh";
export var slideFromBottom = "user-button-module--slide-from-bottom--puW-3";
export var slideFromLeft = "user-button-module--slide-from-left--YPUZ+";
export var slideFromRight = "user-button-module--slide-from-right--JC5TJ";
export var slideFromTop = "user-button-module--slide-from-top--jJfBe";
export var slideToBottom = "user-button-module--slide-to-bottom--atr07";
export var slideToLeft = "user-button-module--slide-to-left--j2aQG";
export var slideToRight = "user-button-module--slide-to-right--01OzN";
export var slideToTop = "user-button-module--slide-to-top--0t7UH";
export var userButton = "user-button-module--user-button--xZxNB";
export var white = "user-button-module--white--0G-8L";
export var zoomIn = "user-button-module--zoom-in--8bvto";
export var zoomOut = "user-button-module--zoom-out--dasMf";