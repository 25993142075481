import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { authForm, input, button } from "./auth-forms.module.scss";

import Input from "../../../../src/components/atoms/form/input";
import FormikForm from "../../../../src/components/hoc/formik-form";
import Button from "../../../../src/components/atoms/button";
import useUser from "../hooks/use-user";

const updateDataSchema = Yup.object({
    name: Yup.string(),
    email: Yup.string(),
});

const AccountDataForm = () => {
    const { t } = useI18next();
    const { updateProfile, data, profile } = useUser();

    return (
        <Formik
            initialValues={{ name: profile.firstName, email: data?.email || "" }}
            validationSchema={updateDataSchema}
            onSubmit={updateProfile}
        >
            {(formik) => (
                <FormikForm formik={formik} successMessage={""} className={authForm}>
                    <Input
                        name="name"
                        type="name"
                        placeholder={t("form.label.data")}
                        className={input}
                    />

                    <Input
                        name="email"
                        type="email"
                        disabled
                        placeholder={t("form.label.email")}
                        className={input}
                    />

                    <Button color="primary" type="filled" htmlType="submit" className={button}>
                        {t("button.new-data")}
                    </Button>
                </FormikForm>
            )}
        </Formik>
    );
};

export default AccountDataForm;
