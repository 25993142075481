import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import { getImage, ImageDataLike } from "gatsby-plugin-image";

import {
    userPanel,
    titleText,
    introduction,
    ratio,
    logoBottom,
    welcomeImage,
} from "./user-panel.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import { selectUserProfile } from "../../../redux/user/user.selectors";

import Section from "../../../components/molecules/section";
import RatioImage from "../../../components/atoms/ratio-image";
import AccountTitle from "../atoms/account-title";

type TUserPanelQueryData = Record<"welcomePhotoLandscape" | "logo", ImageDataLike>;

export default function UserPanel(props: RouteComponentProps) {
    const profile = useSelector(selectUserProfile);
    const { logo, welcomePhotoLandscape } = useStaticQuery<TUserPanelQueryData>(query);

    const title = profile ? `Cześć ${profile?.firstName}!` : "Cześć!";

    return (
        <div className={mainGrid}>
            <Section className={userPanel}>
                <AccountTitle className={titleText}>{title}</AccountTitle>
            </Section>
            <Section className={userPanel}>
                <div className={introduction}>
                    <p>
                        Oto twoje konto, tutaj znajdziesz wszystkie stylówki oraz informacje
                        dotyczące Twojego konta.
                    </p>
                    <p>Miłej zabawy!</p>
                </div>
            </Section>
            <Section columnPhone={"full"} className={userPanel}>
                <RatioImage
                    image={getImage(welcomePhotoLandscape)}
                    ratioClass={ratio}
                    className={welcomeImage}
                />
                <RatioImage image={getImage(logo)} ratioClass={ratio} className={logoBottom} />
            </Section>
        </div>
    );
}

const query = graphql`
    query {
        welcomePhotoLandscape: file(relativePath: { eq: "welcome-photo-landscape.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        logo: file(relativePath: { eq: "emilka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 80, layout: FULL_WIDTH)
            }
        }
    }
`;
