import React from "react";

import { subtitleText } from "./adm-title.module.scss";

import Title from "../../../components/atoms/title";

interface IAdmTitleProps {
    className?: string;
}

const AdmTitle: React.FC<IAdmTitleProps> = ({ className = "", children }) => {
    return (
        <Title
            subtitle="Panel Administratora"
            className={className}
            subtitleClassName={subtitleText}
            align="center"
            fontStyle="italic"
            Tag="h1"
            size="medium"
        >
            {children}
        </Title>
    );
};

export default AdmTitle;
