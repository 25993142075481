import React from "react";

import { line, registerButton } from "./login.module.scss";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import AuthLayout from "../../../layouts/auth-layout";
import LoginForm from "../forms/login-form";
import Link from "../../../components/atoms/link";

export default function Login() {
    const { t } = useI18next();
    return (
        <AuthLayout title={t("auth.login.title")}>
            <LoginForm />
            <div className={line}>
                <Link
                    to="/wystylizuj-mnie/"
                    type="outlined"
                    className={registerButton}
                    textUppercase
                >
                    {t("button.register")}
                </Link>
            </div>
        </AuthLayout>
    );
}
