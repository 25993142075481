import moment from "moment";

moment.locale("pl");

const defaultFormat = "YYYY-MM-DD";

const getFormattedDate = (date, targetFormat, isTimestamp = false) => {
    const format = targetFormat ? targetFormat : defaultFormat;

    const newDate = isTimestamp ? moment.unix(date) : moment(date);

    return newDate.format(format);
};

export { getFormattedDate, defaultFormat };
