import React from "react";
import { useSelector } from "react-redux";

import { adminPanel } from "./admin-panel.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import ChampagneIcon from "../../../assets/images/svg/champagne.svg";
import SurveyEditedIcon from "../../../assets/images/svg/survey-edited.svg";
import ModelIcon from "../../../assets/images/svg/model.svg";
import { selectUserProfile } from "../../../redux/user/user.selectors";

import Section from "../../../components/molecules/section";
import SurveyCounter from "../atoms/survey-counter";
import AdmTitle from "../atoms/adm-title";

export default function AdminPanel() {
    const profile = useSelector(selectUserProfile);
    const title = profile ? `Cześć ${profile?.firstName}!` : "Cześć!";

    return (
        <div className={mainGrid}>
            <Section>
                <AdmTitle>{title}</AdmTitle>
            </Section>
            <Section columnPhone="full" className={adminPanel}>
                <SurveyCounter title="Nowe ankiety" Icon={ChampagneIcon} count={3} />
                <SurveyCounter title="Wypełnionych ankiet" Icon={SurveyEditedIcon} count={24} />
                <SurveyCounter title="Przygotowanych stylówek" Icon={ModelIcon} count={19} />
            </Section>
        </div>
    );
}
