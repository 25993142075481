import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectAlertsAsArray } from "../../redux/alerts/alerts.selectors";
import { deleteAlert } from "../../redux/alerts/alerts.actions";
import { alertList } from "./alert-list.module.scss";

import Alert from "../molecules/alert";

export interface IAlertListProps {
    className?: string;
}

export default function AlertList({ className }: IAlertListProps) {
    const alerts = useSelector(selectAlertsAsArray);
    const dispatch = useDispatch();

    if (alerts.length === 0) {
        return null;
    }

    return (
        <div className={`${alertList} ${className}`}>
            {alerts.map(([key, { type, content, canClose }]) => (
                <Alert
                    key={key}
                    type={type}
                    message={content}
                    canClose={canClose}
                    onClose={() => dispatch(deleteAlert(key))}
                />
            ))}
        </div>
    );
}
