// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-about-us-tsx": () => import("./../../../src/components/templates/about-us.tsx" /* webpackChunkName: "component---src-components-templates-about-us-tsx" */),
  "component---src-components-templates-app-tsx": () => import("./../../../src/components/templates/app.tsx" /* webpackChunkName: "component---src-components-templates-app-tsx" */),
  "component---src-components-templates-author-page-tsx": () => import("./../../../src/components/templates/author-page.tsx" /* webpackChunkName: "component---src-components-templates-author-page-tsx" */),
  "component---src-components-templates-beauty-type-tsx": () => import("./../../../src/components/templates/beauty-type.tsx" /* webpackChunkName: "component---src-components-templates-beauty-type-tsx" */),
  "component---src-components-templates-blog-tsx": () => import("./../../../src/components/templates/blog.tsx" /* webpackChunkName: "component---src-components-templates-blog-tsx" */),
  "component---src-components-templates-contact-tsx": () => import("./../../../src/components/templates/contact.tsx" /* webpackChunkName: "component---src-components-templates-contact-tsx" */),
  "component---src-components-templates-faq-tsx": () => import("./../../../src/components/templates/faq.tsx" /* webpackChunkName: "component---src-components-templates-faq-tsx" */),
  "component---src-components-templates-how-to-measure-tsx": () => import("./../../../src/components/templates/how-to-measure.tsx" /* webpackChunkName: "component---src-components-templates-how-to-measure-tsx" */),
  "component---src-components-templates-html-error-message-tsx": () => import("./../../../src/components/templates/html-error-message.tsx" /* webpackChunkName: "component---src-components-templates-html-error-message-tsx" */),
  "component---src-components-templates-index-tsx": () => import("./../../../src/components/templates/index.tsx" /* webpackChunkName: "component---src-components-templates-index-tsx" */),
  "component---src-components-templates-post-tsx": () => import("./../../../src/components/templates/post.tsx" /* webpackChunkName: "component---src-components-templates-post-tsx" */),
  "component---src-components-templates-stylize-tsx": () => import("./../../../src/components/templates/stylize.tsx" /* webpackChunkName: "component---src-components-templates-stylize-tsx" */),
  "component---src-components-templates-tag-page-tsx": () => import("./../../../src/components/templates/tag-page.tsx" /* webpackChunkName: "component---src-components-templates-tag-page-tsx" */),
  "component---src-components-templates-what-fits-you-tsx": () => import("./../../../src/components/templates/what-fits-you.tsx" /* webpackChunkName: "component---src-components-templates-what-fits-you-tsx" */)
}

