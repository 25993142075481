import { IRoute } from "../models/route.model";
import AdminPanel from "./templates/admin-panel";
import SurveySessionView from "../account/templates/survey-session-view";
import AdmRoute from "./hoc/adm-route";
import SurveyQuestionView from "../account/templates/survey-question-view";
import AccountLayout from "../../layouts/account-layout";
import SurveySessions from "./templates/survey-sessions";

export const ADM_ROUTES: IRoute[] = [
    {
        path: "adm",
        label: "",
        id: "ADM",
        redirect: {
            from: "ADM",
            to: "ADM_DASHBOARD",
        },
        Component: AdmRoute,
        routes: [
            {
                id: "ADM_LAYOUT",
                path: "/",
                label: "",
                redirect: {
                    from: "ADM_LAYOUT",
                    to: "ADM_DASHBOARD",
                },
                Component: AccountLayout,
                routes: [
                    {
                        path: "panel",
                        label: "Panel Admina",
                        id: "ADM_DASHBOARD",
                        Component: AdminPanel,
                        routes: [],
                    },
                    {
                        path: "ankieta",
                        label: "Ankieta",
                        id: "ADM_SURVEY_SESSION",
                        Component: AdmRoute,
                        redirect: {
                            from: "ADM_SURVEY_SESSION",
                            to: "ADM_SURVEY_SESSION_LIST",
                        },
                        routes: [
                            {
                                path: "lista",
                                label: "Lista ankiet",
                                id: "ADM_SURVEY_SESSION_LIST",
                                Component: AdmRoute,
                                routes: [
                                    {
                                        path: "/",
                                        label: "Lista ankiet",
                                        id: "ADM_SURVEY_SESSION_LIST_HOME",
                                        Component: SurveySessions,
                                        routes: [],
                                    },
                                    {
                                        path: ":pageNumber",
                                        label: "Lista ankiet",
                                        id: "ADM_SURVEY_SESSION_LIST_PAGE",
                                        Component: SurveySessions,
                                        routes: [],
                                    },
                                ],
                            },
                            {
                                path: ":surveySessionId",
                                label: "Ankieta",
                                id: "ADM_SURVEY_SESSION_VIEW",
                                Component: AdmRoute,
                                routes: [
                                    {
                                        path: "/",
                                        label: "Pytanie",
                                        id: "ADM_SURVEY_SESSION_VIEW_SINGLE",
                                        Component: SurveySessionView,
                                        routes: [],
                                    },
                                    {
                                        path: "pytanie/:questionId",
                                        label: "Pytanie",
                                        id: "ADM_SURVEY_SESSION_QUESTION",
                                        Component: SurveyQuestionView,
                                        routes: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "*",
                        label: "",
                        id: "ADM_REDIRECT",
                        Component: AdmRoute,
                        redirect: {
                            to: "ADM",
                        },
                        routes: [],
                    },
                ],
            },
        ],
    },
];
