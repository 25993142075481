import React, { useCallback, useEffect, useState } from "react";

import {
    selectWrapper,
    selectCurrentItemWrapper,
    expandButton,
    dropdown,
    item as itemClass,
    currentItem as currentItemClass,
} from "./select.module.scss";
import ExpandIcon from "../../assets/images/svg/expand-button.svg";

import Dropdown from "./dropdown";

export interface ISelectItem<Value extends string | number> {
    value: Value;
    label: string;
}

export interface ISelectRenderItemProps<Value extends string | number> {
    item: ISelectItem<Value>;
}

export interface ISelectProps<ItemValue extends string | number> {
    items: ISelectItem<ItemValue>[];
    renderItem?: (item: ISelectRenderItemProps<ItemValue>) => React.ReactElement;
    value?: ItemValue;
    onChange?: (value: ItemValue) => void;
    className?: string;
}

function defaultRenderItem<ItemValue extends string | number>({
    item,
}: ISelectRenderItemProps<ItemValue>) {
    return <span>{item.label}</span>;
}

export default function Select<ItemValue extends string | number>({
    items,
    renderItem = defaultRenderItem,
    value,
    onChange,
    className,
}: ISelectProps<ItemValue>) {
    const [currentItemValue, setCurrentItemValue] = useState<ItemValue | undefined>(value);
    const [drawerOpened, setDrawerOpened] = useState(false);

    const handleItemClick = useCallback(
        (value: ItemValue) => {
            if (onChange) {
                onChange(value);
            } else {
                setCurrentItemValue(value);
            }
            setDrawerOpened(false);
        },
        [onChange]
    );

    const currentItem = items.find((item) => item.value === currentItemValue);

    useEffect(() => {
        setCurrentItemValue(value);
    }, [value]);

    return (
        <div className={`${selectWrapper} ${className}`}>
            <div className={selectCurrentItemWrapper}>
                <span className={currentItemClass} onClick={() => setDrawerOpened(true)}>
                    {currentItem &&
                        renderItem({
                            item: currentItem,
                        })}
                </span>
                <button className={expandButton} onClick={() => setDrawerOpened(true)}>
                    <ExpandIcon />
                </button>
            </div>
            <Dropdown
                open={drawerOpened}
                onClose={() => setDrawerOpened(false)}
                isTriangle={false}
                className={dropdown}
            >
                {items.map((item) => {
                    return React.cloneElement(renderItem({ item }), {
                        key: `select-item-${item.value}`,
                        className: itemClass,
                        onClick: () => handleItemClick(item.value),
                    });
                })}
            </Dropdown>
        </div>
    );
}
