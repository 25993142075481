import { action } from "../action-creator";
import { IUser } from "../../apps/auth/models/user.model";
import { USER } from "./user.reducer";
import {
    IUserLogin,
    IUserPassChange,
    IUserPassReset,
    IUserPassResetRequest,
    IUserRegister,
    IUserSubscribePayload,
} from "../../apps/auth/models/payloads.model";
import { IUserProfile } from "../../apps/auth/models/profile.model";

const types = {
    //document actions:
    SET: `set_data`,
    CLEAR: `clear_data`,
    SET_PROFILE: `set_profile`,

    //command actions:
    LOGIN: `login`,
    LOGOUT: `logout`,
    CHECK: `check`,
    REGISTER: `register`,
    CHANGE_PASSWORD: `change_password`,
    PASSWORD_RESET_REQUEST: `password_reset_request`,
    PASSWORD_RESET: `password_reset`,
    UPDATE_NEWSLETTER: `update_newsletter`,
    CONFIRM: ` confirm`,
    RESEND_ACTIVATION_EMAIL: ` resend_activation_email`,
    UPDATE_PROFILE: ` update_profile`,

    FETCH_CHECK_USER: `fetch_check_user`,
    NEW_LOGIN: `newlogin`,
};

const setUserData = (payload: IUser) => action(USER, types.SET, payload);
const clearUserData = () => action(USER, types.CLEAR);

const loginUser = (payload: IUserLogin) => action(USER, types.LOGIN, payload);
const logoutUser = () => action(USER, types.LOGOUT);
const checkUser = () => action(USER, types.CHECK);
const registerUser = (payload: IUserRegister) => action(USER, types.REGISTER, payload);
const confirmUser = (payload: string) => action(USER, types.CONFIRM, payload);
const changePassword = (payload: IUserPassChange) => action(USER, types.CHANGE_PASSWORD, payload);
const resetPasswordRequest = (payload: IUserPassResetRequest) =>
    action(USER, types.PASSWORD_RESET_REQUEST, payload);
const resetPassword = (payload: IUserPassReset) => action(USER, types.PASSWORD_RESET, payload);
const updateNewsletter = (payload: IUserSubscribePayload) =>
    action(USER, types.UPDATE_NEWSLETTER, payload);
const resendActivationEmail = () => action(USER, types.RESEND_ACTIVATION_EMAIL);
const updateProfile = (payload: Partial<IUserProfile>) =>
    action(USER, types.UPDATE_PROFILE, payload);
const setUserProfile = (payload: IUserProfile) => action(USER, types.SET_PROFILE, payload);
const fetchCheckUser = () => action(USER, types.FETCH_CHECK_USER);

const newLoginUser = (payload: IUserLogin) => action(USER, types.NEW_LOGIN, payload);

export {
    types,
    setUserData,
    clearUserData,
    loginUser,
    logoutUser,
    checkUser,
    registerUser,
    confirmUser,
    changePassword,
    resetPasswordRequest,
    resetPassword,
    updateNewsletter,
    resendActivationEmail,
    updateProfile,
    setUserProfile,
    fetchCheckUser,
    newLoginUser
};
