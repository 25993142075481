import React, { Suspense } from "react";

import { container, layerButton } from "./modal.module.scss";
import { modalMap } from "../../config/modal-map";
import { TModal } from "../../models/modal.model";
import { useModalContext } from "../../contexts/modal.context";

interface IModalProps {
    children?: React.ReactNode;
    modal: TModal;
}

const Modal: React.FC<IModalProps> = ({ modal }) => {
    const { key, modalId, props, canBeClosed = true } = modal;
    const { removeModal } = useModalContext();

    const handleClose = () => {
        if (!canBeClosed) return;
        removeModal(modalId);
    };

    const Component = modalMap[key];

    return (
        <div className={container}>
            <button tabIndex={-1} className={layerButton} onClick={handleClose} />
            <Suspense fallback={null}>
                <Component modalId={modalId} {...(props || {})} />
            </Suspense>
        </div>
    );
};

export default Modal;
