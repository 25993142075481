import { config } from "../config";

const { footerLinks } = config;

type FooterLinksKeys = "socialLinks" | "footerLinks";
type FooterLinksEntity = {
    key: string;
    url: string;
    translationKey?: string;
    external?: boolean;
};

type FooterLinks = Record<FooterLinksKeys, FooterLinksEntity[]>;

export default function getFooterLinks(subKey: FooterLinksKeys): FooterLinksEntity[];
export default function getFooterLinks(subKey: FooterLinksKeys, key: string): string;
export default function getFooterLinks(subKey: FooterLinksKeys, key?: string) {
    const linkSet = (footerLinks as FooterLinks)[subKey];
    const link = linkSet.find((link) => link.key === key)?.url;

    if (key) {
        return link ? link : "";
    } else {
        return linkSet;
    }
}
