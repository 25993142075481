import React, { useEffect, useRef } from "react";
import { Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { form, formFieldset, globalErrorText } from "./formik-form.module.scss";
import { config } from "../../config";
import random from "../../utils/random-string";
import { selectFormById } from "../../redux/formik/formik.selectors";
import { clearForm, setForm } from "../../redux/formik/formik.actions";

import Loader, { ILoaderProps } from "../atoms/loader";
import Error from "../atoms/form/error";

const { statusMap } = config;

interface FormikFormProps {
    className?: string;
    fieldsetClassName?: string;
    formik: FormikProps<any>;
    successMessage: React.ReactNode;
    loaderType?: "layer" | "opaque" | "none";
    loaderPosition?: ILoaderProps["position"];
    showErrors?: boolean;
    children?: React.ReactNode;
}

const FormikForm: React.FC<FormikFormProps> = ({
    className = "",
    fieldsetClassName = "",
    formik,
    successMessage,
    children,
    loaderType,
    loaderPosition = "absolute",
    showErrors = true,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef(`formik-${Date.now()}-${random()}`);

    const { values, status, errors, setErrors, setStatus, setValues } = formik;
    const formFromStore = useSelector((state) => selectFormById(state, formRef.current));
    const globalError = errors.global;

    useEffect(() => {
        setValues({ ...values, formId: formRef.current }, false);
        if (!formFromStore) {
            dispatch(setForm(formRef.current));
        } else {
            setErrors(formFromStore.errors);
            setStatus(formFromStore.status);
        }
    }, [formFromStore]);

    useEffect(() => {
        return () => {
            dispatch(clearForm(formRef.current));
        };
    }, []);

    return (
        <Form
            className={`
                ${form}
                ${className}
            `}
        >
            <fieldset
                className={`${formFieldset} ${fieldsetClassName}`}
                disabled={status === statusMap.loading}
            >
                {status === statusMap.loading && loaderType !== "none" && (
                    <Loader position={loaderPosition} />
                )}
                {status === statusMap.success && successMessage ? successMessage : children}
                {showErrors &&
                    status === statusMap.fail &&
                    globalError &&
                    typeof globalError === "string" && (
                        <Error className={globalErrorText}>{globalError}</Error>
                    )}
            </fieldset>
        </Form>
    );
};

export default FormikForm;
