import { REHYDRATE } from "redux-persist/es/constants";

import {
    checkUser,
    clearUserData,
    fetchCheckUser,
    setUserData,
    setUserProfile,
    types,
} from "./user.actions";
import { USER } from "./user.reducer";
import { actions } from "../api/api.actions";
import actionName from "../action-creator";
import { sendApiRequest } from "../api/api.actions";
import { endpoints } from "../../apps/auth/config";
import { setAlert } from "../alerts/alerts.actions";
import { selectUserProfile, selectUserEmail } from "./user.selectors";
import { setPermissions } from "../rbac/rbac.actions";
import { checkFillSurvey } from "../survey-fill/survey-fill.actions";


const userMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;

    let fetchConfig;

    const profile = selectUserProfile(getState());
    const email = selectUserEmail(getState());

    next(action);

    switch (type) {
        case REHYDRATE:
            dispatch(checkUser());
            break;

        case actionName(USER, types.LOGIN):
            fetchConfig = {
                url: endpoints.login,
                // url: endpoints.newlogin,
                method: "post",
                data: payload,
                withCredentials: true,
            };
            // meta.useAlternateApi = true;
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, `${types.LOGIN} ${actions.API_SUCCESS}`):
            //console.log('login success: ', payload);
            dispatch(setUserData(payload));
            dispatch(setPermissions(payload.permissions));

            var expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 1);
            document.cookie = `api-token=${payload.token.token}; expires=${expiryDate.toUTCString()}; path=/`;
            break;

        case actionName(USER, `${types.LOGIN} ${actions.API_FAIL}`):
            dispatch(clearUserData());
            break;

        case actionName(USER, types.NEW_LOGIN):
            fetchConfig = {
                url: endpoints.newlogin,
                method: "post",
                data: payload,
                withCredentials: true,
            };
            meta.useAlternateApi = true;
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, `${types.NEW_LOGIN} ${actions.API_SUCCESS}`):
            //console.log('NEW_LOGIN success: ', payload);

            var expiryDate = new Date();
            expiryDate.setMonth(expiryDate.getMonth() + 1);
            document.cookie = `apinew-token=${payload.token}; expires=${expiryDate.toUTCString()}; path=/`;
            break;
        
        case actionName(USER, `${types.NEW_LOGIN} ${actions.API_FAIL}`):
            console.log('NEW_LOGIN fail: ');

            // dispatch(clearUserData()); ==> czy kasowac???
            break;
        case actionName(USER, types.LOGOUT):
            fetchConfig = {
                url: endpoints.logout,
                method: "post",
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, `${types.LOGOUT} ${actions.API_SUCCESS}`):
            dispatch(clearUserData());
            break;

        case actionName(USER, `${types.LOGOUT} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        message: "Nie udało się wylogować",
                    },
                    meta
                )
            );
            break;

        case actionName(USER, types.REGISTER):
            fetchConfig = {
                url: endpoints.register,
                method: "post",
                data: payload,
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, { ...meta, context: payload.context }));
            break;

        case actionName(USER, `${types.REGISTER} ${actions.API_SUCCESS}`):
            dispatch(setUserData(payload));
            dispatch(setPermissions(payload.permissions));
            break;

        case actionName(USER, `${types.REGISTER} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        message: "Nie udało się zarejestrować",
                    },
                    meta
                )
            );
            break;

        case actionName(USER, types.CONFIRM):
            fetchConfig = {
                url: `${endpoints.activate}/${payload}`,
                method: "post",
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, `${types.CONFIRM} ${actions.API_SUCCESS}`):
            dispatch(setUserData(payload));
            break;

        case checkUser().type:
            dispatch(fetchCheckUser());
            break;

        case fetchCheckUser().type:
            fetchConfig = {
                url: endpoints.check,
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case `${fetchCheckUser().type} ${actions.API_SUCCESS}`:
            dispatch(setUserData(payload));
            dispatch(setPermissions(payload.permissions));
            dispatch(checkFillSurvey());
            break;

        case `${fetchCheckUser().type} ${actions.API_FAIL}`:
            dispatch(clearUserData());
            break;

        case actionName(USER, types.CHANGE_PASSWORD):
            fetchConfig = {
                url: endpoints.passwordChange,
                method: "post",
                data: payload,
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, `${types.PASSWORD_RESET} ${actions.API_SUCCESS}`):
        case actionName(USER, `${types.CHANGE_PASSWORD} ${actions.API_SUCCESS}`):
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: "Udało się ustawić nowe hasło",
                    },
                    meta
                )
            );
            break;

        // case actionName(USER, `${types.PASSWORD_RESET} ${actions.API_FAIL}`):
        //     dispatch(setAlert(formatFormikErrors(payload), meta));
        //     break;
        case actionName(USER, `${types.CHANGE_PASSWORD} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się ustawić nowego hasła",
                    },
                    meta
                )
            );
            break;

        case actionName(USER, types.PASSWORD_RESET_REQUEST):
            fetchConfig = {
                url: endpoints.passwordResetRequest,
                method: "post",
                data: payload,
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, `${types.PASSWORD_RESET_REQUEST} ${actions.API_SUCCESS}`):
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: "Udało się wysłać prośbę o reset hasła",
                    },
                    meta
                )
            );
            break;

        case actionName(USER, `${types.PASSWORD_RESET_REQUEST} ${actions.API_FAIL}`):
            break;

        case actionName(USER, types.PASSWORD_RESET):
            fetchConfig = {
                url: endpoints.passwordReset,
                method: "post",
                data: payload,
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, types.UPDATE_NEWSLETTER):
            fetchConfig = {
                url: payload.subscribeNewsletter
                    ? endpoints.subscribeNewsletter
                    : endpoints.unSubscribeNewsletter,
                method: "post",
                withCredentials: true,
                data: {
                    email,
                },
            };
            const customMeta = {
                ...meta,
                subscribeNewsletter: payload.subscribeNewsletter,
            };
            dispatch(sendApiRequest(fetchConfig, customMeta));
            break;

        case actionName(USER, `${types.UPDATE_NEWSLETTER} ${actions.API_SUCCESS}`):
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: meta?.subscribeNewsletter
                            ? "Cudownie! Cieszymy się, że zapisałaś się do naszego newslettera."
                            : "Poprawnie wypisałaś się z newslettera.",
                    },
                    meta
                )
            );
            dispatch(setUserData({ isNewsletterSubscriber: meta?.subscribeNewsletter }));
            break;

        case actionName(USER, `${types.UPDATE_NEWSLETTER} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: meta?.subscribeNewsletter
                            ? "Już jesteś zapisana do newslettera."
                            : "Już jesteś wypisana z newslettera.",
                    },
                    meta
                )
            );

            break;

        case actionName(USER, types.RESEND_ACTIVATION_EMAIL):
            fetchConfig = {
                url: endpoints.resendActivationEmail,
                method: "post",
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, meta));
            break;

        case actionName(USER, `${types.RESEND_ACTIVATION_EMAIL} ${actions.API_SUCCESS}`):
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: "Email został wysłany.",
                    },
                    meta
                )
            );
            break;

        case actionName(USER, `${types.RESEND_ACTIVATION_EMAIL} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się wysłać maila.",
                    },
                    meta
                )
            );

            break;

        case actionName(USER, types.UPDATE_PROFILE):
            if (profile) {
                fetchConfig = {
                    url: `${endpoints.profiles}/${profile.profileId}`,
                    method: "patch",
                    data: payload,
                    withCredentials: true,
                };
                dispatch(sendApiRequest(fetchConfig, meta));
            }
            break;

        case actionName(USER, `${types.UPDATE_PROFILE} ${actions.API_SUCCESS}`):
            dispatch(
                setAlert(
                    {
                        type: "success",
                        content: "Dane konta zostały zaktualizowane.",
                    },
                    meta
                )
            );
            dispatch(setUserProfile(payload));
            break;
        case actionName(USER, `${types.UPDATE_PROFILE} ${actions.API_FAIL}`):
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Wystąpił błąd.",
                    },
                    meta
                )
            );

            break;

        // no default
    }
};

export default userMiddleware;
