import actionName from "../action-creator";
import { types } from "./generator-filters.actions";

const GENERATOR_FILTERS = `[GENERATOR_FILTERS]`;

const initialState = {
    color: [],
    pattern: [],
    occasion: [],
    style: [],
    priceTop: [],
    priceBottom: [],
    priceOuterwear: [],
    priceOverall: [],
};

function generatorFiltersReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actionName(GENERATOR_FILTERS, types.SET):
            return payload;
        case actionName(GENERATOR_FILTERS, types.RESTORE_INITIAL):
            return initialState;
        default:
            return state;
    }
}

export { initialState, GENERATOR_FILTERS };
export default generatorFiltersReducer;
