import React from "react";
import { useLocation } from "@reach/router";

import PrivateRoute, { PrivateRouteProps } from "../../hoc/private-route";
import { IRoute } from "../../models/route.model";
import useCheckPermissions from "../../hooks/use-check-permissions";
import { config } from "../../../config";
import { getAbsolutePath } from "../../utility/routes.utility";

const { User, Admin } = config.rbac.permissionMap;

export default function AuthRoute({ ...props }: PrivateRouteProps) {
    const { pathname } = useLocation();

    const confirmEmailPath = getAbsolutePath("AUTH_CONFIRMATION");
    const isConfirmEmailPath = pathname.includes(confirmEmailPath);

    const isAdmin = useCheckPermissions(Admin.role);
    const isUser = useCheckPermissions(User.role);

    let redirect: IRoute["redirect"] = props?.redirect;

    if (isAdmin) {
        redirect = {
            from: pathname,
            to: "ADM",
        };
    } else if (isUser && !isConfirmEmailPath) {
        redirect = {
            from: pathname,
            to: "ACCOUNT",
        };
    }

    return <PrivateRoute {...props} redirect={redirect} />;
}
