import { config } from "../../config";
import actions from "./rbac.actions";
import { clearUserData } from "../user/user.actions";

const RBAC = "[RBAC]";

const { Guest } = config.rbac.permissionMap;
const initialState = Guest.role;

function rbacReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_PERMISSIONS:
            return [...action.payload];

        case clearUserData().type:
        case actions.CLEAR_PERMISSIONS:
            return initialState;

        default:
            return state;
    }
}
export default rbacReducer;
export { RBAC, initialState };
