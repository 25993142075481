import { action } from "../action-creator";
import { SURVEY } from "./survey.reducer";

const actions = {
    //document actions:
    SET: `set_data`,
    CLEAR: `clear_data`,

    //command actions:
    GET_LIST: `get_list`,
};

const setSurvey = (payload) => action(SURVEY, actions.SET, payload, {});
const clearSurvey = () => action(SURVEY, actions.CLEAR);

const getSurveyList = () => action(SURVEY, actions.GET_LIST);

export { actions, setSurvey, clearSurvey, getSurveyList };
