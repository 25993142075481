import React, { useEffect } from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import {
    container,
    section,
    bar,
    title,
    button,
    icon,
    list,
    info,
    error,
} from "./stylizations.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import PlusIcon from "../../../assets/images/svg/plus.svg";
import { TStatus } from "../../../models/status.model";
import { IStylization } from "../../../models/stylization.model";
import { getAbsolutePath } from "../../utility/routes.utility";
import {
    selectStylizationsItems,
    selectStylizationsStatus,
} from "../../../redux/stylizations/stylizations.selectors";
import {
    clearStylizationsAction,
    getStylizationsAction,
} from "../../../redux/stylizations/stylizations.actions";
import { clearSingleStylizationAction } from "../../../redux/single-stylization/single-stylization.actions";
import { selectSingleStylizationStatus } from "../../../redux/single-stylization/single-stylization.selectors";

import Section from "../../../components/molecules/section";
import AccountTitle from "../atoms/account-title";
import Button from "../../../components/atoms/button";
import StylizationCard from "../molecules/stylization-card";
import Loader from "../../../components/atoms/loader";

const Stylizations: React.FC = () => {
    const { t } = useI18next();
    const dispatch = useDispatch();
    const status: TStatus = useSelector(selectStylizationsStatus);
    const singleStylizationStatus: TStatus = useSelector(selectSingleStylizationStatus);
    const stylizations: IStylization[] = useSelector(selectStylizationsItems);

    const showLoader =
        status === "loading" || status === "idle" || singleStylizationStatus === "loading";

    const handleAddStylization = async () => {
        await navigate(getAbsolutePath("GENERATOR_NEW_STYLIZATION"));
    };

    useEffect(() => {
        if (singleStylizationStatus === "success") {
            dispatch(clearStylizationsAction());
            dispatch(getStylizationsAction());
        }
    }, [singleStylizationStatus]);

    useEffect(() => {
        dispatch(getStylizationsAction());
        return () => {
            dispatch(clearStylizationsAction());
            dispatch(clearSingleStylizationAction());
        };
    }, [dispatch]);

    return (
        <div className={`${mainGrid} ${container}`}>
            <Section className={section}>
                <AccountTitle className={title}>{t("account.style.title")}</AccountTitle>
                <div className={bar}>
                    <Button className={button} color="primary" onClick={handleAddStylization}>
                        <PlusIcon className={icon} /> {t("stylizations.button.add")}
                    </Button>
                </div>
                {showLoader && <Loader position="absolute" />}
                {status === "fail" && (
                    <p className={`${info} ${error}`}>{t("stylizations.fail")}</p>
                )}
                {status === "success" && stylizations.length === 0 && (
                    <p className={info}>{t("stylizations.empty")}</p>
                )}
                {status === "success" && stylizations.length > 0 && (
                    <ul className={list}>
                        {stylizations.map((stylization) => {
                            return (
                                <li key={stylization.stylizationId}>
                                    <StylizationCard stylization={stylization} />
                                </li>
                            );
                        })}
                    </ul>
                )}
            </Section>
        </div>
    );
};

export default Stylizations;
