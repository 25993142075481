import { actions } from "./generic-list.actions";
import actionName from "../action-creator";
const { set, clear } = actions.document;

export const genericListInitState = {
    items: [],
    filters: {},
    sort: {},
    search: {},
    pagination: {
        currentPage: 0,
    },
};

export const genericListReducer = (entity, subEntity) => {
    return function (state = genericListInitState, action) {
        const { type, payload } = action;

        switch (type) {
            case actionName(`${entity} ${subEntity}`, set):
                return {
                    ...state,
                    ...payload,
                };
            case actionName(`${entity} ${subEntity}`, clear):
                return genericListInitState;
            default:
                return state;
        }
    };
};
