import { actions as apiActions } from "../api/api.actions";
import { prepareGenericListParams } from "./generic-list.utils";
import actionName from "../action-creator";

const actions = {
    document: {
        set: "set",
        clear: "clear",
    },
    api: {
        request: apiActions.API_REQUEST,
        success: apiActions.API_SUCCESS,
        fail: apiActions.API_FAIL,
    },
    command: {
        get: "get",
        fetch: "fetch",
        filter: "filter",
        sort: "sort",
        search: "search",
        del: "delete",
        update: "update",
        addItem: "add_item",
    },
};

const getItems = ({ state, actionEntity, payload, fieldName, page = 1, params }) => ({
    type: actionName(actionEntity, actions.command.get),
    payload: {
        params: params || prepareGenericListParams({ state, payload, fieldName, page }),
        url: payload.url,
    },
});

const clearList = (entity, subEntity) => ({
    type: actionName(`${entity} ${subEntity}`, actions.document.clear),
});

export { actions, getItems, clearList };
