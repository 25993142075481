import React from "react";

import { IRoute } from "../models/route.model";

import SurveyRoute from "./hoc/survey-route";
import SurveyPage from "./templates/survey-page";
import SurveySuccess from "./templates/survey-success";

export const SURVEY_ROUTES: IRoute[] = [
    {
        path: "ankieta",
        label: "Ankieta",
        id: "SURVEY",
        Component: SurveyRoute,
        redirect: {
            from: "SURVEY",
            to: "SURVEY_QUESTION",
        },
        routes: [
            {
                path: "/",
                label: "Ankieta - pytanie",
                id: "SURVEY_QUESTION",
                Component: SurveyPage,
                routes: [],
            },
            {
                path: ":pageNumber",
                label: "Ankieta - pytanie",
                id: "SURVEY_QUESTION_NUMBER",
                Component: SurveyPage,
                routes: [],
            },
            {
                path: "sukces",
                label: "Ankieta - sukces",
                id: "SURVEY_SUCCESS",
                Component: SurveySuccess,
                routes: [],
            },
            {
                path: "*",
                label: "",
                id: "SURVEY_REDIRECT",
                Component: SurveyRoute,
                redirect: {
                    to: "SURVEY_QUESTION_NUMBER",
                },
                routes: [],
            },
        ],
    },
];
