import { action } from "../action-creator";
import { config } from "../../config";
import { UI } from "./ui.reducer";

const { apiStatusMap } = config;

const statusLoading = (meta) => {
    const actionName = `${meta?.actionName} ${UI} ${apiStatusMap.loading}`;
    return action(meta?.entity, actionName, null, meta);
};

const statusSuccess = (meta) => {
    const actionName = `${meta?.actionName} ${UI} ${apiStatusMap.success}`;
    return action(meta?.entity, actionName, null, meta);
};

const statusFail = (meta) => {
    const actionName = `${meta?.actionName} ${UI} ${apiStatusMap.fail}`;
    return action(meta?.entity, actionName, null, meta);
};

const statusIdle = (meta) => {
    const actionName = `${meta?.actionName} ${UI} ${apiStatusMap.idle}`;
    return action(meta?.entity, actionName, null, meta);
};

export { statusLoading, statusSuccess, statusIdle, statusFail };
