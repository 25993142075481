import React, { useEffect, useState } from "react";

import { userAvatar } from "./user-avatar.module.scss";

export interface IUserAvatarProfile {
    src: string;
    className?: string;
    onLoadChange?: (value: boolean) => void;
}

export default function UserAvatar({ src, className = "", onLoadChange }: IUserAvatarProfile) {
    const [lastSrc, setLastSrc] = useState("");

    const handleLoad = () => {
        if (onLoadChange) {
            onLoadChange(false);
        }
    };

    useEffect(() => {
        if (onLoadChange && src !== lastSrc) {
            setLastSrc(src);
            onLoadChange(true);
        }
    }, [src, onLoadChange, lastSrc]);

    return (
        <img src={src} alt="avatar" className={`${userAvatar} ${className}`} onLoad={handleLoad} />
    );
}
