import { action } from "../action-creator";
import { GENERATOR_PRODUCTS } from "./generator-products.reducer";
import { IGeneratorFormValues, IGeneratorProductsSetPayload } from "../../models/generator.model";

export const types = {
    GET: "get",
    SET: "set",
    CLEAR: "clear",
    RESTORE_INITIAL: "restore initial",
};

export const getGeneratorProductsAction = (payload: IGeneratorFormValues) => action(GENERATOR_PRODUCTS, types.GET, payload);
export const setGeneratorProductsAction = (payload: IGeneratorProductsSetPayload) => action(GENERATOR_PRODUCTS, types.SET, payload);
export const clearGeneratorProductsAction = () => action(GENERATOR_PRODUCTS, types.CLEAR);
export const restoreGeneratorProductsInitialStateAction = () => action(GENERATOR_PRODUCTS, types.RESTORE_INITIAL);
