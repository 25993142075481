const entities = {
    survey: `surveyPrivate`,
    surveyList: `surveyList`,
};
const events = {
    surveyFilled: `surveyFilled`,
};

const Guest = {
    role: `guest`,
    test: `testPermission`,
};

const User = {
    role: `User`,
    survey: entities.survey,
};

const Admin = {
    ...User,
    role: `Admin`,
    surveyList: entities.surveyList,
};

const Root = {
    ...Admin,
    role: `Root`,
};

const permissionMap = {
    Guest,
    User,
    Admin,
    Root,
};

module.exports = {
    entities,
    events,
    permissionMap,
    entitiesArray: () => Object.values(entities),
};
